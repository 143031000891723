import React, { useEffect, useRef, useState } from "react";
import "./css/InventoryList.css";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import Axios from "axios";
import { DataTable } from "primereact/datatable";
import { api } from "../../../../../../../utility/AxiosInceptor";
import { Dialog } from "primereact/dialog";
import AddInventoryAgainstBillingModel from "./AddInventory/AddInventory";
import UpdateInventoryAgainstBilllingModel from "./UpdateInventory/UpdateInventory";
import CommonLoaderBlue from "../../Loaders/components/Loader/LoaderBlue";
import { Toast } from "primereact/toast";
import { InputSwitch } from "primereact/inputswitch";
import { CancelPopup, PlanEditIcon } from "../../../../../../../utility";
import { OverlayPanel } from "primereact/overlaypanel";
export default function InventoryList({ data, billingModelSelect, inventorySelect, setUpdateInventory, inventorySelected, setBillingModelFromList, setInventorySelected, setRefresh, refresh }) {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    let toast = useRef();
    const [statusLoading, setStatusLoading] = useState(false);
    const [statusChangeVisibility, setStatusChangeVisibility] = useState(false);
    const [addInventory, setAddInventory] = useState(false);
    // const [inventorySelected, setInventorySelected] = useState();
    let parseLoginRes = JSON.parse(localStorage.getItem("userData"));
    const [loader, setLoader] = useState(true);
    // const [updateInventory, setUpdateInventory] = useState(false);
    const [InventoryList, setinventoryList] = useState([]);
    const [rowClick, setRowClick] = useState(true);
    const [selectedInventory, setSelectedInventory] = useState([]);
    const [rowData, setRowData] = useState([]);
    const op = useRef(null);

    const updateBillingModelStatus = () => {
        setStatusLoading(true);
        api.put(`/api/inventoryType/statusUpdate?inventoryTypeId=${inventorySelected._id}&active=${inventorySelected?.active ? false : true}`)
            .then((res) => {
                toast.current.show({ severity: "success", summary: "Inventory Type Status Updation", detail: `Inventory Type ${inventorySelected?.active ? "Deactivated" : "Activated"} Successfully` });

                setTimeout(() => {
                    setStatusLoading(false);
                    setStatusChangeVisibility(false);
                    setRefresh((prev) => !prev);
                    if (op.current) {
                        op.current.hide();
                    }
                }, 500);
            })
            .catch((err) => {
                toast.current.show({ severity: "error", summary: "Inventory Type Status Updation", detail: `Inventory Type ${inventorySelected?.active ? "Deactivation" : "Activation"} Failed` });
                setStatusLoading(false);
                if (op.current) {
                    op.current.hide();
                }
            });
    };
    useEffect(() => {
        api.get(`/api/inventoryType/all`, {
            params: {
                serviceProvider: parseLoginRes?.company,
                billingModel: data?._id,
            },
        })
            .then((res) => {
                setinventoryList(res?.data?.data);
                setLoader(false);
            })
            .catch((err) => {
                setLoader(false);
            });
    }, [inventorySelect, refresh]);
    return (
        <div>
            <Toast ref={toast} />
            <div className="inventorylisttable">
                {selectedInventory?.length > 0 && (
                    <div className="flex flex-column justify-content-start align-items-center selecteddatapopup">
                        <div className="flex w-full justify-content-end">
                            <span onClick={() => setSelectedInventory([])} style={{ cursor: "pointer" }}>
                                <CancelPopup />
                            </span>
                        </div>
                        <div className="flex justify-content-evenly w-full align-items-center selectedrowdata   ">
                            <div className="flex showselectedroles" style={{ width: "12rem" }}>
                                <p>
                                    Selected: <span>{selectedInventory?.length}</span>
                                </p>
                            </div>
                            <div className="flex justify-content-between" style={{ width: "26.2rem" }}>
                                <Button className="addnewrolebtn" label="Deactive" style={{ width: "12rem" }} />
                                <Button className="adduserbtn" label="Active" style={{ width: "12rem" }} />
                            </div>
                        </div>
                    </div>
                )}
                <DataTable
                    value={InventoryList}
                    size="small"
                    selectionMode="checkbox"
                    selection={selectedInventory}
                    onSelectionChange={(e) => setSelectedInventory(e.value)}
                    className="inventoryListtable"
                    stripedRows
                    resizableColumns
                    emptyMessage={
                        loader ? (
                            <div className="flex flex-wrap flex-row justify-content-center align-items-center ">
                                <CommonLoaderBlue customSize={15} />
                            </div>
                        ) : (
                            "Inventory Type Not Found"
                        )
                    }
                    style={{ marginTop: "2.4rem" }}
                >
                    <Column selectionMode="multiple" headerStyle={{ width: "1rem" }}></Column>
                    <Column header="Inventory Type" field="inventoryType" />
                    <Column header="Make" field="make" />
                    <Column header="Model" field="model" />
                    <Column header="Selling Price" field="sellingPrice" />
                    <Column header="Discount" field="discount" />
                    <Column header="Profit Margin" field="profitMargin" />
                    <Column header="Identifier" field="identifierType.identifier" />
                    <Column header="Status" field="active" body={(rowData) => <label className={rowData?.active ? "active" : "deactive"}> {rowData?.active ? "Active" : "Deactive"}</label>} />
                    <Column
                        header="Action"
                        headerClassName="p-3"
                        body={(rowData) => {
                            return (
                                <>
                                    <div
                                        onClick={(e) => {
                                            op.current.toggle(e);
                                            setInventorySelected(rowData);
                                            setBillingModelFromList(rowData?.billingModel?._id);
                                        }}
                                        className="actionbtnmain"
                                    >
                                        <PlanEditIcon />
                                    </div>
                                    <OverlayPanel ref={op} style={{ width: "10.9em", height: "11.2rem" }} className="flex align-items-center">
                                        <div className="flex flex-column w-full overlay">
                                            <div
                                                className="flex justify-content-start align-items-center prospectoverlay"
                                                style={{ height: "32px" }}
                                                onClick={() => {
                                                    updateBillingModelStatus();
                                                }}
                                            >
                                                <label style={{ fontSize: "1.2rem", fontWeight: "500" }}>Deactive</label>
                                            </div>
                                            <div
                                                className="flex justify-content-start align-items-center prospectoverlay"
                                                style={{ height: "32px" }}
                                                onClick={() => {
                                                    updateBillingModelStatus();
                                                }}
                                            >
                                                <label style={{ fontSize: "1.2rem", fontWeight: "500" }}>Active</label>
                                            </div>
                                            <div
                                                className="flex justify-content-start align-items-center prospectoverlay"
                                                style={{ height: "32px" }}
                                                onClick={() => {
                                                    setUpdateInventory(true);
                                                    if (op.current) {
                                                        op.current.hide();
                                                    }
                                                }}
                                            >
                                                <label style={{ fontSize: "1.2rem", fontWeight: "500" }}>Edit</label>
                                            </div>
                                        </div>
                                    </OverlayPanel>
                                </>
                            );
                        }}
                        field="Edit"
                    />
                    {/* <Column
                        header="Actions"
                        headerClassName="p-3"
                        body={(rowData) => {
                            return (
                                <div style={{ width: "250px" }} className="actionsbilling flex flex-wrap flex-row justify-content-left align-items-center gap-4">
                                    <Button
                                        label=""
                                        style={{ height: "25px", paddingTop: "0px !imprtant", paddingBottom: "0px !important" }}
                                        icon="pi pi-pencil"
                                        onClick={() => {
                                            setInventorySelected(rowData);
                                            setUpdateInventory(true);
                                        }}
                                    />

                                    {!rowData?.active ? (
                                        <InputSwitch
                                            checked={false}
                                            onChange={(e) => {
                                                e.preventDefault();
                                                setInventorySelected(rowData);
                                                setStatusChangeVisibility(true);
                                            }}
                                        />
                                    ) : (
                                        <InputSwitch
                                            checked={true}
                                            onChange={(e) => {
                                                e.preventDefault();
                                                setInventorySelected(rowData);
                                                setStatusChangeVisibility(true);
                                            }}
                                        />
                                    )}
                                </div>
                            );
                        }}
                        field="Edit"
                    /> */}
                </DataTable>
            </div>

            {/* <Dialog
                style={{ width: "85vw" }}
                header="Update Inventory"
                visible={updateInventory}
                onHide={() => {
                    setUpdateInventory(false);
                    setRefresh((prev) => !prev);
                }}
            >
                <UpdateInventoryAgainstBilllingModel billingModelSelect={billingModelSelect} inventorySelected={inventorySelected} />
            </Dialog> */}
            <Dialog
                style={{ width: "85vw" }}
                header="Add Inventory"
                visible={addInventory}
                onHide={() => {
                    setAddInventory(false);
                    setRefresh((prev) => !prev);
                }}
            >
                <AddInventoryAgainstBillingModel setRefresh={setRefresh} setAddInventory={setAddInventory} billingModelSelect={billingModelSelect} />
            </Dialog>

            {/* <Dialog
                header={inventorySelected?.active ? "Inventory Type Deactivation" : "Inventory Type Activation"}
                headerClassName="font-poppins text-main-color"
                visible={statusChangeVisibility}
                onHide={() => {
                    setStatusChangeVisibility((prev) => !prev);
                }}
            >
                <p className="text-main-color font-poppins">Do You Want To {inventorySelected?.active ? "Deactivate" : "Activate"} the Inventory Type ?</p>
                <div className="mt-4  changestatusdialogbutton flex font-poppins flex-wrap flex-row ">
                    <Button onClick={updateBillingModelStatus} loading={statusLoading} disabled={statusLoading} label="Yes" />

                    <Button
                        label="No"
                        onClick={() => {
                            setStatusChangeVisibility(false);
                        }}
                    />
                </div>
                <Toast ref={toast} />
            </Dialog> */}
        </div>
    );
}
