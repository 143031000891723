import { api } from "../../../../../../utility/AxiosInceptor";
import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import * as Yup from "yup";
import { useFormik } from "formik";
import Axios from "axios";
import { Dropdown } from "primereact/dropdown";
import CellPhoneCompletedStockReport from "./complete_stock/cell_phone_report";
import TabletCompleteStockReport from "./complete_stock/tablet_report";
import SIMCompleteStockReport from "./complete_stock/sim_report";
//import TabletProvisionedStockReport from "./provisioned_stock/tablet_report";
//import SIMProvisionedStockReport from "./provisioned_stock/sim_report";
const BASE_URL = process.env.REACT_APP_BASE_URL;
const loginRes = localStorage.getItem("userData");
const parseLoginRes = JSON.parse(loginRes);
export default function InventoryDashboard({ setActiveComponent }) {
    const validationSchema = Yup.object().shape({});
    const [makeModelOptions, setMakeModelOptions] = useState();
    const formik = useFormik({
        initialValues: {
            unit: "",
            billingModel: "",
            makemodel: "",
        },
        validationSchema: validationSchema,
        onSubmit: (values, actions) => {},
    });
    const [unitOptions, setUnitOptions] = useState([]);
    const [billingModelList, setBillingModelList] = useState([]);
    const [currentBillingId, setCurrentBillingId] = useState("");
    useEffect(() => {
        if (formik.values.unit !== "" && currentBillingId !== "") {
            api.get(`/api/inventoryType/getInventoryTypeData?inventoryType=${formik.values.unit}&billingModel=${currentBillingId}&serviceProvider=${parseLoginRes?.company}`)
                .then((res) => {
                    let Data = res?.data?.data;
                    let dataNew = [];
                    for (let i = 0; i < Data.length; i++) {
                        let data = Data[i];
                        data.makemodel = `${data.make} - ${data.model}`;
                        dataNew.push(data);
                    }
                    setMakeModelOptions(dataNew);
                })
                .catch((err) => {});
        }
    }, [currentBillingId, formik.values.unit]);
    useEffect(() => {
        async function fetchData() {
            if (formik.values.billingModel !== "") {
                try {
                    const res = await api.get(`/api/billingModel/getInventoryByBillingModel?billingModel=${currentBillingId}`);
                    let obj = [];
                    let data = res?.data?.data;
                    data.forEach((item) => {
                        let obj2 = {};
                        obj2.inventoryType = item;
                        obj.push(obj2);
                    });
                    setUnitOptions(obj);
                } catch (error) {
                    //toast.error(error?.response?.data?.msg);
                }
            }
        }
        fetchData();
    }, [currentBillingId]);
    useEffect(() => {
        api.get(`/api/billingModel/all?serviceProvider=${parseLoginRes?.company}`)
            .then((res) => {
                setBillingModelList(res?.data?.data);
            })
            .catch((err) => {});
    }, []);
    return (
        <div>
            <div className="card">
                <div className="flex flex-wrap justify-content-center">
                    <h1 className=" heading" style={{ fontWeight: "600" }}>
                        INVENTORY DASHBOARD
                    </h1>
                </div>
                <hr style={{ border: "1px solid #C68301", opacity: "15%" }} />

                {/*  <h5 className="mt-4 w-full card">Complete Stock Report </h5>   */}
                <div style={{ gap: "0.5%" }} className="flex flex-wrap mb-3  justify-content-left">
                    <div className="calendar_field">
                        <p className="field_label  mt-2">
                            Billing Model <span style={{ color: "red" }}>*</span>
                        </p>
                        <Dropdown
                            value={formik.values.billingModel}
                            name="billingModel"
                            optionLabel="billingModel"
                            optionValue="billingModel"
                            options={billingModelList}
                            onChange={(e) => {
                                formik.setFieldValue("billingModel", e.value);
                                let id;
                                billingModelList.map((item) => {
                                    if (item.billingModel === e.value) {
                                        id = item._id;
                                    }
                                });
                                setCurrentBillingId(id);
                            }}
                            placeholder="Select an option"
                            className="w-full mt-2"
                        />
                    </div>
                    <div className="calendar_field">
                        <p className="field_label  mt-2 ">
                            Inventory Type <span style={{ color: "red" }}>*</span>
                        </p>
                        <Dropdown optionLabel="inventoryType" optionValue="inventoryType" value={formik.values.unit} name="unit" options={unitOptions} onChange={formik.handleChange} placeholder="Select an option" className="w-full mt-2 " />
                    </div>
                    <div className="calendar_field">
                        <p className="field_label  mt-2 ">
                            Make And Model <span style={{ color: "red" }}>*</span>
                        </p>
                        <Dropdown optionLabel="makemodel" optionValue="makemodel" value={formik.values.makemodel} name="makemodel" options={makeModelOptions} onChange={formik.handleChange} placeholder="Select Make And Model" className="w-full mt-2 " />
                    </div>
                </div>

                {/* <h5 className="mt-4 w-full card">Provisioned Stock Report </h5>   
              <div className="flex justify-content-around flex-wrap card" >    
               
                  <div className="w-20rem mt-2 card"> 
                   <TabletProvisionedStockReport/>
                  </div> 
                  <div className="w-20rem mt-2 card"> 
                    <SIMProvisionedStockReport/>
                  </div> 
              </div>      */}

                <div className="flex flex-wrap justify-content-end">
                    <Button
                        className="btn"
                        label="Back"
                        style={{ marginLeft: "25px", fontSize: "16px", marginTop: "58px" }}
                        onClick={() => {
                            setActiveComponent("");
                        }}
                    />
                </div>
            </div>
            <div className="flex justify-content-around flex-wrap">
                <SIMCompleteStockReport makemodel={formik.values.makemodel} unitType={formik.values.unit} billingModel={formik.values.billingModel} />
            </div>
        </div>
    );
}
