import React, { useState, useRef, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Axios from "axios";
import { api } from "../../../../../../../../utility/AxiosInceptor";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import AddAgentDetail from "./Dialogs/add_agent_detail";
import AddCellPhoneModelDialog from "./Dialogs/add_cell_phone_model_dialog";
const BASE_URL = process.env.REACT_APP_BASE_URL;
export default function CellPhoneSingleUpload({ inventoryIdSelected, makeModel, setActiveComponent, identifierTypeSelected, permissions, unit, model }) {
    let ref = useRef(null);
    const loginRes = localStorage.getItem("userData");
    const parseLoginRes = JSON.parse(loginRes);
    const [add_cellphone_model_dialog_visibility, setAddCellPhoneModelDialogVisbility] = useState(false);
    const [add_agent_detail_dialog_visibility, setAddAgentDialogVisbility] = useState(false);
    const [carrier, setCarrier] = useState(null);
    const [department, setDepartment] = useState(null);
    const [agent, setAgent] = useState(null);
    const [departmentselected, setDepartmentSelected] = useState(parseLoginRes?.department);
    const [isLoading, setIsLoading] = useState(false);
    const [carrierSelected, setCarrierSelected] = useState("");
    //Make Options
    useEffect(() => {
        if (department === null) {
            api.get(`/api/deparments/getDepartments?company=${parseLoginRes.company}`)
                .then((res) => {
                    let departmentholder = [];
                    for (let i = 0; i < res.data.data.length; i++) {
                        const obj = {};
                        obj.label = res.data.data[i].department;
                        obj.value = res.data.data[i]._id;
                        departmentholder.push(obj);
                    }
                    setDepartment(departmentholder);
                    // Move this inside the promise callback
                })
                .catch(() => {});
        }
    }, []);
    useEffect(() => {
        if (departmentselected !== null) {
            api.get(`/api/web/user/getByDepartments?department=${departmentselected}`)
                .then((res) => {
                    let agentholder = [];
                    for (let i = 0; i < res.data.data.length; i++) {
                        const obj = {};
                        obj.label = res.data.data[i].name;
                        obj.value = res.data.data[i]._id;
                        agentholder.push(obj);
                    }
                    setAgent(agentholder);
                })
                .catch(() => {});
        }
    }, [departmentselected]);
    useEffect(() => {
        api.get(`/api/web/carrier/all`)
            .then((res) => {
                let carrierholder = [];
                for (let i = 0; i < res.data.data.length; i++) {
                    const obj = {};
                    obj.label = res.data.data[i].name;
                    obj.value = res.data.data[i]._id;
                    carrierholder.push(obj);
                }
                if (carrierholder.length === 1) {
                    formik.setFieldValue("carrier", carrierholder[0].value);
                    setCarrierSelected(carrierholder[0].label);
                }
                setCarrier(carrierholder);
            })
            .catch(() => {});
    }, []);

    const formik = useFormik({
        validationSchema: Yup.object({
            carrier: Yup.string().required("Carrier is required"),
            SimNumber:
                identifierTypeSelected === "BOTH" || identifierTypeSelected === "ESN"
                    ? Yup.string()
                          .required("ESN is required")
                          .test("len", "ESN must be the correct length", (value) => {
                              if (carrierSelected === "AT&T") {
                                  return value && value.length === 20; // 20 characters for AT&T
                              } else {
                                  return value && value.length === 19; // 19 characters for others
                              }
                          })
                          .matches(/^\d+$/, "IMEI must contain only digits")
                    : Yup.string().notRequired(),
            box: Yup.string().required("Box is required"),
            Model: Yup.string().required("Model is required"),
            IMEI:
                identifierTypeSelected === "BOTH" || identifierTypeSelected === "IMEI"
                    ? Yup.string()
                          .required("IMEI is required")
                          .test("len", "IMEI must be the correct length", (value) => {
                              if (carrierSelected === "AT&T") {
                                  return value && value.length === 15; // 20 characters for AT&T
                              } else {
                                  return value && value.length === 15; // 19 characters for others
                              }
                          })
                          .matches(/^\d+$/, "IMEI must contain only digits")
                    : Yup.string().notRequired(),
            agentType: Yup.string().required("Department is required"),
        }),
        initialValues: {
            carrier: "",
            serviceProvider: parseLoginRes?.company,
            agentType: parseLoginRes?.department,
            AgentName: parseLoginRes?._id,
            SimNumber: "",
            box: "",
            Model: makeModel.substring(makeModel.lastIndexOf("-") + 2),
            unitType: unit,
            make: makeModel.substring(0, makeModel.indexOf("-") - 1),
            inventoryTypeId: inventoryIdSelected,
            billingModel: model,
            Uploaded_by: parseLoginRes?._id,
            IMEI: "",
        },

        onSubmit: (values, { resetForm }) => {
            setIsLoading(true);
            const dataTosend = { ...formik.values, inventoryTypeId: inventoryIdSelected };

            api.post(`/api/web/simInventory/addInventory`, dataTosend)
                .then((res) => {
                    ref.current.show({ severity: "success", summary: "Inventory", detail: "Successfully Added" });
                    resetForm();
                    if (carrier.length === 1) {
                        formik.setFieldValue("carrier", carrier[0].value);
                    }
                    setIsLoading(false);
                })
                .catch((error) => {
                    ref.current.show({ severity: "error", summary: "Inventory", detail: error?.response?.data?.msg });
                    setIsLoading(false);
                });
        },
    });
    useEffect(() => {
        if (formik.values.carrier !== "") {
            const carrierlabel = carrier.find((carrier) => carrier.value === formik.values.carrier);
            setCarrierSelected(carrierlabel.label);
        }
    }, [formik.values.carrier]);
    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="flex flex-wrap mb-3 main-addunit justify-content-left ">
                    <div className="mainaddunitfield">
                        <p className="field_label ">
                            Carrier <span style={{ color: "red" }}>*</span>
                        </p>
                        <Dropdown value={formik.values.carrier} options={carrier} optionLabel="label" name="carrier" optionValue="value" onChange={formik.handleChange} placeholder="Select an option" className="w-full " />
                        {formik.errors.carrier && formik.touched.carrier && (
                            <div className="mt-2" style={{ color: "red" }}>
                                {formik.errors.carrier}
                            </div>
                        )}
                    </div>
                    {identifierTypeSelected === "BOTH" || identifierTypeSelected === "ESN" ? (
                        <div className="mainaddunitfield">
                            <p className="field_label ">
                                ESN <span style={{ color: "red" }}>*</span>
                            </p>
                            <InputText keyfilter="int" maxLength={carrierSelected === "AT&T" ? 20 : 19} value={formik.values.SimNumber} name="SimNumber" onChange={formik.handleChange} onBlur={formik.handleBlur} className="w-full " />
                            {formik.errors.SimNumber && formik.touched.SimNumber && (
                                <div className="mt-2" style={{ color: "red" }}>
                                    {formik.errors.SimNumber}
                                </div>
                            )}
                        </div>
                    ) : undefined}
                    {identifierTypeSelected === "BOTH" || identifierTypeSelected === "IMEI" ? (
                        <div className="mainaddunitfield">
                            <p className="field_label">
                                IMEI
                                <span style={{ color: "red" }} className="ml-1">
                                    *
                                </span>
                            </p>
                            <InputText type="text" keyfilter="int" maxLength={15} value={formik.values.IMEI} name="IMEI" onChange={formik.handleChange} onBlur={formik.handleBlur} className="w-full " />
                            {formik.errors.IMEI && formik.touched.IMEI && (
                                <div className="mt-2" style={{ color: "red" }}>
                                    {formik.errors.IMEI}
                                </div>
                            )}
                        </div>
                    ) : (
                        ""
                    )}
                    <div className="mainaddunitfield">
                        <p className="field_label">
                            Company Name <span style={{ color: "red" }}>*</span>
                        </p>
                        <InputText value={parseLoginRes?.companyName} name="serviceProvider" disabled className="w-full " />
                    </div>
                    <div className="mainaddunitfield">
                        <p className="field_label">
                            Department/Vendor Name <span style={{ color: "red" }}>* </span>
                        </p>

                        <Dropdown
                            disabled
                            value={formik.values.agentType}
                            options={department}
                            onChange={(e) => {
                                formik.setFieldValue("agentType", e.value);
                                setDepartmentSelected(e.value);
                            }}
                            placeholder="Select an option"
                            className="w-full "
                        />
                        {formik.errors.agentType && formik.touched.agentType && (
                            <div className="mt-2" style={{ color: "red" }}>
                                {formik.errors.agentType}
                            </div>
                        )}
                    </div>
                    <div className="mainaddunitfield">
                        <p className="field_label">
                            Agent Name <span style={{ color: "red" }}>* </span>
                        </p>

                        <Dropdown disabled value={formik.values.AgentName} options={agent} onChange={(e) => formik.setFieldValue("AgentName", e.value)} placeholder="Select an option" className="w-full " />
                        {formik.errors.AgentName && formik.touched.AgentName && (
                            <div className="mt-2" style={{ color: "red" }}>
                                {formik.errors.AgentName}
                            </div>
                        )}
                    </div>

                    <div className="mainaddunitfield">
                        <p className="field_label">
                            Box#<span style={{ color: "red" }}>*</span>
                        </p>
                        <InputText type="text" value={formik.values.box} name="box" onChange={formik.handleChange} onBlur={formik.handleBlur} className="w-full " />
                        {formik.errors.box && formik.touched.box && (
                            <div className="mt-2" style={{ color: "red" }}>
                                {formik.errors.box}
                            </div>
                        )}
                    </div>
                    <div className="cellphoneactionbuttons w-full align-items-start flex flex-wrap flex-row justify-content-end">
                        <Button
                            className="btn"
                            label="Back"
                            onClick={() => {
                                setActiveComponent("");
                            }}
                        />
                        <Button label="Submit" loading={isLoading} className="field-width " type="submit" disabled={!permissions.isCreate || isLoading} />
                    </div>
                </div>
            </form>
            <Dialog
                visible={add_cellphone_model_dialog_visibility}
                onHide={() => {
                    setAddCellPhoneModelDialogVisbility((prev) => !prev);
                }}
            >
                <AddCellPhoneModelDialog agent={"Dummy"} />
            </Dialog>
            <Dialog
                visible={add_agent_detail_dialog_visibility}
                onHide={() => {
                    setAddAgentDialogVisbility((prev) => !prev);
                }}
            >
                <AddAgentDetail agent={"Dummy"} />
            </Dialog>
            <Toast ref={ref} />
        </>
    );
}
