import { api } from "../../../../utility/AxiosInceptor";
import React, { useState, useEffect, useRef } from "react";
import Axios from "axios";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";
import { InputText } from "primereact/inputtext";
import classNames from "classnames";
import { InputSwitch } from "primereact/inputswitch";
import { useLocation, useNavigate } from "react-router-dom";
import "./css/create_role.css";
import { Toast } from "primereact/toast";
import "react-toastify/dist/ReactToastify.css";
import CommonLoaderBlue from "../../../components/Loader/LoaderBlue";
const CreateRole = ({ setUserScreen }) => {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const [isLoading, setIsLoading] = useState(true);
    let toast = useRef(null);
    const [rolePermissions, setRolePermissions] = useState([]);
    const location = useLocation();
    const currentPath = location?.pathname;
    const { rowData } = location.state || {};
    const [moduleData, setModuleData] = useState([]);
    const [selectedModules, setSelectedModules] = useState({});
    const [selectedSubmodules, setSelectedSubmodules] = useState({});
    const [selectedActions, setSelectedActions] = useState({});
    const [errorMessage, setErrorMessage] = useState();
    const [isLoadingFormSubmit, setIsLoadingFormSubmit] = useState(false);
    const [isCreate, setIsCreate] = useState(false);
    const [isManage, setIsManage] = useState(false);
    const navigate = useNavigate();
    // Get user data from ls
    const loginRes = localStorage.getItem("userData");
    const parseLoginRes = JSON.parse(loginRes);

    // Validation Schema
    const validationSchema = Yup.object().shape({
        role: Yup.string().required("This field is required."),
    });
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            role: "",
            description: "",
        },
        onSubmit: async () => {
            setIsLoadingFormSubmit(true);
            const permissions = [];
            // Define the data object
            const data = {
                serviceProvider: parseLoginRes?.company,
                permissions: permissions,
                role: formik.values.role,
                description: formik.values.description,
                isSperPanelRole: false,
            };
            let l = 1;
            const selectedSubmoduleKeys = Object.keys(selectedSubmodules);
            function processNextSubmodule() {
                if (l <= selectedSubmoduleKeys.length) {
                    const submoduleId = selectedSubmoduleKeys[l - 1];
                    if (selectedSubmodules[submoduleId]) {
                        const submoduleActions = [];
                        moduleData.forEach((module) => {
                            module.submodule.forEach((submodule) => {
                                if (submodule._id === submoduleId) {
                                    submodule.actions.forEach((action) => {
                                        const key = `${submoduleId}-${action._id}`;
                                        if (selectedActions[key]) {
                                            submoduleActions.push(action._id);
                                        }
                                    });
                                }
                            });
                        });
                        permissions.push({
                            subModule: submoduleId,
                            // actions: submoduleActions,
                        });
                    }
                    l++;
                    processNextSubmodule(); // Recursively process the next submodule
                }
            }
            processNextSubmodule(); // Start processing the first submodule
            console.log("permission is ", permissions);

            api.post(`/api/web/role`, data)
                .then((response) => {
                    toast.current.show({ severity: "success", summary: "Info", detail: "Role Added Successfully" });

                    formik.resetForm();
                    setIsLoadingFormSubmit(false);
                })
                .catch((error) => {
                    setErrorMessage(error?.response?.data?.msg);

                    toast.current.show({ severity: "error", summary: "Info", detail: error?.response?.data?.msg });
                    setIsLoadingFormSubmit(false);
                });

            // Send the data to the server using Axios POST request
        },
    });
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };
    const getModules = async () => {
        try {
            const res = await api.get(`/api/web/module`);
            let module = res?.data?.data.map((item) => {
                return { ...item, show: false };
            });
            setModuleData(module || []);
            setTimeout(() => {
                setIsLoading(false);
            }, 200);
        } catch (error) {
            setIsLoading(false);
        }
    };
    useEffect(() => {
        getModules();
    }, []);
    const handleModuleCheckboxChange = (moduleId) => {
        setModuleData((prevModules) => prevModules.map((module) => (module._id === moduleId ? { ...module, show: !module.show } : { ...module, show: false })));
        console.log(selectedModules);
        // setSelectedModules((prevSelectedModules) => ({
        //     ...prevSelectedModules,
        //     [moduleId]: !prevSelectedModules[moduleId],
        // }));
        let obj = {};
        if (selectedModules[moduleId]) {
            Object.keys(selectedModules).map((item) => {
                if (item === moduleId) {
                    obj[item] = !selectedModules[item];
                } else {
                    obj[item] = false;
                }
            });
        } else {
            Object.keys(selectedModules).map((item) => {
                obj[item] = false;
            });
            obj[moduleId] = true;
        }
        setSelectedModules(obj);
        // setSubmodulesAndActions(moduleId, !selectedModules[moduleId]);
    };
    const setSubmodulesAndActions = (moduleId, checked) => {
        const module = moduleData.find((module) => module._id === moduleId);
        if (module) {
            module.submodule.forEach((submodule) => {
                setSelectedSubmodules((prevSelectedSubmodules) => ({
                    ...prevSelectedSubmodules,
                    [submodule._id]: checked,
                }));
                submodule.actions.forEach((action) => {
                    togglePermission(submodule._id, action._id);
                });
            });
        }
    };
    const togglePermission = (submoduleId, actionId) => {
        setSelectedActions((prevSelectedActions) => {
            const key = `${submoduleId}-${actionId}`;
            return {
                ...prevSelectedActions,
                [key]: !prevSelectedActions[key],
            };
        });
    };
    const handleSubmoduleCheckboxChange = (submoduleId) => {
        const updatedSubmodules = {
            ...selectedSubmodules,
            [submoduleId]: !selectedSubmodules[submoduleId],
        };
        setSelectedSubmodules(updatedSubmodules);
        // Check permissions if submodule is checked, uncheck if it's unchecked
        if (updatedSubmodules[submoduleId]) {
            checkPermissionsForSubmodule(submoduleId);
        } else {
            uncheckPermissionsForSubmodule(submoduleId);
        }
    };
    // to check permissions for a submodule
    const checkPermissionsForSubmodule = (submoduleId) => {
        setSelectedActions((prevSelectedActions) => {
            const updatedActions = { ...prevSelectedActions };
            moduleData.forEach((module) => {
                module.submodule.forEach((submodule) => {
                    if (submodule._id === submoduleId) {
                        submodule.actions.forEach((action) => {
                            updatedActions[`${submoduleId}-${action._id}`] = true;
                        });
                    }
                });
            });
            return updatedActions;
        });
    };
    // to uncheck permissions for a submodule
    const uncheckPermissionsForSubmodule = (submoduleId) => {
        setSelectedActions((prevSelectedActions) => {
            const updatedActions = { ...prevSelectedActions };
            Object.keys(updatedActions).forEach((actionId) => {
                if (actionId.startsWith(`${submoduleId}-`)) {
                    updatedActions[actionId] = false;
                }
            });
            return updatedActions;
        });
    };
    const getPermissionsByRoleId = async () => {
        try {
            const res = await api.get(`/api/web/role/roleDetails?roleId=${rowData?.role?._id}`);
            if (res?.status === 200 || res?.status === 201) {
                setRolePermissions(res?.data?.data?.permissions);
            }
        } catch (error) {}
    };

    const actionBasedChecks = () => {
        const loginPerms = localStorage.getItem("permissions");
        const parsedLoginPerms = JSON.parse(loginPerms);

        const isCreate = parsedLoginPerms.some((node) => node?.subModule.some((subNode) => subNode?.route === currentPath && subNode?.actions.some((action) => action?.name === "create")));
        setIsCreate(isCreate);

        const isManage = parsedLoginPerms.some((node) => node?.subModule.some((subNode) => subNode?.route === currentPath && subNode?.actions.some((action) => action?.name === "manage")));
        setIsManage(isManage);
    };

    useEffect(() => {
        if (rowData) {
            getPermissionsByRoleId();
        }
    }, [rowData]);

    useEffect(() => {
        actionBasedChecks();
    }, []);
    function calculateSubmoduleName(module) {
        //console.log("i am here");
        let str = "";
        module.submodule.forEach((item, index) => {
            //  console.log(item);
            if (index < 3) {
                if (index === 2) {
                    str += item.name + "...";
                } else {
                    str += item.name + " ,";
                }
            }
        });
        //console.log(str);
        return str;
    }
    function calculateSubmoduleNameCaseLessthan3(module) {
        let str = "";
        if (module.submodule.length === 3) {
            module.submodule.forEach((item, index) => {
                //  console.log(item);

                if (index === 2) {
                    str += item.name;
                } else {
                    str += item.name + " ,";
                }
            });
        } else if (module.submodule.length === 2) {
            module.submodule.forEach((item, index) => {
                //  console.log(item);

                if (index === 1) {
                    str += item.name;
                } else {
                    str += item.name + " ,";
                }
            });
        } else {
            module.submodule.forEach((item, index) => {
                //  console.log(item);

                str += item.name;
            });
        }
        //console.log(str);
        return str;
    }
    return (
        <div className="main-new-role">
            <i className="go-back pi pi-angle-left " onClick={() => setUserScreen(false)} style={{ cursor: "pointer" }} />
            <h3 className="header ">Add New Group</h3>
            <div className="addrole-main-container">
                <div className="w-full flex flex-wrap flex-row justify-content-between align-items-center">
                    <label>Role</label>
                    <p>{getFormErrorMessage("role")}</p>
                </div>
                <InputText id="role" name="role" placeholder="Enter Role Name" value={formik.values.role} onChange={formik.handleChange} className={`${formik.touched.role && formik.errors.role ? "emailinputerr" : "role_input"} Input__Round  w-full`} keyfilter={/^[A-Za-z\s]+$/} maxLength={30} />
            </div>

            <Toast ref={toast} />

            <div className="permission-module-container">
                {isLoading ? (
                    <div style={{ marginTop: "150px" }} className="w-full mt-4 flex flex-wrap flex-row justify-content-center">
                        <CommonLoaderBlue sizehere={24} />
                    </div>
                ) : moduleData.length > 0 ? (
                    moduleData.map((module, index) => (
                        <ul className="main-module">
                            <li id={`${module.name}${index}`} className="w-full main-module-list-item flex flex-wrap flex-row justify-content-between">
                                <div>
                                    <p> {module.name}</p>
                                    <p>{module.submodule.length > 3 ? calculateSubmoduleName(module) : calculateSubmoduleNameCaseLessthan3(module)}</p>
                                </div>
                                <InputSwitch
                                    type="checkbox"
                                    id={`${module._id}`}
                                    checked={selectedModules[module._id] || false}
                                    onChange={() => {
                                        handleModuleCheckboxChange(module._id);
                                        setTimeout(() => {
                                            //     window.scrollTo(0, -20);
                                            var element = document.getElementById(`${module.name}${index}`);
                                            console.log(element);
                                            // Scroll the element into view
                                            element.scrollIntoView({
                                                behavior: "smooth", // Smooth scroll
                                                block: "center", // Scroll to the top of the element
                                            });
                                            // Adjust by 40px from the top
                                        }, 100);
                                    }}
                                />
                            </li>
                            {module.show
                                ? module.submodule.map((submodule) => (
                                      <ul className="sub-module">
                                          <li className="w-full sub-module-list-item flex flex-wrap flex-row justify-content-between">
                                              <p>{submodule.name}</p>
                                              <InputSwitch style={{ cursor: !(isCreate || isManage) ? "not-allowed" : "pointer" }} type="checkbox" checked={selectedSubmodules[submodule._id] || false} onChange={() => handleSubmoduleCheckboxChange(submodule._id)} disabled={!(isCreate || isManage)} />
                                          </li>
                                      </ul>
                                  ))
                                : undefined}
                        </ul>
                    ))
                ) : (
                    <p className="no-datato-show">No Data To Show</p>
                )}
            </div>
            <div className="flex actions-button w-full flex-wrap flex-row justify-content-end ">
                <button> Cancel</button>
                <button onClick={formik.handleSubmit}>
                    {isLoadingFormSubmit ? (
                        <div className="flex flex-wrap flex-row justify-content-center w-full">
                            <CommonLoaderBlue color={"white"} sizehere={14} />
                        </div>
                    ) : (
                        "Submit"
                    )}
                </button>
            </div>
        </div>
    );
};
export default CreateRole;
