import React, { useEffect, useRef, useState } from "react";
import * as echarts from "echarts";
import "./Earning-Chart.css";
import moment from "moment";
import Axios from "axios";
import { api } from "../../../../../../utility/AxiosInceptor";
import CommonLoaderBlue from "../../../../../components/Loader/LoaderBlue";
import { color } from "highcharts";
import { object } from "yup";

const EarningDonutChart = ({ allChartData, shortSelection, selectedRange }) => {
    const chartRef = useRef(null);
    console.log(allChartData);
    const [isLoading, setIsLoading] = useState(true);
    const chartContainerRef = useRef(null);

    const initializeChart = () => {};

    const getColorPalette = (numColors) => {
        const baseColors = ["#1DBF73", "#3FC8E4", "#FF9F0A"];
        const extendedColors = [];
        while (extendedColors.length < numColors) {
            extendedColors.push(...baseColors);
        }
        return extendedColors.slice(0, numColors);
    };

    const updateChart = (data) => {
        const length = window.innerHeight < 600 ? 15 : window.innerHeight < 800 ? 15 : 15;
        const fontSize = 14;

        const option = {
            tooltip: {
                trigger: "item",
                formatter: "{a} <br/>{b} : {c} ({d}%)",
                backgroundColor: "rgba(0,0,0,.7)", // Set background color to black

                textStyle: {
                    fontFamily: "Inter",
                    fontWeight: 400,
                    fontSize,
                    color: "#fff",
                },
                padding: [2, 10, 2, 10],
                position: function (point, params, dom, rect, size) {
                    console.log("pokint is", point);
                    // `point`: The position of the pointer
                    // `params`: The parameters of the hovered item
                    // `dom`: The DOM element of the tooltip
                    // `rect`: The bounding rectangle of the chart
                    // `size`: The size of the tooltip

                    // Custom position (offset the tooltip to the right of the pie chart)
                    if (point[0] > 135) {
                        return [point[0] - 160, point[1] - 0];
                    } else {
                        return [point[0] - 40, point[1] - 0];
                    }
                    // Adjust these values as per your requirement
                },
            },
            series: [
                {
                    name: "Tracking",
                    type: "pie",
                    radius: ["44%", "70%"],
                    center: ["50%", "50%"],
                    padAngle: 2,
                    label: {
                        fontFamily: "Inter",
                        fontWeight: 400,
                        fontSize,
                        lineHeight: fontSize + 2,
                        formatter: (params) => `${params.name}\n${params.value}`,
                        position: "outside",
                        verticalAlign: "middle",
                        show: false,
                    },
                    labelLine: {
                        length: length,
                        length2: length,
                        maxSurfaceAngle: 90,
                        show: false,
                    },
                    data,
                    emphasis: {
                        // Disable hover effect
                        scale: false,
                        itemStyle: {
                            opacity: 1, // Keeps the opacity the same on hover
                            // Keeps the color the same on hover
                            borderRadius: 4, // Keeps the border radius the same
                            // Keep the same border radius on hover
                            shadowBlur: 0, // No shadow effect on hover
                            shadowColor: "transparent", // No shadow color on hover
                            shadowOffsetX: 0, // No shadow offset on hover
                            shadowOffsetY: 0,
                            scale: false,
                        },
                    },
                },
            ],
            graphic: [
                {
                    type: "text",
                    left: "center",
                    top: "44%",
                    style: {
                        text: "Total Enrollments ", // Static value that you want to show in the center
                        textAlign: "center",
                        fill: "#8B8BA7", // Text color
                        fontSize: 10, // Font size
                        fontWeight: "700", // Font weight
                    },
                },
                {
                    type: "text",
                    left: "center",
                    top: "49%",
                    style: {
                        text: "0", // Static value that you want to show in the center
                        textAlign: "center",
                        fill: "#414064", // Text color

                        fontSize: 32, // Font size
                        fontWeight: "500", // Font weightv
                        fontFamily: "Space Grotesk",
                    },
                },
            ],
        };
        if (chartContainerRef.current) {
            chartRef.current = echarts.init(chartContainerRef.current, null, { renderer: "svg" });
            chartRef.current.setOption(option);
            setTimeout(() => {
                setIsLoading(false);
            }, 500);
        }
    };

    const fetchDataAndUpdateChart = () => {
        console.log(allChartData);

        if (allChartData) {
            if (Object.keys(allChartData).length > 0) {
                let dataforchart = [
                    {
                        name: "In Transit",
                        value: allChartData.inTransit ? allChartData.inTransit : 0,
                        itemStyle: { color: "#1DBF73" },
                    },
                    {
                        name: "Pending Dispatch",
                        value: allChartData.inTransit ? allChartData.inTransit : 0,
                        itemStyle: { color: "#3FC8E4" },
                    },
                    {
                        name: "Delivered",
                        value: allChartData.delivered ? allChartData.delivered : 0,
                        itemStyle: { color: "#FF9F0A" },
                    },
                ];
                updateChart(dataforchart);
            }
        }
    };

    useEffect(() => {
        if (allChartData && Object.keys(allChartData).length > 0) {
            fetchDataAndUpdateChart();
        }

        const handleResize = () => {
            if (chartRef.current) {
                chartRef.current.resize();
                fetchDataAndUpdateChart();
            }
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
            if (chartRef.current) {
                chartRef.current.dispose();
            }
        };
    }, [shortSelection, allChartData, selectedRange]);

    return (
        <>
            <div className="earning-chart-main">
                <h1>Tracking</h1>
                {isLoading ? (
                    <div className=" flex flex-wrap flex-row justify-content-center align-items-center w-full h-full">
                        <CommonLoaderBlue sizehere={40} color={"rgb(78, 105, 251)"} />
                    </div>
                ) : (
                    " "
                )}

                <div style={{ width: "300px", marginLeft: "-38px" }} className="flex overflow-visible flex-wrap earning-chart-container-main flex-row justify-content-start">
                    <div ref={chartContainerRef} id="chartdiv"></div>
                </div>
                {!isLoading ? (
                    <div className="w-full custom-legend">
                        <div className="flex flex-wrap flex-row  w-full justify-content-between">
                            <div className="pending-dispatch-legend w-full flex flex-wrap flex-row justify-content-between">
                                <div className="flex flex-wrap flex-row justify-content-start align-items-center">
                                    <div className="rectangle"></div>
                                    <p>Pending Dispatch:</p>
                                </div>
                                <h6>{allChartData.inTransit ? allChartData.inTransit : 0}</h6>
                            </div>
                            <div className="intransit-legend w-full flex flex-wrap flex-row justify-content-between">
                                <div className="flex flex-wrap flex-row justify-content-start align-items-center">
                                    <div className="rectangle"></div>
                                    <p>In Transit:</p>
                                </div>
                                <h6>{allChartData.inTransit ? allChartData.inTransit : 0}</h6>
                            </div>
                            <div className="delivered-legend w-full flex flex-wrap flex-row justify-content-between">
                                <div className="flex flex-wrap flex-row justify-content-start align-items-center">
                                    <div className="rectangle"></div>
                                    <p>Delivered:</p>
                                </div>
                                <h6>{allChartData?.delivered ? allChartData?.delivered : 0}</h6>
                            </div>
                        </div>
                    </div>
                ) : undefined}
            </div>
        </>
    );
};

export default EarningDonutChart;
