import { api } from "../../../../utility/AxiosInceptor";
import React, { useState, useEffect, useRef } from "react";
import Axios from "axios";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import { useLocation, useNavigate } from "react-router-dom";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { toast } from "react-toastify";
import EditUser from "./EditUser";
import "./user_management.css";
import { OverlayPanel } from "primereact/overlaypanel";
import { Status, NextIcon, PrevIcon, Editbutton, CancelPopup, EmptyRole } from "../../../../utility";
import CommonLoaderBlue from "../billingModel/billingmodel_configurations/Loaders/components/Loader/LoaderBlue";
import CreateUser from "./CreateUser";
import CreateRole from "../roles_and_permissions/CreateRole";
const BASE_URL = process.env.REACT_APP_BASE_URL;
const ManageUser = () => {
    const [showEditUser, setShowEditUser] = useState(false);
    let toastfordelete = useRef(null);
    const [allUsers, setAllUsers] = useState([]);
    const [userId, setUserId] = useState(null);
    const [visibleDeleteUser, setVisibleDelelteUser] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [isCreate, setIsCreate] = useState(false);
    const [isManage, setIsManage] = useState(false);
    const [showAddUser, setShowAddUser] = useState(false);
    const [rowData, setRowData] = useState(null);
    const [prospect, setProspect] = useState(false);
    const [cancelled, setCancelled] = useState(false);
    const [all, setAll] = useState(false);
    const [selectedRoles, setSelectedRoles] = useState([]);
    const [rowClick, setRowClick] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [allRolesData, setAllRolesData] = useState([]);
    const [customerSearchName, setCustomerSearchName] = useState("");
    const [totalSearchResult, setTotalSearchResult] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [rolesDataFrom, setRolesDataFrom] = useState(1);
    const [roleDataTo, setRolesDataTo] = useState(15);
    const [completeListEnrollment, setCompleteListEnrollment] = useState([]);
    const [selectedNumberToShow, setSelectedNumberToShow] = useState(15);
    const [totalIncaseSearch, setTotalIncaseSearch] = useState(0);
    const [totalPage, setTotalPage] = useState(0);
    const [userScreen, setUserScreen] = useState(false);
    const op = useRef(null);
    const location = useLocation();
    const currentPath = location?.pathname;

    // Get user data from ls
    const loginRes = localStorage.getItem("userData");
    const parseLoginRes = JSON.parse(loginRes);
    const role = parseLoginRes?.role?.role;
    const navigate = useNavigate();
    const actions = (rowData) => {
        return (
            <>
                <div className="flex align-items-center justify-content-center">
                    <div onClick={() => handleUserEdit(rowData)} disabled={!isManage}>
                        <span>
                            <Editbutton />
                        </span>
                    </div>
                    {/* <Button icon="pi pi-trash" rounded outlined severity="danger" onClick={() => handleUserDelete(rowData)} /> */}
                </div>
            </>
        );
    };

    const handleUserEdit = (rowData) => {
        setRowData(rowData);
        setShowEditUser((prev) => !prev);
    };
    const handleUserDelete = async (rowData) => {
        setUserId(rowData._id);
        setVisibleDelelteUser(true);
    };

    const getAllUsers = async () => {
        try {
            const res = await api.get(`/api/web/user/all?company=${parseLoginRes?.company}`, { withCredentials: true });
            setAllUsers(res?.data?.data || []);
            // Pagination logic
            setAllRolesData(res?.data?.data.slice(0, 15)); // Initially display first 15 records
            setCompleteListEnrollment(res?.data?.data); // Full list for manual pagination
            setTotalPage(Math.ceil(res?.data?.data.length / 15)); // Total number of pages
        } catch (error) {
            toast.error(`Error fetching module data: ${error?.response?.data?.msg}`);
        }
    };
    useEffect(() => {
        getAllUsers();
    }, []);

    const redirectToCreateUser = () => {
        navigate("/create-user");
    };

    const redirectToUpdatePermissions = (rowData) => {
        navigate("/createrole", {
            state: { rowData },
        });
    };

    function confirmDeleteUser() {
        api.delete(`/api/web/user?userId=${userId}`)
            .then(() => {
                toastfordelete.current.show({ severity: "success", summary: "Info", detail: "Deleted User Successfully" });
                getAllUsers();
            })
            .catch((err) => {
                toastfordelete.current.show({ severity: "error", summary: "Info", detail: "Deleted User Failed" });
            });

        setVisibleDelelteUser(false);
    }
    function skipDeleteUser() {
        setVisibleDelelteUser(false);
    }

    const filteredUsers = allRolesData.filter((user) => {
        return user?.name.toLowerCase().includes(searchText.toLowerCase()) || user?.role?.role.toLowerCase().includes(searchText.toLowerCase());
    });

    const actionBasedChecks = () => {
        const loginPerms = localStorage.getItem("permissions");
        const parsedLoginPerms = JSON.parse(loginPerms);

        const isCreate = parsedLoginPerms.some((node) => node?.subModule.some((subNode) => subNode?.route === currentPath && subNode?.actions.some((action) => action?.name === "create")));
        setIsCreate(isCreate);

        const isManage = parsedLoginPerms.some((node) => node?.subModule.some((subNode) => subNode?.route === currentPath && subNode?.actions.some((action) => action?.name === "manage")));
        setIsManage(isManage);
    };

    useEffect(() => {
        actionBasedChecks();
    }, []);
    return (
        <>
            {userScreen ? (
                <CreateRole setUserScreen={setUserScreen} />
            ) : (
                <div className="manageusermain">
                    {selectedRoles?.length > 0 && (
                        <div className="flex flex-column justify-content-start align-items-center selecteddatapopup">
                            <div className="flex w-full justify-content-end">
                                <span onClick={() => setSelectedRoles([])} style={{ cursor: "pointer" }}>
                                    <CancelPopup />
                                </span>
                            </div>
                            <div className="flex justify-content-evenly w-full align-items-center selectedrowdata">
                                <div className="flex showselectedroles" style={{ width: "12rem" }}>
                                    <p>
                                        Selected: <span>{selectedRoles?.length}</span>
                                    </p>
                                </div>
                                <div className="flex justify-content-between" style={{ width: "26.2rem" }}>
                                    <Button className="addnewrolebtn" label="Deactive" style={{ width: "12rem" }} />
                                    <Button className="adduserbtn" label="Active" style={{ width: "12rem" }} />
                                </div>
                            </div>
                        </div>
                    )}
                    <div>
                        <h3>User Management</h3>
                        <p className="mt-2">Create new Groups and Persmisions</p>
                    </div>
                    <div className="flex justify-content-between align-items-center mt-4">
                        <div className="flex justify-content-between align-items-center" style={{ width: "15.5rem", height: "4.2rem" }}>
                            <div className="allusers">
                                <p>All Users</p>
                            </div>
                            <div className="rolebtn">
                                <p>Roles</p>
                            </div>
                        </div>
                        <div className="flex align-items-center justify-content-between" style={{ width: "66.8rem" }}>
                            <div className="flex align-items-center" style={{ width: "30rem", height: "4rem" }}>
                                <InputText className="searchfield w-full h-full" value={searchText} onChange={(e) => setSearchText(e.target.value)} placeholder="Search by Name or Role" />
                            </div>
                            <div>
                                <button className="selector" onClick={(e) => op.current.toggle(e)}>
                                    <Status />
                                </button>
                                <OverlayPanel ref={op}>
                                    <div className="flex flex-column w-full overlay">
                                        <div className="flex justify-content-start align-items-center" style={{ gap: "10px", height: "36px" }}>
                                            <input id="prepaid" name="prepaid" className="check" type="checkbox" onChange={(e) => setProspect(e.value)} checked={prospect} />
                                            <label>Prospect</label>
                                        </div>
                                        <div className="flex justify-content-start align-items-center" style={{ gap: "10px", height: "36px" }}>
                                            <input id="postpaid" name="postpaid" className="check" type="checkbox" onChange={(e) => setCancelled(e.value)} checked={cancelled} />
                                            <label>Cancelled</label>
                                        </div>
                                        <div className="flex justify-content-start align-items-center" style={{ gap: "10px", height: "36px" }}>
                                            <input id="acp" name="acp" className="check" type="checkbox" onChange={(e) => setAll(e.value)} checked={all} />
                                            <label>All</label>
                                        </div>
                                    </div>
                                </OverlayPanel>
                            </div>
                            <div style={{ width: "140px" }}>
                                <Button className="addnewrolebtn w-full" label="Add New Role" onClick={() => setUserScreen(true)} disabled={!(isCreate || isManage)} style={{ marginLeft: "1.2rem" }} />
                            </div>

                            <div style={{ width: "140px" }}>
                                <Button className="adduserbtn  w-full" label="Add User" onClick={() => setShowAddUser(true)} disabled={!(isCreate || isManage)} style={{ marginLeft: "1.2rem" }} />
                                {/* <Button className="adduserbtn w-full" label="Add User" onClick={redirectToCreateUser} disabled={!(isCreate || isManage)} /> */}
                            </div>
                        </div>
                    </div>

                    <div className="mt-3">
                        <DataTable
                            value={filteredUsers}
                            selectionMode={rowClick ? null : "checkbox"}
                            selection={selectedRoles}
                            onSelectionChange={role !== "Admin" ? null : (e) => setSelectedRoles(e.value)}
                            onRowClick={null}
                            emptyMessage={
                                isLoading ? (
                                    <div className="flex flex-wrap w-full justify-content-center  flex-row  mt-4 mb-4 ">
                                        <CommonLoaderBlue customSize={16} />
                                    </div>
                                ) : (
                                    "Enrollment Not Found"
                                )
                            }
                        >
                            <Column selectionMode="multiple" style={{ width: "1rem" }} />
                            <Column field="name" header="NAME"></Column>
                            <Column field="email" header="EMAIL"></Column>
                            <Column field="role.role" header="ROLE"></Column>
                            <Column field="createdDate" header="DATE" body={(rowData) => new Date(rowData.createdDate).toLocaleDateString()}></Column>
                            <Column field={(item) => (item?.active === true ? "Active" : "Inactive")} header="STATUS"></Column>
                            <Column body={actions} header="ACTION"></Column>
                        </DataTable>
                        <div className="flex w-full justify-content-between align-items-center mt-5">
                            <div className="totalcount">
                                <h4>
                                    {roleDataTo} / {customerSearchName.length > 0 ? totalIncaseSearch : completeListEnrollment.length}
                                </h4>
                            </div>
                            <div className="flex">
                                <button
                                    onClick={() => {
                                        const itemsPerPage = 15;
                                        const calculateTotalPages = (data) => Math.ceil(data?.length % itemsPerPage);

                                        const handlePageNavigation = (data, setDataFn) => {
                                            const totalPages = calculateTotalPages(data);
                                            if (currentPage > 1) {
                                                let startIdx, endIdx;

                                                if (totalPages - 1 === currentPage) {
                                                    startIdx = (currentPage - 2) * itemsPerPage;
                                                    endIdx = startIdx + itemsPerPage;
                                                } else if (totalPages === currentPage) {
                                                    startIdx = data.length - (data.length % itemsPerPage) - itemsPerPage;
                                                    endIdx = startIdx + itemsPerPage;
                                                } else {
                                                    startIdx = (currentPage - 2) * itemsPerPage;
                                                    endIdx = startIdx + itemsPerPage;
                                                }

                                                setDataFn(data.slice(startIdx, endIdx));
                                                setCurrentPage((prev) => prev - 1);
                                                setRolesDataFrom(startIdx + 1);
                                                setRolesDataTo(endIdx);
                                            }
                                        };

                                        if (customerSearchName?.length > 0) {
                                            handlePageNavigation(totalSearchResult, setAllRolesData);
                                        } else {
                                            handlePageNavigation(completeListEnrollment, setAllRolesData);
                                        }
                                    }}
                                    className="cursor-pointer backbutton"
                                    style={{ backgroundColor: "#FFFFFF33" }}
                                >
                                    <span>
                                        <PrevIcon /> &nbsp; Previous
                                    </span>
                                </button>
                                <div className="flex align-items-center justify-content-center" style={{ width: "12rem", fontWeight: "500", fontSize: "1.4rem", color: "#72728F" }}>
                                    &nbsp; {rolesDataFrom}-{roleDataTo}&nbsp; of&nbsp; {customerSearchName.length > 0 ? totalIncaseSearch : completeListEnrollment.length}
                                </div>

                                <button
                                    onClick={() => {
                                        if (customerSearchName.length > 0) {
                                            let totalPages = Math.ceil(totalSearchResult.length / 15);
                                            if (totalPages > currentPage) {
                                                let enrollmentTillCurrentPage = currentPage * 15;
                                                if (totalPages - 1 === currentPage) {
                                                    setAllRolesData(totalSearchResult.slice(enrollmentTillCurrentPage));

                                                    setCurrentPage((prev) => prev + 1);
                                                    setRolesDataFrom(enrollmentTillCurrentPage + 1);
                                                    setRolesDataTo(totalSearchResult.length);
                                                } else {
                                                    setAllRolesData(totalSearchResult.slice(enrollmentTillCurrentPage, enrollmentTillCurrentPage + 15));
                                                    setCurrentPage((prev) => prev + 1);
                                                    setRolesDataFrom(enrollmentTillCurrentPage + 1);
                                                    setRolesDataTo(enrollmentTillCurrentPage + 15);
                                                }
                                            }
                                        } else {
                                            let totalPages = Math.ceil(completeListEnrollment.length / 15);
                                            if (totalPages > currentPage) {
                                                let enrollmentTillCurrentPage = currentPage * 15;
                                                if (totalPages - 1 === currentPage) {
                                                    setAllRolesData(completeListEnrollment.slice(enrollmentTillCurrentPage));

                                                    setCurrentPage((prev) => prev + 1);
                                                    setRolesDataFrom(enrollmentTillCurrentPage + 1);
                                                    setRolesDataTo(completeListEnrollment.length);
                                                } else {
                                                    setAllRolesData(completeListEnrollment.slice(enrollmentTillCurrentPage, enrollmentTillCurrentPage + 15));

                                                    setCurrentPage((prev) => prev + 1);
                                                    setRolesDataFrom(enrollmentTillCurrentPage + 1);
                                                    setRolesDataTo(enrollmentTillCurrentPage + 15);
                                                }
                                            }
                                        }
                                    }}
                                    className="cursor-pointer nextbutton"
                                    style={{ backgroundColor: "#FFFFFF33" }}
                                >
                                    <span className="flex align-items-center">
                                        Next &nbsp;
                                        <NextIcon />
                                    </span>
                                </button>
                            </div>
                        </div>
                        <Dialog
                            header="Delete User"
                            visible={visibleDeleteUser}
                            style={{ width: "50vw", overflowX: "hidden", marginLeft: "50%", width: "100%", transform: "translate(-50%)" }}
                            draggable={false}
                            onHide={() => {
                                setVisibleDelelteUser(false);
                            }}
                        >
                            <div style={{ textAlign: "center" }}>
                                <p> Are You Sure to Delete a User ? </p>
                                <div style={{ marginTop: "45px" }}>
                                    <Button
                                        style={{ marginRight: "45px" }}
                                        label="Yes"
                                        onClick={() => {
                                            confirmDeleteUser();
                                        }}
                                    />{" "}
                                    <Button
                                        label="No"
                                        onClick={() => {
                                            skipDeleteUser();
                                        }}
                                    />
                                </div>
                            </div>
                        </Dialog>
                        <Dialog
                            header="null"
                            headerClassName="edituserdialogheader"
                            visible={showEditUser}
                            style={{ width: "80rem", height: "50rem" }}
                            onHide={() => {
                                setShowEditUser((prev) => !prev);
                            }}
                        >
                            <EditUser data={rowData} setShowEditUser={setShowEditUser} />
                        </Dialog>
                        <Dialog
                            header="null"
                            headerClassName="edituserdialogheader"
                            visible={showAddUser}
                            style={{ width: "80rem", height: "50rem" }}
                            onHide={() => {
                                setShowAddUser(false);
                            }}
                        >
                            <CreateUser setShowAddUser={setShowAddUser} />
                        </Dialog>
                    </div>

                    <Toast ref={toastfordelete} />
                </div>
            )}
        </>
    );
};

export default ManageUser;
