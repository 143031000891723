import React, { useEffect, useState } from "react";
import "./TopCards.css";
import { ActiveClients, Revenue, TotalEnrollment, Users } from "./assets";
import { api } from "../../../../../../utility/AxiosInceptor";
export default function TopCards() {
    const [data, setData] = useState([
        {
            svg: Users,
            title: "TOTAL USERS",
            count: 0,
            increase: true,
            sinceYester: "0",
        },
        {
            svg: TotalEnrollment,
            title: "TOTAL ENROLLMENTS",
            count: 0,
            normal: true,
            sinceYester: "0",
        },
        {
            svg: ActiveClients,
            title: "ACTIVE CLIENTS",
            count: 0,
            descrease: true,
            sinceYester: "0",
        },
        {
            svg: Revenue,
            title: "TOTAL REVENUE",
            count: "0",
            sinceLastMonth: "0%",
        },
    ]);
    useEffect(() => {
        api.get(`/api/web/dashboard/summery`)
            .then((res) => {
                let newData = [];
                data.map((item) => {
                    let itemNew = { ...item };
                    if (item.title === "TOTAL USERS") {
                        itemNew.count = res.data.result.totalUser;
                        itemNew.sinceYester = res.data.result.usersSinceYesterday;
                    } else if (item.title === "TOTAL ENROLLMENTS") {
                        itemNew.count = res.data.result.totalEnrollments;
                        itemNew.sinceYester = res.data.result.enrollmentsSinceYesterday;
                    } else if (item.title === "ACTIVE CLIENTS") {
                        itemNew.count = res.data.result.ActiveEnrollments;
                        itemNew.sinceYester = res.data.result.activeEnrollmentsSinceYesterday;
                    } else if (item.title === "TOTAL REVENUE") {
                        itemNew.count = res.data.result.totalRevenue;
                        itemNew.sinceLastMonth = res.data.result.percentageChange;
                    }
                    newData.push(itemNew);
                });
                console.log("new data is", newData);
                setData(newData);
            })
            .catch((err) => {});
    }, []);
    return (
        <div className="top-card-main-container flex flex-wrap flex-row justify-content-between w-full">
            {data.map((item, index) => {
                let Svg = item.svg;
                return (
                    <div className="top-card-main-wrapper  ">
                        <div className="w-full flex flex-wrap top-content  flex-row  align-items-center justify-content-start">
                            <Svg />
                            <p className="title-main">{item.title}</p>
                        </div>
                        <div className="bottom-content w-full flex flex-wrap flex-row  justify-content-start">
                            <h1>{item.count}</h1>
                            <p>
                                {item?.title !== "TOTAL REVENUE" ? (
                                    <p>
                                        <span className={`${item?.descrease && item.sinceYester !== 0 ? "decrease" : item?.increase && item.sinceYester !== 0 ? "increase" : "normal"}`}>{item?.sinceYester}</span>since yesterday
                                    </p>
                                ) : (
                                    <p>
                                        <span className="normal">{item?.sinceLastMonth}</span>Since last month
                                    </p>
                                )}
                            </p>
                        </div>
                    </div>
                );
            })}
        </div>
    );
}
