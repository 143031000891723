import React, { useEffect, useState } from "react";
import "./Initiation.css";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import Axios from "axios";
import { api } from "../../../../../../../utility/AxiosInceptor";
const BASE_URL = process.env.REACT_APP_BASE_URL;
export default function InitiateEnrollment({ setParentDetails, setInitiationData, parentDetails, setPersonalInfoActive, setInitiateActive, billingModelSelected, setCurrentBillingModel, setBillingModelSelected }) {
    const loginRes = localStorage.getItem("userData");
    const parseLoginRes = JSON.parse(loginRes);
    const [billingModel, setBillingModelList] = useState([]);
    const [billingModelError, setBillingModelError] = useState(false);
    const [initiationLoading, setInitiationLoading] = useState(false);
    useEffect(() => {
        api.get(`/api/billingModel/all?serviceProvider=${parseLoginRes?.company}`)
            .then((res) => {
                let permissions = localStorage.getItem("permissions");
                let parsedLoginPerms = JSON.parse(permissions);
                let permittedroutes = [];
                for (let i = 0; i < parsedLoginPerms.length; i++) {
                    if (parsedLoginPerms[i].module === "Prepaid SIMs" || parsedLoginPerms[i].module === "Postpaid Connections") {
                        permittedroutes.push(parsedLoginPerms[i].module);
                    }
                }
                let billingmodels = res?.data?.data;
                let permittedbillingmodel = [];
                for (let i = 0; i < billingmodels.length; i++) {
                    if (billingmodels[i].billingModel === "PREPAID" && permittedroutes.includes("Prepaid SIMs")) {
                        permittedbillingmodel.push(billingmodels[i]);
                    } else if (billingmodels[i].billingModel === "POSTPAID" && permittedroutes.includes("Postpaid Connections")) {
                        permittedbillingmodel.push(billingmodels[i]);
                    }
                }
                setBillingModelList(permittedbillingmodel);
            })
            .catch((err) => {});
    }, []);
    return (
        <div className="flex flex-wrap justify-content-center align-items-evenly initiatemain  ">
            <div className="initiateinner">
                <h1>Select Billing Model</h1>
                <p>Please choose a billing model for the child account to continue the enrollment process. </p>
                <div className="flex flex-column" style={{ width: "30rem", marginTop: "2rem" }}>
                    <label>Billing Model</label>
                    <Dropdown
                        className="innerdropdown mt-1"
                        options={billingModel}
                        onChange={(e) => {
                            setBillingModelSelected(e.value);
                            setBillingModelError(false);
                            for (let i = 0; i < billingModel.length; i++) {
                                if (e.value === billingModel[i]._id) {
                                    if (billingModel[i].billingModel === "PREPAID") {
                                        setParentDetails((prev) => ({
                                            ...prev,
                                            accountType: "Prepaid",
                                        }));
                                    } else if (billingModel[i].billingModel === "POSTPAID") {
                                        setParentDetails((prev) => ({
                                            ...prev,
                                            accountType: "Postpaid",
                                        }));
                                    }
                                    setCurrentBillingModel(billingModel[i].billingModel);
                                    break;
                                }
                            }
                        }}
                        optionLabel="billingModel"
                        optionValue="_id"
                        value={billingModelSelected}
                        placeholder="Select Billing Model"
                    />
                </div>
                {billingModelError ? <p className="p-error mt-2">Billing Model is Required</p> : ""}
            </div>
            <div className="flex justify-content-end" style={{ width: "92.4rem" }}>
                <Button
                    style={{ height: "5rem", width: "16rem" }}
                    onClick={() => {
                        if (billingModelSelected !== "") {
                            setInitiationLoading(true);
                            api.post(`/api/user/createChildaccount`, { enrollment: parentDetails?.enrollmentId, userId: parseLoginRes?._id, serviceProvider: parseLoginRes?.company })
                                .then((res) => {
                                    setInitiationData(res?.data?.data);
                                    setInitiateActive(false);
                                    setPersonalInfoActive(true);
                                })
                                .catch((err) => {});
                        } else {
                            setBillingModelError(true);
                        }
                    }}
                    loading={initiationLoading}
                    disabled={initiationLoading}
                    label="Next"
                    className="initiationbutton"
                />
            </div>
        </div>
    );
}
