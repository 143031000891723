import React, { useEffect, useState, useRef } from "react";
import { ToastContainer } from "react-toastify"; // Import ToastContainer and toast
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DataTable } from "primereact/datatable";
import { useFormik } from "formik";
import "./css/billing_configuration.css";
import * as Yup from "yup";
import { api } from "../../../../../utility/AxiosInceptor";
import { Toast } from "primereact/toast";
import ListAllBilling from "./components/billingmodel_configurations/billing_model_configurations";
import Axios from "axios";
import { Dropdown } from "primereact/dropdown";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import "primeicons/primeicons.css";
import { MultiSelect } from "primereact/multiselect";
import { Button } from "primereact/button";
import UpdateBill from "./components/update_bill";
import { Dialog } from "primereact/dialog";

import { Card } from "primereact/card";
import CommonLoaderBlue from "../../billingModel/billingmodel_configurations/Loaders/components/Loader/LoaderBlue";
import { PlanEditIcon } from "../../../../../utility";
import { OverlayPanel } from "primereact/overlaypanel";
const BASE_URL = process.env.REACT_APP_BASE_URL;
const BillingConfiguration = () => {
    const [rowData, setRowData] = useState();
    const [configBillingModel, setConfigBillingModel] = useState(false);
    const [delteBillingLoader, setDeleteBillingLoader] = useState(false);
    const [isDiscountLoading, setIsDiscountLoading] = useState(false);
    const [isFeatureLoading, setIsFeatureLoading] = useState(false);
    const [currentBillingId, setCurrentBillingId] = useState("");
    const [refresh, setRefresh] = useState(false);
    const toast2 = useRef(null);
    const [loader, setLoader] = useState(true);
    const [visible, setVisible] = useState(false);
    const [newDiscount, setNewDiscount] = useState(false);
    const [currentRow, setCurrentRow] = useState();
    const [updatePlanVisibility, setUpdatePlanVisibility] = useState(false);
    const [newFeature, setNewFeature] = useState(false);
    const [allPlan, setAllPlan] = useState([]);
    const [allDiscount, setAllDiscount] = useState([]);
    const [allFeature, setAllFeature] = useState([]);
    const [refreshOnBillingConfig, setRefreshOnBillingConfig] = useState(false);
    const [configData, setconfigData] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const op = useRef(null);
    const formRef = useRef(null);
    const scrollToForm = () => {
        if (formRef.current) {
            formRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
        }
    };
    const validationSchema = Yup.object().shape({
        monthlyCharge: Yup.array().required("This is Required").min(1, "Field must contain at least one value").of(Yup.string()),
        paymentMethod: Yup.array().required("This is Required").min(1, "Field must contain at least one value").of(Yup.string()),

        billingmodel: Yup.string().required("This is Required"),

        inventoryType: Yup.string().required("This is Required"),

        oneTimeCharge: Yup.string().required("This is Required"),

        dueDate: Yup.string().required("This is Required"),

        BillCreationDate: Yup.string().required("This is Required"),

        latefeeCharge: Yup.string().required("This is Required"),

        applyLateFee: Yup.string().required("This is Required"),
        makeModel: Yup.string().required("This is Required"),
        subsequentBillCreateDate: Yup.string().required("This is Required"),
    });
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            billingmodel: "",
            inventoryType: "",
            oneTimeCharge: "",
            monthlyCharge: [],
            dueDate: "",
            makeModel: "",
            paymentMethod: [],
            selectdiscount: [],
            discountname: "",
            amount: "",
            BillCreationDate: "",
            additionalFeature: [],
            featureName: "",
            featureAmount: "",
            latefeeCharge: "",
            applyLateFee: "",
            subsequentBillCreateDate: "",
        },
        onSubmit: async (values, actions) => {
            setIsLoading(true);
            const dataToSend = {
                ServiceProvider: parseLoginRes?.company,
                billingmodel: formik.values.billingmodel,
                inventoryType: formik.values.inventoryType,
                oneTimeCharge: formik.values.oneTimeCharge,
                monthlyCharge: formik.values.monthlyCharge,
                dueDate: formik.values.dueDate,
                paymentMethod: formik.values.paymentMethod,
                selectdiscount: formik.values.selectdiscount,
                BillCreationDate: formik.values.BillCreationDate,
                additionalFeature: formik.values.additionalFeature,
                latefeeCharge: formik.values.latefeeCharge,
                applyLateFee: formik.values.applyLateFee,
                applyToCustomer: formik.values.applyToCustomer,
                make: formik.values.makeModel.substring(0, formik.values.makeModel.indexOf("-") - 1),
                devicemodel: formik.values.makeModel.substring(formik.values.makeModel.lastIndexOf("-") + 2),
                subsequentBillCreateDate: formik.values.subsequentBillCreateDate,
            };

            try {
                const response = await api.post(`/api/web/billing/billconfig`, dataToSend);
                if (response?.status === 200 || response?.status === 201) {
                    toast.success("Bill Configured Successfully");
                    getConfigData();
                    actions.resetForm();
                    setIsLoading(false);
                }
            } catch (error) {
                toast.error(error?.response?.data?.msg);
                setIsLoading(false);
            }
        },
    });

    const loginRes = localStorage.getItem("userData");
    const parseLoginRes = JSON.parse(loginRes);
    const [optionsForBillingmodel, setOptionsForBillingModel] = useState([]);
    const [optionsForInventoryType, setOptionForInventoryType] = useState([]);
    const [onChangeBillingModel, setOnChangeBillingModel] = useState(false);

    const optionsForPayment = [
        // { label: "Cash", value: "Cash" },
        // { label: "Credit Card", value: "Credit Card" },
        // { label: "Credit Card (Authorize)", value: "Credit Card Authorize" },
        { label: "Credit Card", value: "Credit Card Stripe" },
        //{ label: "Money Order", value: "Money Order" },
        // { label: "E-Check", value: "E-Check" },
        //{ label: "E-Check (Authorize)", value: "E-Check Authorize" },
        // { label: "E-Check (Stripe)", value: "E-Check Stripe" },
        { label: "Skip Payment", value: "Skip Payment" },
    ];
    const optionsForCreation = [
        { label: "On Activation", value: "On Activation" },
        { label: "After QA Approval ", value: "On QA Approval" },
    ];

    const getDiscount = async () => {
        try {
            const res = await api.get(`/api/web/discount/getalldiscounts`);
            setAllDiscount(res?.data?.data || []);
        } catch (error) {
            toast.error(error?.response?.data?.msg);
        }
    };
    const getFeature = async () => {
        try {
            const res = await api.get(`/api/web/feature/getallfeatures`);
            setAllFeature(res?.data?.data || []);
        } catch (error) {
            toast.error(error?.response?.data?.msg);
        }
    };
    const getConfigData = async () => {
        try {
            const res = await api.get(`/api/web/billing/getall`);
            setconfigData(res?.data?.data || []);
            setLoader(false);
        } catch (error) {
            setLoader(false);
            toast.error(error?.response?.data?.msg);
            setLoader(false);
        }
    };
    const getBillingModelList = async () => {
        try {
            const res = await api.get(`/api/billingModel/all?serviceProvider=${parseLoginRes?.company}`);

            setOptionsForBillingModel(res?.data?.data || []);
        } catch (error) {
            toast.error(error?.response?.data?.msg);
        }
    };
    const getInventoryList = async () => {
        if (formik.values.billingmodel !== "") {
            try {
                const res = await api.get(`/api/billingModel/getInventoryByBillingModel?billingModel=${currentBillingId}`);
                let obj = [];
                let data = res?.data?.data;
                data.forEach((item) => {
                    let obj2 = {};
                    obj2.inventoryType = item;
                    obj.push(obj2);
                });
                setOptionForInventoryType(obj);
            } catch (error) {
                toast.error(error?.response?.data?.msg);
            }
        }
    };

    useEffect(() => {
        getDiscount();
        getConfigData();
        getFeature();
    }, [refresh]);
    useEffect(() => {
        getBillingModelList();
    }, [refreshOnBillingConfig]);
    useEffect(() => {
        getInventoryList();
    }, [onChangeBillingModel]);
    useEffect(() => {
        if (formik.values.inventoryType !== "" && formik.values.makeModel !== "") {
            api.get(
                `${BASE_URL}/api/web/plan/getByInventoryType?inventoryType=${formik.values.inventoryType}&make=${formik.values.makeModel?.substring(0, formik.values.makeModel.indexOf("-") - 1)}&model=${formik.values.makeModel.substring(formik.values.makeModel.lastIndexOf("-") + 2)}&serviceProvider=${parseLoginRes.company}&billingModel=${formik.values.billingmodel}`,
            ).then((res) => {
                formik.setFieldValue("monthlyCharge", []);
                setAllPlan(res?.data?.data || []);
            });
        }
    }, [formik.values.inventoryType, formik.values.makeModel]);

    const addDiscount = async () => {
        setIsDiscountLoading(true);
        const dataToSend = {
            ServiceProvider: parseLoginRes?.company,
            discountname: formik.values.discountname,
            amount: formik.values.amount,
        };

        try {
            const response = await api.post(`/api/web/discount/adddiscount`, dataToSend);
            if (response?.status == 200 || response?.status == 201) {
                formik.setFieldValue("discountname", "");
                formik.setFieldValue("amount", "");
                setNewDiscount(false);
                getDiscount();
                toast.success("Discount added successfully");
                setIsDiscountLoading(false);
            }
        } catch (error) {
            toast.error(error?.response?.data?.msg);
            setIsDiscountLoading(false);
        }
    };

    const addFeature = async () => {
        setIsFeatureLoading(true);
        const dataToSend = {
            ServiceProvider: parseLoginRes?.company,
            featureName: formik.values.featureName,
            featureAmount: formik.values.featureAmount,
        };

        try {
            const response = await api.post(`/api/web/feature/addfeature`, dataToSend);
            if (response?.status == 200 || response?.status == 201) {
                setIsFeatureLoading(false);
                formik.setFieldValue("featureName", "");
                formik.setFieldValue("featureAmount", "");
                setNewFeature(false); // Fix the typo here
                getFeature();
                toast.success("Feature added successfully");
            }
        } catch (error) {
            setIsFeatureLoading(false);
            toast.error(error?.response?.data?.msg);
        }
    };

    // {
    //     useEffect(() => {
    //         const getPlan = async () => {
    //             try {
    //                 const res = await api.get(`/api/web/plan/all?serviceProvider=${parseLoginRes?.company}`);
    //                 setAllPlan(res?.data?.data || []);
    //             } catch (error) {
    //                 toast.error(error?.response?.data?.msg);
    //             }
    //         };
    //         getPlan();
    //     }, []);
    // }

    useEffect(() => {
        if (formik.values.inventoryType !== "" && formik.values.billingmodel !== "") {
            api.get(`/api/inventoryType/getSellingPrice?inventoryType=${formik.values.inventoryType}&billingModel=${currentBillingId}`)
                .then((res) => {
                    formik.setFieldValue("oneTimeCharge", res?.data?.OneTimeCharges);
                })
                .catch((err) => {});
        }
    }, [formik.values.inventoryType, formik.values.billingmodel]);
    const [makeModelOptions, setMakeModelOptions] = useState();
    useEffect(() => {
        if (formik.values.inventoryType !== "" && currentBillingId !== "") {
            api.get(`/api/inventoryType/getInventoryTypeData?inventoryType=${formik.values.inventoryType}&billingModel=${currentBillingId}&serviceProvider=${parseLoginRes?.company}`)
                .then((res) => {
                    let Data = res?.data?.data;
                    let dataNew = [];
                    for (let i = 0; i < Data.length; i++) {
                        let data = Data[i];
                        data.makemodel = `${data.make} - ${data.model}`;
                        dataNew.push(data);
                    }
                    setMakeModelOptions(dataNew);
                })
                .catch((err) => {});
        }
    }, [currentBillingId, formik.values.inventoryType]);
    return (
        <div className="billingmain">
            <Dialog
                header="Billing Model Configurations"
                visible={configBillingModel}
                className="pt-0"
                style={{ width: "80vw" }}
                onHide={() => {
                    setConfigBillingModel(false);
                    setRefreshOnBillingConfig((prev) => !prev);
                }}
            >
                <ListAllBilling />
            </Dialog>

            <ToastContainer />
            <form className="billingform " onSubmit={formik.handleSubmit} ref={formRef}>
                {updatePlanVisibility ? (
                    <UpdateBill rowData={currentRow} setRefresh={setRefresh} optionsForInventoryType={optionsForInventoryType} setUpdatePlanVisibility={setUpdatePlanVisibility} />
                ) : (
                    <div className="forminner">
                        <p>Configuration</p>
                        <div className="addbillingfield">
                            <div className="flex justify-content-between" style={{ width: "33.2rem" }}>
                                <label className="mt-0">Billing Model </label>
                                {formik.touched.billingmodel && formik.errors.billingmodel ? <p className="p-error">{formik.errors.billingmodel}</p> : null}
                            </div>
                            <Dropdown
                                className="addbillingfieldinput"
                                id="billingmodel"
                                options={optionsForBillingmodel}
                                value={formik.values.billingmodel}
                                placeholder="Select Billing Model"
                                optionLabel="billingModel"
                                optionValue="billingModel"
                                onChange={(e) => {
                                    formik.setFieldValue("billingmodel", e.value);
                                    let id;
                                    optionsForBillingmodel.map((item) => {
                                        if (item.billingModel === e.value) {
                                            id = item._id;
                                        }
                                    });
                                    formik.handleChange(e);
                                    formik.setFieldValue("inventoryType", "");
                                    setCurrentBillingId(id);
                                    setOnChangeBillingModel((prev) => !prev);
                                }}
                            />
                        </div>
                        <label style={{ fontSize: "1.2rem", fontWeight: "600", color: "#72728F" }} className="addbillingfield">
                            Add Inventory Details
                        </label>
                        <div>
                            <div className="mainbillingconfig flex w-full flex-wrap flex-row justify-content-left  ">
                                <Toast ref={toast2} />
                                <div className="flex">
                                    <div className="addbillingfield ">
                                        <div className="flex justify-content-between">
                                            <label className="">Inventory Type</label>
                                            {formik.touched.inventoryType && formik.errors.inventoryType ? <p className="p-error">{formik.errors.inventoryType}</p> : null}
                                        </div>
                                        <Dropdown
                                            className="addbillingfieldinput"
                                            id="inventoryType"
                                            placeholder="Select Inventory Type"
                                            options={optionsForInventoryType}
                                            value={formik.values.inventoryType}
                                            optionLabel="inventoryType"
                                            optionValue="inventoryType"
                                            onChange={(e) => {
                                                formik.setFieldValue("inventoryType", e.value);
                                                formik.handleChange(e);
                                            }}
                                            onBlur={formik.handleBlur}
                                        />
                                    </div>
                                    <div className="addbillingfield" style={{ marginLeft: "1.2rem" }}>
                                        <div className="flex justify-content-between">
                                            <label>Make And Model</label>
                                            {formik.touched.makeModel && formik.errors.makeModel ? <p className="p-error">{formik.errors.makeModel}</p> : null}
                                        </div>
                                        <Dropdown optionLabel="makemodel" optionValue="makemodel" value={formik.values.makeModel} name="makeModel" options={makeModelOptions} onChange={formik.handleChange} placeholder="Select an option" className="addbillingfieldinput " />
                                    </div>
                                    <div className="addbillingfield" style={{ marginLeft: "1.2rem" }}>
                                        <div className="flex justify-content-between">
                                            <label className="">One Time Charges</label>
                                            {formik.touched.oneTimeCharge && formik.errors.oneTimeCharge ? <p className="p-error">{formik.errors.oneTimeCharge}</p> : null}
                                        </div>
                                        <InputText disabled id="oneTimeCharge" className="addbillingfieldinput" placeholder="Enter One Time Charges" value={formik.values.oneTimeCharge} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                    </div>
                                </div>

                                <div className="flex">
                                    <div className="addbillingfield">
                                        <div className="flex justify-content-between">
                                            <label>Monthly Charges</label>
                                            {formik.touched.monthlyCharge && formik.errors.monthlyCharge ? <p className="p-error">{formik.errors.monthlyCharge}</p> : null}
                                        </div>
                                        <MultiSelect
                                            id="monthlyCharge"
                                            display="chip"
                                            options={allPlan}
                                            placeholder="Monthly Charges"
                                            value={formik.values.monthlyCharge}
                                            onChange={(e) => formik.setFieldValue("monthlyCharge", e.value)}
                                            optionLabel={(option) => `${option.name} - (${option.planId})`}
                                            optionValue="_id"
                                            className="addbillingfieldinput"
                                        />
                                    </div>
                                    <div className="addbillingfield" style={{ marginLeft: "1.2rem" }}>
                                        <div className="flex justify-content-between">
                                            <label>First Bill Create Date</label>
                                            {formik.touched.BillCreationDate && formik.errors.BillCreationDate ? <p className="p-error">{formik.errors.BillCreationDate}</p> : null}
                                        </div>
                                        <Dropdown
                                            className="addbillingfieldinput"
                                            id="BillCreationDate"
                                            placeholder="First Bill Create Date"
                                            options={optionsForCreation}
                                            value={formik.values.BillCreationDate}
                                            onChange={(e) => {
                                                formik.setFieldValue("BillCreationDate", e.value);
                                                formik.handleChange(e);
                                            }}
                                            onBlur={formik.handleBlur}
                                        />
                                    </div>
                                    <div className="addbillingfield" style={{ marginLeft: "1.2rem" }}>
                                        <div className="flex justify-content-between">
                                            <label>Subsequent Bill Create Date </label>
                                            {formik.touched.subsequentBillCreateDate && formik.errors.subsequentBillCreateDate ? <p className="p-error">{formik.errors.subsequentBillCreateDate}</p> : null}
                                        </div>
                                        <InputText id="subsequentBillCreateDate" className="addbillingfieldinput" placeholder="No of Days From First Bill Create Date" value={formik.values.subsequentBillCreateDate} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                    </div>
                                </div>
                                <div className="flex">
                                    <div className="addbillingfield">
                                        <div className="flex justify-content-between">
                                            <label>Due Date</label>
                                            {formik.touched.dueDate && formik.errors.dueDate ? <p className="p-error">{formik.errors.dueDate}</p> : null}
                                        </div>
                                        <InputText id="dueDate" placeholder="No of days From Bill Create Date" className="addbillingfieldinput" value={formik.values.dueDate} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                    </div>
                                    <div className="addbillingfield" style={{ marginLeft: "1.2rem" }}>
                                        <div className="flex justify-content-between">
                                            <label>Late Fee Charge</label>
                                            {formik.touched.latefeeCharge && formik.errors.latefeeCharge ? <p className="p-error">{formik.errors.latefeeCharge}</p> : null}
                                        </div>
                                        <InputText id="latefeeCharge" placeholder="Late Fee Charge" value={formik.values.latefeeCharge} className="addbillingfieldinput" onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                    </div>
                                    <div className="addbillingfield" style={{ marginLeft: "1.2rem" }}>
                                        <div className="flex justify-content-between">
                                            <label>Apply Late Fee </label>
                                            {formik.touched.applyLateFee && formik.errors.applyLateFee ? <p className="p-error">{formik.errors.applyLateFee}</p> : null}
                                        </div>
                                        <InputText id="applyLateFee" placeholder="No of Days from Due Date" className="addbillingfieldinput" value={formik.values.applyLateFee} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                    </div>
                                </div>

                                <div className="flex">
                                    <div className="addbillingfield">
                                        <div className="flex justify-content-between">
                                            <label>Payment Method</label>
                                            {formik.touched.paymentMethod && formik.errors.paymentMethod ? <p className="p-error">{formik.errors.paymentMethod}</p> : null}
                                        </div>
                                        <MultiSelect
                                            className="addbillingfieldinput"
                                            id="paymentMethod"
                                            placeholder="Select Payment Method"
                                            options={optionsForPayment}
                                            display="chip"
                                            optionLabel="label"
                                            optionValue="label"
                                            value={formik.values.paymentMethod}
                                            onChange={(e) => {
                                                formik.setFieldValue("paymentMethod", e.value);
                                                formik.handleChange(e);
                                            }}
                                        />
                                    </div>
                                    <div className="addbillingfield" style={{ marginLeft: "1.2rem" }}>
                                        <label>Select Discount</label>
                                        <MultiSelect
                                            id="selectdiscount"
                                            display="chip"
                                            placeholder="Select Discounts"
                                            options={allDiscount}
                                            value={formik.values.selectdiscount}
                                            onChange={(e) => formik.setFieldValue("selectdiscount", e.value)}
                                            optionLabel={(option) => `${option.discountname} - (${option.amount})`}
                                            optionValue="_id"
                                            className="addbillingfieldinput"
                                        />
                                    </div>
                                    <div className="addbillingfield" style={{ marginLeft: "1.2rem" }}>
                                        <label>Additional Feature</label>
                                        <MultiSelect
                                            id="additionalFeature"
                                            display="chip"
                                            placeholder="Select Additional Features"
                                            options={allFeature}
                                            value={formik.values.additionalFeature}
                                            onChange={(e) => formik.setFieldValue("additionalFeature", e.value)}
                                            optionLabel={(option) => `${option.featureName} - (${option.featureAmount})`}
                                            optionValue="_id"
                                            className="addbillingfieldinput"
                                        />
                                    </div>
                                </div>
                                <div className="flex" style={{ marginTop: "1.8rem" }}>
                                    <a
                                        className="linkfordiscount"
                                        onClick={() => {
                                            if (newDiscount || newFeature) {
                                            } else {
                                                setNewDiscount(true);
                                                setNewFeature(false);
                                            }
                                        }}
                                        style={{ opacity: newDiscount || newFeature ? "0.5" : "1" }}
                                    >
                                        Add Discount
                                    </a>
                                    <a
                                        className="linkfordiscount"
                                        onClick={() => {
                                            if (newDiscount || newFeature) {
                                            } else {
                                                setNewDiscount(false);
                                                setNewFeature(true);
                                            }
                                        }}
                                        style={{ opacity: newDiscount || newFeature ? "0.5" : "1", marginLeft: "2.4rem" }}
                                    >
                                        Add Feature
                                    </a>
                                </div>
                            </div>
                            {newDiscount ? (
                                <div className="flex" style={{ marginTop: "2rem" }}>
                                    <div className="addbillingfield">
                                        <label>Discount Name</label>
                                        <InputText id="discountname" className="addbillingfieldinput" value={formik.values.discountname} onChange={formik.handleChange} />
                                    </div>
                                    <div className="addbillingfield" style={{ marginLeft: "1.2rem" }}>
                                        <label>Amount </label>
                                        <InputText id="amount" className="addbillingfieldinput" value={formik.values.amount} onChange={formik.handleChange} />
                                    </div>
                                    <div style={{ marginTop: "1.2rem", marginLeft: "1.2rem", width: "33.2rem" }} className="flex align-items-end justify-content-end">
                                        <Button
                                            type="button"
                                            label="Cancel"
                                            className="backbtn"
                                            onClick={() => {
                                                setNewFeature(false);
                                                setNewDiscount(false);
                                            }}
                                            style={{ width: "13.2rem", height: "4.2rem" }}
                                        />
                                        <Button type="button" label="Add Discount" onClick={addDiscount} className="nextbtn" style={{ width: "13.2rem", height: "4.2rem" }} />
                                    </div>
                                </div>
                            ) : (
                                ""
                            )}

                            {newFeature ? (
                                <div className="flex">
                                    <div className="addbillingfield">
                                        <label>Feature Name</label>
                                        <InputText id="featureName" className="addbillingfieldinput" value={formik.values.featureName} onChange={formik.handleChange} />
                                    </div>
                                    <div className="addbillingfield" style={{ marginLeft: "1.2rem" }}>
                                        <label> Amount </label>
                                        <InputText id="featureAmount" className="addbillingfieldinput" value={formik.values.featureAmount} onChange={formik.handleChange} />
                                    </div>
                                    <div style={{ marginTop: "1.2rem", marginLeft: "1.2rem", width: "33.2rem" }} className="flex align-items-end justify-content-end">
                                        <Button
                                            type="button"
                                            label="Cancel"
                                            className="backbtn"
                                            onClick={() => {
                                                setNewFeature(false);
                                                setNewDiscount(false);
                                            }}
                                            style={{ width: "13.2rem", height: "4.2rem" }}
                                        />
                                        <Button type="button" loading={isFeatureLoading} disabled={isFeatureLoading} label="Add Feature" onClick={addFeature} className="nextbtn" style={{ width: "13.2rem", height: "4.2rem" }} />
                                    </div>
                                </div>
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                )}
                <div className="flex justify-content-end " style={{ width: "102rem", marginTop: "3.2rem" }}>
                    <Button
                        className="backbtn"
                        style={{ width: "16rem", height: "5rem" }}
                        label="Cancel"
                        type="button"
                        onClick={() => {
                            setUpdatePlanVisibility(false);
                        }}
                    />
                    <Button className="nextbtn" style={{ width: "16rem", height: "5rem" }} loading={isLoading} disabled={isLoading} label="Submit" type="Submit" />
                </div>
            </form>

            <div className="billingconfigtable">
                <div className="flex flex-column w-full justify-content-start   " style={{ height: "6.3rem" }}>
                    <h1 className="">Billing Configurations</h1>
                    <p style={{ marginTop: "1.2rem" }}>View and manage all billing configurations in one place, ensuring streamlined processes and accurate setup for your business needs.</p>
                </div>
                {/* <Dialog
                    visible={updatePlanVisibility}
                    style={{ width: "80vw" }}
                    header="Update Billing Configuration "
                    onHide={() => {
                        setUpdatePlanVisibility(false);
                    }}
                >
                    <UpdateBill rowData={currentRow} setRefresh={setRefresh} optionsForInventoryType={optionsForInventoryType} setUpdatePlanVisibility={setUpdatePlanVisibility} />
                </Dialog> */}
                <DataTable
                    emptyMessage={
                        loader ? (
                            <div className="flex flex-wrap flex-row justify-content-center mt-4 mb-4 ">
                                <CommonLoaderBlue />
                            </div>
                        ) : (
                            "Billing Configurations Not Found"
                        )
                    }
                    value={configData}
                    showGridlines
                    resizableColumns
                    columnResizeMode="fit"
                    style={{ marginTop: "2.4rem" }}
                    className="billingdatatable"
                >
                    <Column
                        header="Billing Model"
                        field="billingmodel"
                        body={(rowData) => {
                            return <p>{rowData?.billingmodel}</p>;
                        }}
                        style={{
                            width: "100px",
                            minWidth: "100px",
                            whiteSpace: "normal",
                            wordWrap: "break-word",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                        }}
                    />
                    <Column
                        header="Inventory Type"
                        field="inventoryType"
                        body={(rowData) => {
                            return <p>{rowData?.inventoryType}</p>;
                        }}
                        style={{
                            width: "110px",
                            minWidth: "110px",
                            whiteSpace: "normal",
                            wordWrap: "break-word",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                        }}
                    />
                    <Column
                        header="One Time Charges"
                        field="oneTimeCharge"
                        body={(rowData) => {
                            return rowData?.oneTimeCharge;
                        }}
                        style={{
                            width: "100px",
                            minWidth: "100px",
                            whiteSpace: "normal",
                            wordWrap: "break-word",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                        }}
                    />

                    <Column
                        header="Plan Name"
                        body={(rowData) => {
                            const text = rowData?.monthlyCharge?.map((mc) => mc.name).join(", ");
                            const maxWords = 3;
                            const words = text.split(" ");

                            return words.length > maxWords ? words.slice(0, maxWords).join(" ") + "..." : text;
                        }}
                        style={{
                            width: "180px",
                            minWidth: "180px",
                            whiteSpace: "normal",
                            wordWrap: "break-word",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                        }}
                    />
                    <Column
                        header="Create At"
                        field="BillCreationDate"
                        style={{
                            width: "120px",
                            minWidth: "120px",
                            whiteSpace: "normal",
                            wordWrap: "break-word",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                        }}
                    />
                    <Column
                        header="Due Date"
                        field="Due Date"
                        body={(rowData) => `${rowData?.dueDate} Days from Bill Create Date`}
                        style={{
                            width: "120px",
                            minWidth: "120px",
                            whiteSpace: "normal",
                            wordWrap: "break-word",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                        }}
                    />
                    <Column
                        header="Billing Days"
                        field="subsequentBillCreateDate"
                        style={{
                            width: "80px",
                            minWidth: "80px",
                            whiteSpace: "normal",
                            wordWrap: "break-word",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                        }}
                    />
                    <Column
                        header="Late Fee   "
                        field="Late Fee"
                        body={(rowData) => `$${rowData.latefeeCharge}`}
                        style={{
                            width: "70x",
                            minWidth: "70px",
                            whiteSpace: "normal",
                            wordWrap: "break-word",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                        }}
                    />
                    <Column
                        header="Late Fee Days"
                        field="Apply Late Fee"
                        body={(rowData) => `After ${rowData.applyLateFee} Days from Due Date`}
                        style={{
                            width: "140px",
                            minWidth: "140px",
                            whiteSpace: "normal",
                            wordWrap: "break-word",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                        }}
                    />

                    <Column
                        header="Feature"
                        body={(rowData) => rowData?.additionalFeature?.map((sd) => `${sd.featureName} - $${sd.featureAmount}`).join(", ")}
                        style={{
                            width: "180px",
                            minWidth: "180px",
                            whiteSpace: "normal",
                            wordWrap: "break-word",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                        }}
                    />
                    <Column
                        header="Discount"
                        body={(rowData) => rowData?.selectdiscount?.map((sd) => `${sd.discountname} - $${sd.amount}`).join(", ")}
                        style={{
                            width: "200px",
                            minWidth: "200px",
                            whiteSpace: "normal",
                            wordWrap: "break-word",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                        }}
                    />
                    <Column
                        header="Payment Method"
                        field="paymentMethod"
                        body={(rowData) => rowData.paymentMethod.join(", ")}
                        style={{
                            width: "120px",
                            minWidth: "120px",
                            whiteSpace: "normal",
                            wordWrap: "break-word",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                        }}
                    />
                    <Column
                        header="Actions"
                        body={(rowData) => {
                            return (
                                <>
                                    <div
                                        className="actionbtnmain"
                                        onClick={(e) => {
                                            op.current.toggle(e);
                                            setCurrentRow(rowData);
                                        }}
                                        style={{ paddingLeft: "2.7rem" }}
                                    >
                                        <PlanEditIcon />
                                    </div>
                                    <OverlayPanel ref={op} style={{ width: "10.9rem", height: "8rem" }} className="flex align-items-center">
                                        <div className="flex flex-column w-full overlay">
                                            <div
                                                className="flex justify-content-start align-items-center prospectoverlay"
                                                style={{ height: "32px" }}
                                                onClick={() => {
                                                    setUpdatePlanVisibility(true);
                                                    scrollToForm();
                                                }}
                                            >
                                                <label style={{ fontSize: "1.2rem", fontWeight: "500" }}>Update</label>
                                            </div>
                                            <div
                                                className="flex justify-content-start align-items-center prospectoverlay"
                                                style={{ height: "32px" }}
                                                onClick={() => {
                                                    setRowData(rowData);
                                                    setVisible(true);
                                                }}
                                            >
                                                <label style={{ fontSize: "1.2rem", fontWeight: "500" }}>Delete</label>
                                            </div>
                                        </div>
                                    </OverlayPanel>
                                </>
                            );
                        }}
                        style={{
                            width: "38px",
                            minWidth: "38px",
                            whiteSpace: "normal",
                            wordWrap: "break-word",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                        }}
                    ></Column>
                </DataTable>
            </div>
            <Dialog
                headerClassName="dialogheader"
                closable={false}
                visible={visible}
                onHide={() => {
                    if (!visible) return;
                    setVisible(false);
                }}
                className="delete-dialog"
            >
                <div className="datadelete">
                    <h1>Confirm Deletion</h1>
                    <p> Are you sure you want to delete this plan? This action cannot be undone. Proceed with caution.</p>{" "}
                </div>
                <div></div>
                <div className="deletedialogbtn">
                    <Button className="backbtn" label="Cancel" onClick={() => setVisible(false)} style={{ width: "16rem", height: "5rem" }} />
                    <Button
                        label="Delete"
                        className="nextbtn"
                        loading={delteBillingLoader}
                        disabled={delteBillingLoader}
                        onClick={() => {
                            setDeleteBillingLoader(true);
                            api.delete(`/api/web/billing/deletebillconfig?billId=${rowData._id}`)
                                .then(() => {
                                    toast.success("Plan Deleted Successfully ");
                                    setRefresh((prev) => !prev);
                                    setTimeout(() => {
                                        setVisible(false);
                                    }, 500);
                                })
                                .catch((err) => {
                                    toast.error("Plan Deletion Failed");
                                    setDeleteBillingLoader(false);
                                });
                        }}
                        style={{ width: "16rem", height: "5rem" }}
                    />
                </div>
            </Dialog>
        </div>
    );
};

export default BillingConfiguration;
