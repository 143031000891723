import React, { useEffect, useState, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { api } from "../../../../../../../utility/AxiosInceptor";
import { Column } from "primereact/column";
import { DateTime } from "luxon";
import Axios from "axios";
import { ProgressSpinner } from "primereact/progressspinner";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { FilterMatchMode } from "primereact/api";
import "./completedenrollment.css";
import { OverlayPanel } from "primereact/overlaypanel";
import CommonLoaderBlue from "../../../../billingModel/billingmodel_configurations/Loaders/components/Loader/LoaderBlue";
import { Backbtn, Editbutton, Export, ExportHover, Filter, Import, ImportHover, Status, Calendar, Sortby, DownloadIcon, Nextbtn, NextIcon, PrevIcon, EmptyEnrollment } from "../../../../../../../utility";
import DateRangeCalendar from "../all_enrollments.js/Calendar/Calendar-DropDown";
import { Button } from "primereact/button";
const BASE_URL = process.env.REACT_APP_BASE_URL;
const Prepaid_Completed_Enrollments = () => {
    // State For Select Row
    const [selectedEnrollments, setSelectedEnrollments] = useState(null);
    const [rowClick, setRowClick] = useState(true);
    const [createDateToFilterValue, setCreatedDateToFilterValue] = useState("");
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        enrollment: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        createdFilter: { value: null, matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO },
        createdTo: { value: null, matchMode: FilterMatchMode.LESS_THAN_OR_EQUAL_TO },
    });
    const [enrollmentIdFilterValue, setEnrollmentIdFilterValue] = useState("");
    const [createDateFilterValue, setCreatedDateFilterValue] = useState("");
    const [allCompletedEnrollments, setAllCompletedEnrollments] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [globalFilterValue, setGlobalFilterValue] = useState("");
    const [hover, setHover] = useState(false);
    const [hoverLeave, setHoverLeave] = useState(false);
    const [hoverAfter, setHoverAfter] = useState(false);
    const [hoverLeaveAfter, setHoverLeaveAfter] = useState(false);

    const [customerSearchName, setCustomerSearchName] = useState("");
    const [totalSearchResult, setTotalSearchResult] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [enrollmentFrom, setEnrollmentFrom] = useState(1);
    const [enrollmentTo, setEnrollmentTo] = useState(15);
    const [completeListEnrollment, setCompleteListEnrollment] = useState([]);
    const [selectedNumberToShow, setSelectedNumberToShow] = useState(15);
    const [totalIncaseSearch, setTotalIncaseSearch] = useState(0);
    const [totalPage, setTotalPage] = useState(0);
    const [prospect, setProspect] = useState(false);
    const [cancelled, setCancelled] = useState(false);
    const [all, setAll] = useState(false);
    const [selectedRange, setSelectedRange] = useState([null, null]);
    const [shortSelection, setShortSelection] = useState();

    const userData = JSON.parse(localStorage.getItem("userData"));
    const [role, setRole] = useState(userData?.role?.role);
    const op = useRef(null);
    const date = useRef(null);
    const onGlobalFilterValueChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters["global"].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };
    const onNameDateEnrollmentIdValueFilter = (e, field) => {
        const value = e.target.value;
        let _filters = { ...filters };
        if (field === "enrollment") {
            _filters["enrollment"].value = value;
            setFilters(_filters);
            setEnrollmentIdFilterValue(value);
        } else if (field === "createdTo") {
            const parsedDate = DateTime.fromJSDate(new Date(e.value));
            let easternDateTime = parsedDate.setZone("America/New_York", { keepLocalTime: true });
            easternDateTime = easternDateTime.set({
                hour: 23,
                minute: 59,
                second: 0,
            });

            const formattedEasternTime = easternDateTime.toFormat("d LLL yyyy, h:mm a");
            const etDateObject = DateTime.fromFormat(formattedEasternTime, "d LLL yyyy, h:mm a", { zone: "America/New_York" });
            const value2 = etDateObject.toSeconds();
            setCreatedDateToFilterValue(e.value);
            _filters["createdTo"].value = value2;
            setFilters(_filters);
        } else {
            const parsedDate = DateTime.fromJSDate(new Date(e.value));
            const easternDateTime = parsedDate.setZone("America/New_York", { keepLocalTime: true });
            const formattedEasternTime = easternDateTime.toFormat("d LLL yyyy, h:mm a");
            const etDateObject = DateTime.fromFormat(formattedEasternTime, "d LLL yyyy, h:mm a", { zone: "America/New_York" });
            const value = etDateObject.toSeconds();

            setCreatedDateFilterValue(e.value);
            _filters["createdFilter"].value = value;
            setFilters(_filters);
        }
    };

    const navigate = useNavigate();

    const handleEnrollmentIdClick = (rowData) => {
        navigate("/customer-profile", { state: { selectedId: rowData._id } });
        localStorage.setItem("selectedId", JSON.stringify(rowData._id));
    };

    // Get user data from ls
    const loginRes = localStorage.getItem("userData");
    const parseLoginRes = JSON.parse(loginRes);
    // Get role name  from login response
    const roleName = parseLoginRes?.role?.role;
    const toCapital = roleName ? roleName.toUpperCase() : "DEFAULT_VALUE";

    const getAllCompletedEnrollments = async () => {
        setIsLoading(true);
        try {
            const res = await api.get(`/api/user/prePostCompletedEnrollmentsList?userId=${parseLoginRes?._id}&accountType=Prepaid`);
            if (res?.status === 200 || res?.status === 201) {
                const updatedData = res?.data?.data.map((item) => ({
                    ...item,
                    enrollment: item.isSelfEnrollment ? "Self Enrollments" : "Enrollment",
                    name: `${item?.firstName ? item?.firstName.toUpperCase() : ""} ${item?.lastName ? item?.lastName.toUpperCase() : ""}`,
                    createdDate: new Date(item.createdAt)
                        .toLocaleDateString("en-US", {
                            month: "2-digit",
                            day: "2-digit",
                            year: "numeric",
                        })
                        .replace(/\//g, "-"),
                    createdFilter: DateTime.fromFormat(item.createdAt, "d LLL yyyy, h:mm a", { zone: "America/New_York" }).toSeconds(),
                    createdTo: DateTime.fromFormat(item.createdAt, "d LLL yyyy, h:mm a", { zone: "America/New_York" }).toSeconds(),
                }));
                // Pagination logic
                setAllCompletedEnrollments(updatedData.slice(0, 15)); // Initially display first 15 records
                setCompleteListEnrollment(updatedData); // Full list for manual pagination
                setTotalPage(Math.ceil(updatedData.length / 15)); // Total number of pages

                setIsLoading(false);
            }
        } catch (error) {
            toast.error(error?.response?.data?.msg);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getAllCompletedEnrollments();
    }, []);

    const actionTemplate = (rowData) => {
        return (
            <div className="outeractionbutton">
                <div className="inneractionbutton">
                    <span className="flex justify-content-start align-items-center" style={{ gap: ".5rem" }}>
                        <span
                            className="-ml-2"
                            style={{
                                display: "inline-block",
                                width: "1.2rem",
                                height: "1.2rem",
                                borderRadius: "1.2rem",
                                backgroundColor: "#46b146",
                                boxShadow: ".2rem .2rem .3rem .0rem #aeaec040, .0rem .1rem .4rem .0rem #aeaec040",
                                marginRight: ".8rem", // Added for spacing between circle and text
                            }}
                        ></span>
                        <span
                            className="-ml-2"
                            style={{
                                fontSize: "1.0rem",
                                fontWeight: "500",
                                color: "#163c16",
                            }}
                        >
                            Enable
                        </span>
                    </span>
                </div>
            </div>
        );
    };
    return (
        <div className="prepaidcompleted ">
            <ToastContainer />
            <div className="flex w-full flex-column justify-content-start h-4" style={{ marginBottom: "1.5rem" }}>
                <h3 className="heading">Completed Enrollments</h3>
                <p className="para mt-2">View and manage all enrollments with Active, Suspended, or Disconnected statuses in one organized table.</p>{" "}
                <div className="flex flex-wrap justify-content-start align-items-center mt-2 w-full">
                    <div className="flex flex-wrap w-full justify-content-between align-items-center">
                        <div className="flex prosp">
                            <h2 className="prosp-heading">Active/ Cancelled</h2>
                        </div>
                        <div className="flex align-items-center" style={{ width: "50.5rem", gap: "1.6rem" }}>
                            <InputText
                                className="searchbar"
                                value={customerSearchName}
                                onChange={(e) => {
                                    setCustomerSearchName(e.target.value);
                                    setCurrentPage(1);

                                    if (e.target.value.length > 0) {
                                        let searchResult = completeListEnrollment.filter((item) => item.name.toLowerCase().includes(e.target.value.toLowerCase()));

                                        setAllCompletedEnrollments(searchResult);
                                        setTotalIncaseSearch(searchResult.length);
                                        setTotalSearchResult(searchResult);
                                        setEnrollmentFrom(1);
                                        setEnrollmentTo(searchResult.length > 15 ? 15 : searchResult.length);
                                    } else {
                                        setTotalSearchResult([]);
                                        setAllCompletedEnrollments(completeListEnrollment.slice(0, 15));
                                        setEnrollmentFrom(1);
                                        setEnrollmentTo(completeListEnrollment.length > 15 ? 15 : completeListEnrollment.length);
                                    }
                                }}
                                placeholder="ID, Name, Contact, Date"
                            />
                            <div>
                                <button className="selector" onClick={(e) => date.current.toggle(e)}>
                                    <Calendar />
                                </button>
                                <OverlayPanel ref={date}>
                                    <DateRangeCalendar setSelectedRange={setSelectedRange} setShortSelection={setShortSelection} shortSelection={shortSelection} selectedRange={selectedRange} />
                                </OverlayPanel>
                            </div>
                            <div>
                                <button className="selector" onClick={(e) => op.current.toggle(e)}>
                                    <Status />
                                </button>
                                <OverlayPanel ref={op}>
                                    <div className="flex flex-column w-full overlay">
                                        <div className="flex justify-content-start align-items-center" style={{ gap: "10px", height: "36px" }}>
                                            <input id="prepaid" name="prepaid" className="check" type="checkbox" onChange={(e) => setProspect(e.value)} checked={prospect} />
                                            <label>Prospect</label>
                                        </div>
                                        <div className="flex justify-content-start align-items-center" style={{ gap: "10px", height: "36px" }}>
                                            <input id="postpaid" name="postpaid" className="check" type="checkbox" onChange={(e) => setCancelled(e.value)} checked={cancelled} />
                                            <label>Cancelled</label>
                                        </div>
                                        <div className="flex justify-content-start align-items-center" style={{ gap: "10px", height: "36px" }}>
                                            <input id="acp" name="acp" className="check" type="checkbox" onChange={(e) => setAll(e.value)} checked={all} />
                                            <label>All</label>
                                        </div>
                                    </div>
                                </OverlayPanel>
                            </div>
                            <button className="export flex justify-content-evenly align-items-center" style={{ opacity: role !== "Admin" ? "0.5" : "1" }}>
                                <p className="flex justify-content-between">
                                    Export
                                    <span className="ml-2">
                                        <DownloadIcon />
                                    </span>
                                </p>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {allCompletedEnrollments?.length === 0 && !isLoading ? (
                <div className="flex w-full  justify-content-center " style={{ marginTop: "5rem" }}>
                    <div className="emptystatediv">
                        <div className="emptyicon">
                            <span>
                                <EmptyEnrollment />
                            </span>
                        </div>
                        <div className="emptystatedata">
                            <h1>No Enrollments in the Queue!</h1>
                            <p>There are currently no active, suspended, or disconnected enrollments to display. Start new enrollments to fill your queue!</p>
                            <div className="flex w-full justify-content-center">
                                <Button className="emptystatebtn" label="Start Enrollment" onClick={() => navigate("/prepaid-newenrollment")} />
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div>
                    <DataTable
                        selectionMode={rowClick ? null : "checkbox"}
                        selection={selectedEnrollments}
                        onSelectionChange={role !== "Admin" ? null : (e) => setSelectedEnrollments(e.value)}
                        value={allCompletedEnrollments}
                        filters={filters}
                        globalFilterFields={["enrollmentId", "name"]}
                        stripedRows
                        resizableColumns
                        size="small"
                        onRowClick={(e) => handleEnrollmentIdClick(e.data)}
                        emptyMessage={
                            isLoading ? (
                                <div className="flex flex-wrap w-full justify-content-center  flex-row  mt-4 mb-4 ">
                                    <CommonLoaderBlue customSize={16} />
                                </div>
                            ) : (
                                "Enrollment Not Found"
                            )
                        }
                    >
                        <Column selectionMode="multiple" style={{ width: "1rem", pointerEvents: role !== "Admin" ? "none" : "auto", opacity: role !== "Admin" ? 0.5 : 1 }} />

                        <Column
                            header="Id"
                            field="enrollmentId"
                            // body={(rowData) => (
                            //     <button style={{ fontSize: "1.272rem", fontWeight: "500", color: "#5E79FF", border: "none", backgroundColor: "transparent", cursor: "pointer" }} onClick={() => handleEnrollmentIdClick(rowData)}>
                            //         {rowData.enrollmentId}
                            //     </button>
                            // )}
                        ></Column>
                        <Column header="Name" field="name"></Column>
                        <Column field="contact" header="Contact" />
                        <Column
                            header="Status"
                            body={(rowData) => (
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "start" }}>
                                    <span
                                        style={{
                                            color:
                                                rowData?.status === "prospect"
                                                    ? "#4A90E2"
                                                    : rowData?.status === "cancelled"
                                                      ? "#95A5A6"
                                                      : rowData?.status === "active"
                                                        ? "#27AE60"
                                                        : rowData?.status === "disconnected"
                                                          ? "#B3261E"
                                                          : rowData?.status === "inactive"
                                                            ? "#B3261E"
                                                            : rowData?.status === "suspended"
                                                              ? "#F39C12"
                                                              : "",
                                            fontSize: "1.4rem",
                                            textTransform: "capitalize",
                                        }}
                                    >
                                        {rowData?.status ? rowData.status.charAt(0).toUpperCase() + rowData.status.slice(1).toLowerCase() : ""}
                                    </span>
                                </div>
                            )}
                        />
                        {role === "Admin" && <Column field="createdBy.name" header="Created By" />}
                        <Column field="createdDate" header="Date" />
                        {/* <Column
                        header="Linked"
                        body={(rowData) => {
                            return <p className={rowData?.linkedAccount ? "yes" : "no"}>{rowData?.linkedAccount ? "Yes" : "No"}</p>;
                        }}
                    />
                    <Column header="Actions" body={actionTemplate}></Column> */}
                    </DataTable>
                    <div className="flex w-full justify-content-between align-items-center mt-5">
                        <div className="totalcount">
                            <h4>
                                {enrollmentTo} / {customerSearchName.length > 0 ? totalIncaseSearch : completeListEnrollment.length}
                            </h4>
                        </div>
                        <div className="flex">
                            <button
                                onClick={() => {
                                    const itemsPerPage = 15;
                                    const calculateTotalPages = (data) => Math.ceil(data.length / itemsPerPage);

                                    const handlePageNavigation = (data, setDataFn) => {
                                        const totalPages = calculateTotalPages(data);
                                        if (currentPage > 1) {
                                            let startIdx, endIdx;

                                            if (totalPages - 1 === currentPage) {
                                                startIdx = (currentPage - 2) * itemsPerPage;
                                                endIdx = startIdx + itemsPerPage;
                                            } else if (totalPages === currentPage) {
                                                startIdx = data.length - (data.length % itemsPerPage) - itemsPerPage;
                                                endIdx = startIdx + itemsPerPage;
                                            } else {
                                                startIdx = (currentPage - 2) * itemsPerPage;
                                                endIdx = startIdx + itemsPerPage;
                                            }

                                            setDataFn(data.slice(startIdx, endIdx));
                                            setCurrentPage((prev) => prev - 1);
                                            setEnrollmentFrom(startIdx + 1);
                                            setEnrollmentTo(endIdx);
                                        }
                                    };

                                    if (customerSearchName.length > 0) {
                                        handlePageNavigation(totalSearchResult, setAllCompletedEnrollments);
                                    } else {
                                        handlePageNavigation(completeListEnrollment, setAllCompletedEnrollments);
                                    }
                                }}
                                className="cursor-pointer backbutton"
                                style={{ backgroundColor: "#FFFFFF33" }}
                            >
                                <span>
                                    <PrevIcon /> &nbsp; Previous
                                </span>
                            </button>
                            <div className="flex align-items-center justify-content-center" style={{ width: "12rem", fontWeight: "500", fontSize: "1.4rem", color: "#72728F" }}>
                                &nbsp; {enrollmentFrom}-{enrollmentTo}&nbsp; of&nbsp; {customerSearchName.length > 0 ? totalIncaseSearch : completeListEnrollment.length}
                            </div>

                            <button
                                onClick={() => {
                                    if (customerSearchName.length > 0) {
                                        let totalPages = Math.ceil(totalSearchResult.length / 15);
                                        if (totalPages > currentPage) {
                                            let enrollmentTillCurrentPage = currentPage * 15;
                                            if (totalPages - 1 === currentPage) {
                                                setAllCompletedEnrollments(totalSearchResult.slice(enrollmentTillCurrentPage));

                                                setCurrentPage((prev) => prev + 1);
                                                setEnrollmentFrom(enrollmentTillCurrentPage + 1);
                                                setEnrollmentTo(totalSearchResult.length);
                                            } else {
                                                setAllCompletedEnrollments(totalSearchResult.slice(enrollmentTillCurrentPage, enrollmentTillCurrentPage + 15));
                                                setCurrentPage((prev) => prev + 1);
                                                setEnrollmentFrom(enrollmentTillCurrentPage + 1);
                                                setEnrollmentTo(enrollmentTillCurrentPage + 15);
                                            }
                                        }
                                    } else {
                                        let totalPages = Math.ceil(completeListEnrollment.length / 15);
                                        if (totalPages > currentPage) {
                                            let enrollmentTillCurrentPage = currentPage * 15;
                                            if (totalPages - 1 === currentPage) {
                                                setAllCompletedEnrollments(completeListEnrollment.slice(enrollmentTillCurrentPage));

                                                setCurrentPage((prev) => prev + 1);
                                                setEnrollmentFrom(enrollmentTillCurrentPage + 1);
                                                setEnrollmentTo(completeListEnrollment.length);
                                            } else {
                                                setAllCompletedEnrollments(completeListEnrollment.slice(enrollmentTillCurrentPage, enrollmentTillCurrentPage + 15));

                                                setCurrentPage((prev) => prev + 1);
                                                setEnrollmentFrom(enrollmentTillCurrentPage + 1);
                                                setEnrollmentTo(enrollmentTillCurrentPage + 15);
                                            }
                                        }
                                    }
                                }}
                                className="cursor-pointer nextbutton"
                                style={{ backgroundColor: "#FFFFFF33" }}
                            >
                                <span className="flex align-items-center">
                                    Next &nbsp;
                                    <NextIcon />
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            )}
            <br />
        </div>
    );
};

export default Prepaid_Completed_Enrollments;
