import { api } from "../../../../../../../utility/AxiosInceptor";
import React, { useEffect, useState, useRef } from "react";
import { Button } from "primereact/button";
// import { Calendar } from "primereact/calendar";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Axios from "axios";
import { ToastContainer } from "react-toastify"; // Import ToastContainer and toast
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ProgressSpinner } from "primereact/progressspinner";
import { useNavigate } from "react-router-dom";
import { Dialog } from "primereact/dialog";
import DialogForReject from "./dialogs/DialogForReject";
import DialogForActivateSim from "./dialogs/DialogForActivateSim";
import { InputText } from "primereact/inputtext";
import DialogeForRemarks from "./dialogs/DialogeForRemarks";
import DialogeForTransferUser from "./dialogs/DialogeForTransferUser";
import { OverlayPanel } from "primereact/overlaypanel";
import DialogeForRemarksForIJ from "./dialogs/DialogeForRemarksForIJ";
import "./styles.css";
import "./all_enrollment.css";
import { Backbtn, Editbutton, Export, ExportHover, Filter, Import, ImportHover, Status, Calendar, Sortby, DownloadIcon, Nextbtn, NextIcon, PrevIcon, EmptyEnrollment } from "../../../../../../../utility";
import CommonLoaderBlue from "../../../../billingModel/billingmodel_configurations/Loaders/components/Loader/LoaderBlue";
import DateRangeCalendar from "./Calendar/Calendar-DropDown";
const BASE_URL = process.env.REACT_APP_BASE_URL;
const PrepaidAllEnrollments = () => {
    const [isEnrolmentId, setIsEnrolmentId] = useState();
    const [CsrId, setCsrId] = useState();
    const [totalSearchResult, setTotalSearchResult] = useState([]);
    const [totalIncaseSearch, setTotalIncaseSearch] = useState(0);
    const [zipCode, setZipCode] = useState();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [openDialogeForActivate, setOpenDialogeForActivate] = useState(false);
    const [OpenDialogeForRemarks, setOpenDialogeForRemarks] = useState(false);
    const [OpenDialogeForRemarksForIJ, setOpenDialogeForRemarksForIJ] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isButtonLoading, setisButtonLoading] = useState(false);
    const [link, setLink] = useState();
    const [allEnrollments, setAllEnrollments] = useState([]);
    const [expandedRows, setExpandedRows] = useState([]);
    const [checkType, setCheckType] = useState();
    const [customerSearchName, setCustomerSearchName] = useState("");
    const [selectedRow, setSelectedRow] = useState(null);
    const [dialogeForTransfer, setDialogeForTransfer] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [checkRemarks, setCheckRemarks] = useState();
    const [hover, setHover] = useState(false);
    const [completeListEnrollment, setCompleteListEnrollment] = useState([]);
    const [hoverLeave, setHoverLeave] = useState(false);
    const [hoverAfter, setHoverAfter] = useState(false);
    const [hoverLeaveAfter, setHoverLeaveAfter] = useState(false);
    const [enrollmentFrom, setEnrollmentFrom] = useState(1);
    const [enrollmentTo, setEnrollmentTo] = useState(15);
    const [prospect, setProspect] = useState(false);
    const [cancelled, setCancelled] = useState(false);
    const [all, setAll] = useState(false);
    const [selectedRange, setSelectedRange] = useState([null, null]);
    const [shortSelection, setShortSelection] = useState();
    const userData = JSON.parse(localStorage.getItem("userData"));
    const [role, setRole] = useState(userData?.role?.role);
    const op = useRef(null);
    const date = useRef(null);
    const navigate = useNavigate();
    const handleEnrollmentIdClick = (rowData) => {
        navigate("/customer-profile", { state: { selectedId: rowData._id } });
        localStorage.setItem("selectedId", JSON.stringify(rowData._id));
    };
    const loginRes = localStorage.getItem("userData");
    const parseLoginRes = JSON.parse(loginRes);
    const roleName = parseLoginRes?.role?.role;
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);
    const getAllEnrollments = async () => {
        setIsLoading(true);
        try {
            const res = await api.get(`/api/user/EnrollmentApprovedByUser?userId=${parseLoginRes?._id}&accountType=Prepaid`);
            if (res?.status === 200 || res?.status === 201) {
                if (!res?.data?.data) {
                    toast.success(" No enrollments have been received from the previous department yet");
                } else if (res?.data?.data) {
                    const updatedData = res?.data?.data.map((item) => ({
                        ...item,
                        enrollment: item.isSelfEnrollment ? "Self Enrollments" : "Enrollment",
                        name: `${item?.firstName ? item?.firstName.toUpperCase() : ""} ${item?.lastName ? item?.lastName.toUpperCase() : ""}`,
                        createdDate: new Date(item.createdAt)
                            .toLocaleDateString("en-US", {
                                month: "2-digit",
                                day: "2-digit",
                                year: "numeric",
                            })
                            .replace(/\//g, "-"),
                        createdTo: item.createdAt,
                    }));

                    setAllEnrollments(updatedData.slice(0, 15));
                    setCompleteListEnrollment(updatedData);
                    setTotalPage(Math.ceil(updatedData.length / 15));
                }
                setIsLoading(false);
            }
        } catch (error) {
            toast.error(`${error?.response?.data?.msg}`);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getAllEnrollments();
    }, []);
    const approveRowByTl = async (rowData) => {
        setisButtonLoading(true);
        const approvedBy = parseLoginRes?._id;
        const enrolmentId = rowData?._id;
        const approved = true;
        const dataToSend = { approvedBy, enrolmentId, approved };

        setCheckRemarks(rowData?.QualityRemarks);

        if (rowData?.QualityRemarks) {
            if (rowData && rowData.QualityRemarks && rowData.QualityRemarks.includes("declined")) {
                toast.error("Declined sales can only rejected");
                setisButtonLoading(false);
            } else {
                try {
                    const response = await api.patch(`/api/user/approval`, dataToSend);
                    if (response?.status === 201 || response?.status === 200) {
                        toast.success("Approved");

                        api.post(`/api/web/order`, { orderNumber: rowData.enrollmentId })
                            .then((response) => {
                                toast.success("Order Displaced Successfully");

                                api.post(`/api/web/order/createLable`, { orderId: response.data.data.orderId.toString(), userId: parseLoginRes._id, testLabel: true })
                                    .then(() => {
                                        toast.success("Label Successfully");
                                    })
                                    .catch((err) => {
                                        // toast.success("Label Creation Failed");
                                    });
                            })
                            .catch((err) => {
                                toast.success("Order Displacing Failed");
                            });
                        setisButtonLoading(false);
                    }
                } catch (error) {
                    toast.error(error?.response?.data?.msg);
                    setisButtonLoading(false);
                }
                getAllEnrollments();
            }
        } else {
            toast.error("Please Add Remarks First");
            setisButtonLoading(false);
        }
    };

    const runNV = async (rowData) => {
        setisButtonLoading(true);
        try {
            const response = await api.post(`/api/user/verifyEligibility?enrollmentId=${rowData?._id}`);
            if (response?.status == 200 || response?.status == 201) {
                const link1 = response?.data?.data?._links?.certification?.href;
                const link2 = response?.data?.data?._links?.resolution?.href;
                if (link1) {
                    setLink(link1);
                } else {
                    setLink(link2);
                }
                const respMsg = response?.data?.data?.status;

                if (respMsg.includes("complete") || respMsg.includes("COMPLETE") || respMsg.includes("Complete")) {
                    toast.success(response?.data?.data?.status);
                } else {
                    toast.warning(response?.data?.data?.status);
                }

                setSelectedRow(rowData);
            }
        } catch (error) {
            const status = error?.response?.status;

            if (status === 500 || status === 400) {
                toast.error(error?.response?.data?.data?.message);
            } else {
                const error1 = error?.response?.data?.data?.body;
                const error2 = error?.response?.data?.data?.errors[0]?.description;

                const errorMessage1 = Array.isArray(error1) ? error1.toString() : error1 && typeof error1 === "object" ? JSON.stringify(error1) : error1;
                const errorMessage2 = Array.isArray(error2) ? error2.toString() : error2 && typeof error2 === "object" ? JSON.stringify(error2) : error2;
                if (errorMessage1) {
                    toast.error("Error is " + errorMessage1);
                } else {
                    toast.error("Error is " + errorMessage2);
                }
            }
        } finally {
            setisButtonLoading(false);
        }
    };

    const enrollUser = async (rowData) => {
        setisButtonLoading(true);
        try {
            const response = await api.post(`/api/user/enrollVerifiedUser?userId=${parseLoginRes?._id}&enrollmentId=${rowData?._id}`);

            if (response?.status == "200" || response?.status == "201") {
                toast.success("Successfully Enrolled");
                getAllEnrollments();
                setisButtonLoading(false);
            }
        } catch (error) {
            const body = error?.response?.data?.data?.body;

            const errorMessage = Array.isArray(body) ? body.toString() : body && typeof body === "object" ? JSON.stringify(body) : body;
            toast.error("Error is " + errorMessage);
            setisButtonLoading(false);
        }
    };

    const HnadleAllApprove = async () => {
        setisButtonLoading(true);
        if (allEnrollments) {
            const enrollmentIds = allEnrollments.map((enrollment) => enrollment._id);

            const dataToSend = {
                approvedBy: parseLoginRes?._id,
                enrolmentIds: enrollmentIds,
                approved: true,
            };
            try {
                const response = await api.patch(`/api/user/batchApproval`, dataToSend);
                if (response?.status == "200" || response?.status == "201") {
                    toast.success("Approved");
                    setisButtonLoading(false);
                    getAllEnrollments();
                }
            } catch (error) {
                toast.error(error?.response?.data?.msg);
                setisButtonLoading(false);
            }
            setisButtonLoading(false);
        } else {
            toast.error("No Enrollment Found");
            setisButtonLoading(false);
        }
    };

    const handleApproveSelected = async () => {
        setisButtonLoading(true);
        if (allEnrollments) {
            const enrollmentIds = selectedRows.map((enrollment) => enrollment._id);

            const dataToSend = {
                approvedBy: parseLoginRes?._id,
                enrolmentIds: enrollmentIds,
                approved: true,
            };
            try {
                const response = await api.patch(`/api/user/batchApproval`, dataToSend);
                if (response?.status == "200" || response?.status == "201") {
                    toast.success("Approved");
                    setisButtonLoading(false);
                    getAllEnrollments();
                }
            } catch (error) {
                toast.error(error?.response?.data?.msg);
                setisButtonLoading(false);
            }
            setisButtonLoading(false);
        } else {
            toast.error("No Enrollment Found");
            setisButtonLoading(false);
        }
    };

    const actionTemplate = (rowData) => {
        return (
            <div>
                <div className="flex flex-wrap justify-content-start tableeditbtn" text raised disabled={isButtonLoading}>
                    <Editbutton />
                </div>
            </div>
        );
    };
    const handleEnrollmentBill = (rowData) => {
        navigate("/invoice", { state: { selectedId: rowData._id } });
        localStorage.setItem("selectedId", JSON.stringify(rowData._id));
    };

    const actionTemplateForTL = (rowData) => {
        return (
            <div>
                {parseLoginRes?.companyName.includes("IJ") || parseLoginRes?.companyName.includes("ij") ? (
                    <Button label="Add Remarks" onClick={() => handleOpenDialogForRemarksForIJ(rowData)} className=" p-button-sucess mr-2 ml-2 pt-1 pb-1" text raised disabled={isButtonLoading} />
                ) : (
                    <Button label="Add Remarks" onClick={() => handleOpenDialogForRemarks(rowData)} className="pt-1 pb-1 p-button-sucess mr-2 ml-2" text raised disabled={isButtonLoading} />
                )}
                <Button label="Billing" onClick={() => handleEnrollmentBill(rowData)} text raised disabled={isButtonLoading} className="pt-1 pb-1" />
                <Button
                    label="Approve"
                    onClick={() => {
                        if (!rowData.QualityRemarks) {
                            toast.error("Please Add Remarks");
                        } else if (rowData.QualityRemarks === "satisfactory" || rowData.QualityRemarks === "good" || rowData.QualityRemarks === "average") {
                            approveRowByTl(rowData);
                        } else {
                            if (rowData.QualityRemarks !== undefined) {
                                toast.error("Only enrollment with call quality marked as good, average or satisfactory will be Approved");
                            } else {
                                toast.error("Please Add Remarks");
                            }
                        }
                    }}
                    className=" p-button-success mr-2 ml-2  pt-1 pb-1 "
                    text
                    raised
                    disabled={isButtonLoading}
                />
                <Button
                    label="Reject"
                    onClick={() => {
                        if (!rowData?.QualityRemarks) {
                            toast.error("Please Add Remarks");
                        } else if (rowData.QualityRemarks === "declined") {
                            handleOpenDialog(rowData);
                        } else {
                            if (rowData.QualityRemarks !== undefined) {
                                toast.error("Only enrollment with call quality marked as declined will be rejected");
                            } else {
                                toast.error("Please Add Remarks");
                            }
                        }
                    }}
                    className=" p-button-danger pt-1 pb-1 mr-2 ml-2"
                    text
                    raised
                    disabled={isButtonLoading}
                />
            </div>
        );
    };

    const actionTemplateForPR = (rowData) => {
        return (
            <div>
                <Button label="Reject" onClick={() => handleOpenDialog(rowData)} className=" p-button-danger pt-1 pb-1 mr-2 ml-2" text raised disabled={isButtonLoading} />
                <Button label="Run NV" onClick={() => runNV(rowData)} className=" mr-2 ml-2 pt-1 pb-1" text raised disabled={isButtonLoading} />
                {selectedRow === rowData && link ? (
                    <Button
                        label="Go To Link"
                        onClick={() => {
                            window.open(link, "_blank");
                        }}
                        className=" mr-2 ml-2 pt-1 pb-1 p-button-warning"
                        text
                        raised
                        disabled={isButtonLoading}
                    />
                ) : null}
                <Button label="Enroll User" onClick={() => enrollUser(rowData)} className=" mr-2 ml-2 pt-1 pb-1" text raised disabled={isButtonLoading} />
                <Button label="Activate Sim" onClick={() => handleDialogeForActivate(rowData)} className=" mr-2 ml-2 pt-1 pb-1" text raised disabled={isButtonLoading} />
                {/* <Button label="Update User With NLAD" onClick={() => updateUser(rowData)} className=" mr-2 ml-2" text raised disabled={isButtonLoading} /> */}
                <Button label="Transfer User" onClick={() => transferUser(rowData)} className=" mr-2 ml-2 pt-1 pb-1" text raised disabled={isButtonLoading} />
            </div>
        );
    };
    const handleOpenDialog = (rowData) => {
        setisButtonLoading(true);
        setIsModalOpen(true);
        setIsEnrolmentId(rowData?._id);
        setCsrId(rowData?.csr);
        setCheckType(rowData?.enrollment);
        setisButtonLoading(false);
    };

    const handleDialogeForActivate = (rowData) => {
        setOpenDialogeForActivate(true);
        setIsEnrolmentId(rowData?._id);
        setZipCode(rowData?.zip);
    };
    const handleOpenDialogForRemarks = (rowData) => {
        setOpenDialogeForRemarks(true);
        setIsEnrolmentId(rowData?._id);
    };

    const handleOpenDialogForRemarksForIJ = (rowData) => {
        setOpenDialogeForRemarksForIJ(true);
        setIsEnrolmentId(rowData?._id);
    };
    const transferUser = async (rowData) => {
        setDialogeForTransfer(true);
        setIsEnrolmentId(rowData?._id);
    };
    const [selectedNumberToShow, setSelectedNumberToShow] = useState(15);
    return (
        <div className="prepaidall">
            <ToastContainer className="custom-toast-container" />
            <form>
                <Dialog visible={isModalOpen} style={{ width: "50vw" }} draggable={false} onHide={() => setIsModalOpen(false)}>
                    <DialogForReject setIsModalOpen={setIsModalOpen} checkType={checkType} enrollmentId={isEnrolmentId} CSRid={CsrId} getAllEnrollments={getAllEnrollments} />
                </Dialog>
                <Dialog header={"Activate Sim"} visible={openDialogeForActivate} style={{ width: "70vw" }} onHide={() => setOpenDialogeForActivate(false)}>
                    <DialogForActivateSim enrollmentId={isEnrolmentId} setOpenDialogeForActivate={setOpenDialogeForActivate} zipCode={zipCode} />
                </Dialog>
                <Dialog header={"Add Remarks"} visible={OpenDialogeForRemarks} style={{ width: "70vw" }} onHide={() => setOpenDialogeForRemarks(false)}>
                    <DialogeForRemarks getAllEnrollments={getAllEnrollments} enrollmentId={isEnrolmentId} />
                </Dialog>
                <Dialog header={"Add Remarks"} visible={OpenDialogeForRemarksForIJ} style={{ width: "70vw" }} onHide={() => setOpenDialogeForRemarksForIJ(false)}>
                    <DialogeForRemarksForIJ getAllEnrollments={getAllEnrollments} enrollmentId={isEnrolmentId} setOpenDialogeForRemarksForIJ={setOpenDialogeForRemarksForIJ} />
                </Dialog>
                <Dialog header={"Transfer User"} visible={dialogeForTransfer} style={{ width: "30vw" }} onHide={() => setDialogeForTransfer(false)}>
                    <DialogeForTransferUser enrollmentId={isEnrolmentId} setDialogeForTransfer={setDialogeForTransfer} />
                </Dialog>
            </form>
            <div className="flex w-full flex-column justify-content-start h-4" style={{ marginBottom: "1.5rem" }}>
                <h3 className="heading">Enrollments Queue</h3>
                <p className="para mt-2">Track all enrollments with 'Prospect' or 'Cancelled' status and manage them easily from one unified view.</p>
                <div className="flex flex-wrap justify-content-start align-items-center mt-2 w-full">
                    <div className="flex flex-wrap w-full justify-content-between align-items-center">
                        <div className="flex align-items-center prosp">
                            <h2 className="prosp-heading">Prospects/ Cancelled</h2>
                        </div>
                        <div className="flex align-items-center" style={{ width: "50.5rem", gap: "1.6rem" }}>
                            <InputText
                                className="searchbar"
                                value={customerSearchName}
                                onChange={(e) => {
                                    setCustomerSearchName(e.target.value);
                                    setCurrentPage(1);

                                    if (e.target.value.length > 0) {
                                        let searchResult = completeListEnrollment.filter((item) => item.name.toLowerCase().includes(e.target.value.toLowerCase()));

                                        setAllEnrollments(searchResult);
                                        setTotalIncaseSearch(searchResult.length);
                                        setTotalSearchResult(searchResult);
                                        setEnrollmentFrom(1);
                                        setEnrollmentTo(searchResult.length > 15 ? 15 : searchResult.length);
                                    } else {
                                        setTotalSearchResult([]);
                                        setAllEnrollments(completeListEnrollment.slice(0, 15));
                                        setEnrollmentFrom(1);
                                        setEnrollmentTo(completeListEnrollment.length > 15 ? 15 : completeListEnrollment.length);
                                    }
                                }}
                                placeholder="ID, Name, Contact, Date"
                            />
                            <div>
                                <button className="selector" onClick={(e) => date.current.toggle(e)}>
                                    <Calendar />
                                </button>
                                <OverlayPanel ref={date}>
                                    <DateRangeCalendar setSelectedRange={setSelectedRange} setShortSelection={setShortSelection} shortSelection={shortSelection} selectedRange={selectedRange} />
                                </OverlayPanel>
                            </div>
                            <div>
                                <button className="selector" onClick={(e) => op.current.toggle(e)}>
                                    <Status />
                                </button>
                                <OverlayPanel ref={op}>
                                    <div className="flex flex-column w-full overlay">
                                        <div className="flex justify-content-start align-items-center" style={{ gap: "10px", height: "36px" }}>
                                            <input id="prepaid" name="prepaid" className="check" type="checkbox" onChange={(e) => setProspect(e.value)} checked={prospect} />
                                            <label>Prospect</label>
                                        </div>
                                        <div className="flex justify-content-start align-items-center" style={{ gap: "10px", height: "36px" }}>
                                            <input id="postpaid" name="postpaid" className="check" type="checkbox" onChange={(e) => setCancelled(e.value)} checked={cancelled} />
                                            <label>Cancelled</label>
                                        </div>
                                        <div className="flex justify-content-start align-items-center" style={{ gap: "10px", height: "36px" }}>
                                            <input id="acp" name="acp" className="check" type="checkbox" onChange={(e) => setAll(e.value)} checked={all} />
                                            <label>All</label>
                                        </div>
                                    </div>
                                </OverlayPanel>
                            </div>
                            <div>
                                <button className="export flex justify-content-evenly align-items-center" style={{ opacity: role !== "Admin" ? "0.5" : "1" }}>
                                    <p className="flex justify-content-between">
                                        Export
                                        <span className="ml-2">
                                            <DownloadIcon />
                                        </span>
                                    </p>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {allEnrollments.length === 0 && !isLoading ? (
                <div className="flex w-full justify-content-center " style={{ marginTop: "5rem" }}>
                    <div className="emptystatediv">
                        <div className="emptyicon">
                            <span>
                                <EmptyEnrollment />
                            </span>
                        </div>
                        <div className="emptystatedata">
                            <h1>No Enrollments in the Queue!</h1>
                            <p>There are currently no active, suspended, or disconnected enrollments to display. Start new enrollments to fill your queue!</p>
                            <div className="flex w-full justify-content-center">
                                <Button className="emptystatebtn" label="Start Enrollment" onClick={() => navigate("/prepaid-newenrollment")} />
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div>
                    {isButtonLoading ? <ProgressSpinner className="flex flex-wrap justify-content-center flex-row mt-4" /> : null}

                    <DataTable
                        value={allEnrollments}
                        selection={selectedRows}
                        onSelectionChange={role !== "Admin" ? null : (e) => setSelectedRows(e.value)}
                        size="small"
                        stripedRows
                        resizableColumns
                        expandedRows={expandedRows}
                        onRowToggle={(e) => setExpandedRows(e.data)}
                        onRowClick={(e) => handleEnrollmentIdClick(e.data)}
                        emptyMessage={
                            isLoading ? (
                                <div className="flex flex-wrap w-full justify-content-center  flex-row  mt-4 mb-4 ">
                                    <CommonLoaderBlue customSize={16} />
                                </div>
                            ) : (
                                "Enrollment Not Found"
                            )
                        }
                        style={{ fontFamily: "Inter", fontSize: "14px" }}
                    >
                        <Column selectionMode="multiple" style={{ width: "1rem", pointerEvents: role !== "Admin" ? "none" : "auto", opacity: role !== "Admin" ? 0.5 : 1 }} />
                        <Column
                            header="Id"
                            field="enrollmentId"
                            // body={(rowData) => (
                            //     <button style={{ fontSize: "1.4rem", fontWeight: "500", color: "#5E79FF", border: "none", backgroundColor: "transparent", cursor: "pointer", marginLeft: "-0.5rem" }} onClick={() => handleEnrollmentIdClick(rowData)}>
                            //         {rowData.enrollmentId}
                            //     </button>
                            // )}
                        ></Column>
                        <Column header="Name" field="name"></Column>
                        <Column field="contact" header="Contact" />
                        {/* <Column field="createdBy.name" header="Created By" /> */}
                        {/* <Column
                            header="Linked"
                            body={(rowData) => {
                                return <p className={rowData?.linkedAccount ? "yes" : "no"}>{rowData?.linkedAccount ? "Yes" : "No"}</p>;
                            }}
                        />
                        <Column header="Actions" body={actionTemplate}></Column> */}
                        <Column
                            header="Status"
                            field="status"
                            body={(rowData) => (
                                <span
                                    style={{
                                        color:
                                            rowData?.status === "prospect"
                                                ? "#4A90E2"
                                                : rowData?.status === "cancelled"
                                                  ? "#95A5A6"
                                                  : rowData?.status === "active"
                                                    ? "#27AE60"
                                                    : rowData?.status === "disconnected"
                                                      ? "#B3261E"
                                                      : rowData?.status === "inactive"
                                                        ? "#B3261E"
                                                        : rowData?.status === "suspended"
                                                          ? "#F39C12"
                                                          : "",
                                        fontSize: "1.4rem",
                                        textTransform: "capitalize",
                                    }}
                                >
                                    {rowData?.status}
                                </span>
                            )}
                        />
                        {role === "Admin" && <Column field="createdBy.name" header="Created By" />}
                        <Column field="createdDate" header="Date" />
                    </DataTable>
                    <div className="flex w-full justify-content-between align-items-center mt-5">
                        <div className="totalcount">
                            {enrollmentTo} / {customerSearchName.length > 0 ? totalIncaseSearch : completeListEnrollment.length}
                        </div>
                        <div className="flex">
                            <button
                                onClick={() => {
                                    const itemsPerPage = 15;
                                    const calculateTotalPages = (data) => Math.ceil(data.length / itemsPerPage);

                                    const handlePageNavigation = (data, setDataFn) => {
                                        const totalPages = calculateTotalPages(data);
                                        if (currentPage > 1) {
                                            let startIdx, endIdx;

                                            if (totalPages - 1 === currentPage) {
                                                startIdx = (currentPage - 2) * itemsPerPage;
                                                endIdx = startIdx + itemsPerPage;
                                            } else if (totalPages === currentPage) {
                                                startIdx = data.length - (data.length % itemsPerPage) - itemsPerPage;
                                                endIdx = startIdx + itemsPerPage;
                                            } else {
                                                startIdx = (currentPage - 2) * itemsPerPage;
                                                endIdx = startIdx + itemsPerPage;
                                            }

                                            setDataFn(data.slice(startIdx, endIdx));
                                            setCurrentPage((prev) => prev - 1);
                                            setEnrollmentFrom(startIdx + 1);
                                            setEnrollmentTo(endIdx);
                                        }
                                    };

                                    if (customerSearchName.length > 0) {
                                        handlePageNavigation(totalSearchResult, setAllEnrollments);
                                    } else {
                                        handlePageNavigation(completeListEnrollment, setAllEnrollments);
                                    }
                                }}
                                className="cursor-pointer backbutton"
                                style={{ backgroundColor: "#FFFFFF33" }}
                            >
                                <span>
                                    <PrevIcon /> &nbsp; Previous
                                </span>
                            </button>
                            {/* <span style={{ fontSize: "1.4rem" }} className="flex align-items-center ">
                                Result per page
                                <button className="resultbtn" style={{ backgroundColor: "#FFFFFF33" }}>
                                    {selectedNumberToShow}
                                </button>
                                &nbsp; {enrollmentFrom}-{enrollmentTo}&nbsp; of&nbsp; {customerSearchName.length > 0 ? totalIncaseSearch : completeListEnrollment.length}
                            </span>
                            &nbsp; */}
                            <div className="flex align-items-center justify-content-center" style={{ width: "12rem", fontWeight: "500", fontSize: "1.4rem", color: "#72728F" }}>
                                &nbsp; {enrollmentFrom}-{enrollmentTo}&nbsp; of&nbsp; {customerSearchName.length > 0 ? totalIncaseSearch : completeListEnrollment.length}
                            </div>

                            <button
                                onClick={() => {
                                    if (customerSearchName.length > 0) {
                                        let totalPages = Math.ceil(totalSearchResult.length / 15);
                                        if (totalPages > currentPage) {
                                            let enrollmentTillCurrentPage = currentPage * 15;
                                            if (totalPages - 1 === currentPage) {
                                                setAllEnrollments(totalSearchResult.slice(enrollmentTillCurrentPage));

                                                setCurrentPage((prev) => prev + 1);
                                                setEnrollmentFrom(enrollmentTillCurrentPage + 1);
                                                setEnrollmentTo(totalSearchResult.length);
                                            } else {
                                                setAllEnrollments(totalSearchResult.slice(enrollmentTillCurrentPage, enrollmentTillCurrentPage + 15));

                                                setCurrentPage((prev) => prev + 1);
                                                setEnrollmentFrom(enrollmentTillCurrentPage + 1);
                                                setEnrollmentTo(enrollmentTillCurrentPage + 15);
                                            }
                                        }
                                    } else {
                                        let totalPages = Math.ceil(completeListEnrollment.length / 15);
                                        if (totalPages > currentPage) {
                                            let enrollmentTillCurrentPage = currentPage * 15;
                                            if (totalPages - 1 === currentPage) {
                                                setAllEnrollments(completeListEnrollment.slice(enrollmentTillCurrentPage));

                                                setCurrentPage((prev) => prev + 1);
                                                setEnrollmentFrom(enrollmentTillCurrentPage + 1);
                                                setEnrollmentTo(completeListEnrollment.length);
                                            } else {
                                                setAllEnrollments(completeListEnrollment.slice(enrollmentTillCurrentPage, enrollmentTillCurrentPage + 15));

                                                setCurrentPage((prev) => prev + 1);
                                                setEnrollmentFrom(enrollmentTillCurrentPage + 1);
                                                setEnrollmentTo(enrollmentTillCurrentPage + 15);
                                            }
                                        }
                                    }
                                }}
                                className="cursor-pointer nextbutton"
                                style={{ backgroundColor: "#FFFFFF33" }}
                            >
                                <span className="flex align-items-center">
                                    Next &nbsp;
                                    <NextIcon />
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
export default PrepaidAllEnrollments;
