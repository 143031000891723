import { api } from "../../../../utility/AxiosInceptor";
import { InputText } from "primereact/inputtext";
import React, { useEffect, useState } from "react";
import "./css/plan_configuration.css";
import { useFormik } from "formik";
import { Dropdown } from "primereact/dropdown";
import * as Yup from "yup";
import { Button } from "primereact/button";
import { useRef } from "react";
import { Toast } from "primereact/toast";
import { InputTextarea } from "primereact/inputtextarea";
import { MultiSelect } from "primereact/multiselect";
const BASE_URL = process.env.REACT_APP_BASE_URL;
const validationSchema = Yup.object().shape({
    name: Yup.string().required("This is Required"),
    description: Yup.string().required("This is Required"),
    dataAllowance: Yup.number().integer("Please enter a valid integer").required("This is Required "),
    voiceAllowance: Yup.number().integer("Please enter a valid integer").required("This is Required"),
    textAllowance: Yup.number().integer("Please enter a valid integer").required("This is Required"),
    duration: Yup.number().integer("Please enter a valid integer").required("This is Required"),
    price: Yup.number().required("This is Required"),
    planId: Yup.string().required("This is Required"),
    type: Yup.string().required("This is Required"),
    carrier: Yup.string().required("This is Required"),

    durationUnit: Yup.string().required("This is Required"),
    inventoryType: Yup.string().required("This is Required"),
    makeModel: Yup.string().required("This is Required"),
});

export default function PlansConfigurations({ setAddPlanVisibility, setRefresh }) {
    const loginRes = localStorage.getItem("userData");
    const parseLoginRes = JSON.parse(loginRes);
    const [carrier, setCarrier] = useState(null);
    const [currentBillingId, setCurrentBillingId] = useState("");
    const toast = useRef(null);
    const [inventoryTypeOptions, setInventoryTypeOptions] = useState([]);
    const [billingModelOptions, setBillingModelOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const getBillingModelList = async () => {
        try {
            const res = await api.get(`/api/billingModel/all?serviceProvider=${parseLoginRes?.company}`);
            setBillingModelOptions(res?.data?.data || []);
        } catch (error) {
            toast.current.show({ severity: "error", summary: "Plan Updation", detail: error?.response?.data?.msg });
        }
    };
    useEffect(() => {
        getBillingModelList();
    }, []);
    const isUnlimitedOptions = [
        {
            label: "Voice",
        },
        {
            label: "Text",
        },
        { label: "Data" },
    ];
    const durationUnitOptions = [
        {
            label: "Days",
            value: "days",
        },
    ];
    const [featureOptions, setFeatureOptions] = useState([
        {
            label: "3 Way Calling",
        },
        {
            label: "WI-FI Calling",
        },
        {
            label: "International Roaming",
        },
        {
            label: "Tethering Block",
        },
        {
            label: "5G Compatibility",
        },
    ]);
    const formik = useFormik({
        initialValues: {
            name: "",
            createdBy: parseLoginRes?._id,
            serviceProvider: parseLoginRes?.company,
            description: "",
            carrier: "",
            type: "",
            durationUnit: "",
            dataAllowance: null,
            voiceAllowance: null,
            textAllowance: null,
            duration: null,
            price: "",
            planFeature: [],
            isUnlimited: [],
            makeModel: "",
            planId: "",
            additionalFeatures: ["calls", "minutes"],
            termsAndConditions: "no termsAndConditions",
            restrictions: ["no restriction"],
            inventoryType: "",
        },
        validationSchema,
        onSubmit: (values) => {
            setIsLoading(true);
            let selectedFeatures = [];
            featureOptions.forEach((item) => {
                let obj = {
                    name: item?.label,
                    isSelected: formik.values.planFeature.includes(item?.label) ? true : false,
                };
                selectedFeatures.push(obj);
            });
            let make = formik.values.makeModel.substring(0, formik.values.makeModel.indexOf("-") - 1);
            let model = formik.values.makeModel.substring(formik.values.makeModel.lastIndexOf("-") + 2);
            let dataTosend = { ...formik.values, planFeature: selectedFeatures, make: make, model: model };
            delete dataTosend.makeModel;

            api.post(`/api/web/plan`, dataTosend)
                .then(() => {
                    setIsLoading(false);
                    toast.current.show({ severity: "success", summary: "Plan Submission", detail: "Plan Added Successfully" });
                    setTimeout(() => {
                        setAddPlanVisibility(false);
                        setRefresh((prev) => !prev);
                    }, 2000);
                })
                .catch((err) => {
                    setIsLoading(false);
                    toast.current.show({ severity: "error", summary: "Plan Submission", detail: err?.response?.data?.msg });
                });
        },
    });
    useEffect(() => {
        async function fetchData() {
            if (formik.values.type !== "") {
                try {
                    const res = await api.get(`/api/billingModel/getInventoryByBillingModel?billingModel=${currentBillingId}`);
                    let obj = [];
                    let data = res?.data?.data;
                    data.forEach((item) => {
                        let obj2 = {};
                        obj2.inventoryType = item;
                        obj.push(obj2);
                    });
                    setInventoryTypeOptions(obj);
                } catch (error) {
                    //toast.error(error?.response?.data?.msg);
                }
            }
        }
        fetchData();
    }, [currentBillingId]);

    const [makeModelOptions, setMakeModelOptions] = useState();
    useEffect(() => {
        if (formik.values.inventoryType !== "" && currentBillingId !== "") {
            api.get(`/api/inventoryType/getInventoryTypeData?inventoryType=${formik.values.inventoryType}&billingModel=${currentBillingId}&serviceProvider=${parseLoginRes?.company}`)
                .then((res) => {
                    let Data = res?.data?.data;
                    let dataNew = [];
                    for (let i = 0; i < Data.length; i++) {
                        let data = Data[i];
                        data.makemodel = `${data.make} - ${data.model}`;
                        dataNew.push(data);
                    }
                    setMakeModelOptions(dataNew);
                })
                .catch((err) => {});
        }
    }, [currentBillingId, formik.values.inventoryType]);
    useEffect(() => {
        api.get(`/api/web/carrier/all`)
            .then((res) => {
                let carrierholder = [];
                for (let i = 0; i < res.data.data.length; i++) {
                    const obj = {};
                    obj.label = res.data.data[i].name;
                    obj.value = res.data.data[i]._id;
                    carrierholder.push(obj);
                }
                if (carrierholder.length === 1) {
                    formik.setFieldValue("carrier", carrierholder[0].value);
                }
                setCarrier(carrierholder);
            })
            .catch(() => {});
    }, []);

    return (
        <div className="addplanmain">
            <div className="addplandata">
                <div
                    className="go-back-btn"
                    onClick={() => {
                        setAddPlanVisibility(false);
                    }}
                >
                    <i className="go-back pi pi-angle-left " style={{ cursor: "pointer" }} />
                </div>
                <div className="planmainheader" style={{ marginTop: "1.6rem" }}>
                    <label>Add Plan</label>
                    <p>Please provide your basic information, including name, contact details, and email, to start the enrollment process.</p>
                </div>
                <form onSubmit={formik.handleSubmit} className="mainaddplanform">
                    <div className="addplanfield w-full">
                        <div className="flex justify-content-between" style={{ width: "30rem" }}>
                            <label className="block">
                                Billing Model <span className="steric">*</span>
                            </label>
                            {formik.touched.type && formik.errors.type ? <p className="error">{formik.errors.type}</p> : null}
                        </div>
                        <Dropdown
                            className={formik.touched.type && formik.errors.type ? "addplanfieldinputerror" : "addplanfieldinput"}
                            placeholder="Plan Type"
                            options={billingModelOptions}
                            optionLabel="billingModel"
                            optionValue="billingModel"
                            name="type"
                            value={formik.values.type}
                            onChange={(e) => {
                                formik.setFieldValue("type", e.value);
                                let id;
                                billingModelOptions.map((item) => {
                                    if (item.billingModel === e.value) {
                                        id = item._id;
                                    }
                                });
                                setCurrentBillingId(id);
                            }}
                        />
                    </div>
                    <div className="inventorydetails">
                        <h5> Add Inventory Details</h5>
                        <div style={{ marginTop: "1.2rem" }}>
                            <div className="flex" style={{ gap: "1.2rem" }}>
                                <div className="addplanfield mt-0">
                                    <div className="flex justify-content-between">
                                        <label>
                                            Plan Inventory Type <span className="steric">*</span>
                                        </label>
                                        {formik.touched.inventoryType && formik.errors.inventoryType ? <p className="error">{formik.errors.inventoryType}</p> : null}
                                    </div>
                                    <Dropdown
                                        placeholder="Plan Inventory Type"
                                        options={inventoryTypeOptions}
                                        optionLabel="inventoryType"
                                        optionValue="inventoryType"
                                        className={formik.touched.inventoryType && formik.errors.inventoryType ? "addplanfieldinputerror" : "addplanfieldinput"}
                                        name="inventoryType"
                                        value={formik.values.inventoryType}
                                        onChange={formik.handleChange}
                                    />
                                </div>
                                <div className="addplanfield mt-0 ">
                                    <div className="flex justify-content-between">
                                        <label className="block">Make And Model</label>
                                        {formik.touched.makeModel && formik.errors.makeModel ? <p className=" error">{formik.errors.makeModel}</p> : null}
                                    </div>
                                    <Dropdown
                                        optionLabel="makemodel"
                                        optionValue="makemodel"
                                        value={formik.values.makeModel}
                                        name="makeModel"
                                        options={makeModelOptions}
                                        onChange={formik.handleChange}
                                        placeholder="Select an option"
                                        className={formik.touched.makeModel && formik.errors.makeModel ? "addplanfieldinputerror" : "addplanfieldinput"}
                                    />
                                </div>
                                <div className="addplanfield mt-0">
                                    <div className="flex justify-content-between">
                                        <label className="block">
                                            Carrier <span className="steric">*</span>
                                        </label>
                                        {formik.touched.carrier && formik.errors.carrier ? <p className=" error">{formik.errors.carrier}</p> : null}
                                    </div>
                                    <Dropdown
                                        value={formik.values.carrier}
                                        options={carrier}
                                        optionLabel="label"
                                        name="carrier"
                                        optionValue="value"
                                        onChange={formik.handleChange}
                                        placeholder="Select an option"
                                        className={formik.touched.carrier && formik.errors.carrier ? "addplanfieldinputerror" : "addplanfieldinput"}
                                    />
                                </div>
                            </div>
                            <div className="flex" style={{ gap: "1.2rem" }}>
                                <div className="addplanfield ">
                                    <div className="flex justify-content-between">
                                        <label className="block">
                                            Plan Name <span className="steric">*</span>
                                        </label>
                                        {formik.touched.name && formik.errors.name ? <p className=" error">{formik.errors.name}</p> : null}
                                    </div>
                                    <InputText
                                        placeholder="Plan Name"
                                        className={formik.touched.name && formik.errors.name ? "addplanfieldinputerror" : "addplanfieldinput"}
                                        name="name"
                                        value={formik.values.name}
                                        onChange={(e) => {
                                            formik.setFieldValue("name", capitalizeFirstLetter(e.target.value));
                                        }}
                                    />
                                </div>

                                <div className="addplanfield ">
                                    <div className="flex justify-content-between">
                                        <label className="block">
                                            Plan ID <span className="steric">*</span>
                                        </label>
                                        {formik.touched.planId && formik.errors.planId ? <p className="error">{formik.errors.planId}</p> : null}
                                    </div>
                                    <InputText placeholder="Plan ID" className={formik.touched.planId && formik.errors.planId ? "addplanfieldinputerror" : "addplanfieldinput"} name="planId" value={formik.values.planId} onChange={formik.handleChange} />
                                </div>
                                <div className="addplanfield ">
                                    <label className="block">
                                        Unlimited <span className="steric">*</span>
                                    </label>

                                    <MultiSelect className="addplanfieldinput" placeholder="Select Unlimited" optionLabel="label" options={isUnlimitedOptions} optionValue="label" name="isUnlimited" onChange={formik.handleChange} value={formik.values.isUnlimited} />
                                </div>
                            </div>
                            <div className="flex" style={{ gap: "1.2rem" }}>
                                <div className="addplanfield ">
                                    <label className="block">Features</label>

                                    <MultiSelect className="addplanfieldinput" placeholder="Select Features" optionLabel="label" options={featureOptions} optionValue="label" name="planFeature" onChange={formik.handleChange} value={formik.values.planFeature} />
                                </div>
                                <div className="addplanfield ">
                                    <div className="flex justify-content-between">
                                        <label className="block">
                                            Plan Data Allowance <span className="steric">*</span>
                                        </label>
                                        {formik.touched.dataAllowance && formik.errors.dataAllowance ? <p className=" error">{formik.errors.dataAllowance}</p> : null}
                                    </div>
                                    <InputText
                                        placeholder="Plan Data Allowance"
                                        keyfilter="int"
                                        className={formik.touched.dataAllowance && formik.errors.dataAllowance ? "addplanfieldinputerror" : "addplanfieldinput"}
                                        name="dataAllowance"
                                        value={formik.values.dataAllowance}
                                        onChange={formik.handleChange}
                                    />
                                </div>
                                <div className="addplanfield ">
                                    <div className="flex justify-content-between">
                                        <label className="block">
                                            Plan Voice Allowance <span className="steric">*</span>
                                        </label>
                                        {formik.touched.voiceAllowance && formik.errors.voiceAllowance ? <p className="error">{formik.errors.voiceAllowance}</p> : null}
                                    </div>
                                    <InputText
                                        placeholder="Plan Voice Allowance"
                                        keyfilter="int"
                                        className={formik.touched.voiceAllowance && formik.errors.voiceAllowance ? "addplanfieldinputerror" : "addplanfieldinput"}
                                        name="voiceAllowance"
                                        value={formik.values.voiceAllowance}
                                        onChange={formik.handleChange}
                                    />
                                </div>
                            </div>
                            <div className="flex " style={{ gap: "1.2rem" }}>
                                <div className="addplanfield ">
                                    <div className="flex justify-content-between">
                                        <label className="block">
                                            Plan Text Allowance <span className="steric">*</span>
                                        </label>
                                        {formik.touched.textAllowance && formik.errors.textAllowance ? <p className="error">{formik.errors.textAllowance}</p> : null}
                                    </div>
                                    <InputText
                                        placeholder="Plan Text Allowance"
                                        keyfilter="int"
                                        className={formik.touched.textAllowance && formik.errors.textAllowance ? "addplanfieldinputerror" : "addplanfieldinput"}
                                        name="textAllowance"
                                        value={formik.values.textAllowance}
                                        onChange={formik.handleChange}
                                    />
                                </div>
                                <div className="addplanfield ">
                                    <div className="flex justify-content-between">
                                        <label className="block">
                                            Duration Unit <span className="steric">*</span>
                                        </label>
                                        {formik.touched.durationUnit && formik.errors.durationUnit ? <p className=" error">{formik.errors.durationUnit}</p> : null}
                                    </div>

                                    <Dropdown
                                        options={durationUnitOptions}
                                        optionLabel="label"
                                        optionValue="value"
                                        placeholder="Duration Unit"
                                        className={formik.touched.durationUnit && formik.errors.durationUnit ? "addplanfieldinputerror" : "addplanfieldinput"}
                                        name="durationUnit"
                                        value={formik.values.durationUnit}
                                        onChange={formik.handleChange}
                                    />
                                </div>
                                <div className="addplanfield ">
                                    <div className="flex justify-content-between">
                                        <label className="block">
                                            Plan Duration <span className="steric">*</span>
                                        </label>
                                        {formik.touched.duration && formik.errors.duration ? <p className="error">{formik.errors.duration}</p> : null}
                                    </div>
                                    <InputText placeholder="Plan Duration" keyfilter="int" className={formik.touched.duration && formik.errors.duration ? "addplanfieldinputerror" : "addplanfieldinput"} name="duration" value={formik.values.duration} onChange={formik.handleChange} />
                                </div>
                            </div>
                            <div className="flex" style={{ gap: "1.2rem" }}>
                                <div className="addplanfield ">
                                    <div className="flex justify-content-between">
                                        <label className="block">
                                            Plan Price <span className="steric">*</span>
                                        </label>
                                        {formik.touched.price && formik.errors.price ? <p className="error">{formik.errors.price}</p> : null}
                                    </div>

                                    <InputText placeholder="Plan Price" keyfilter="num" className={formik.touched.price && formik.errors.price ? "addplanfieldinputerror" : "addplanfieldinput"} name="price" value={formik.values.price} onChange={formik.handleChange} />
                                </div>
                            </div>
                            <div className="addplanfield " style={{ marginTop: "1.6rem" }}>
                                <div className="flex justify-content-between" style={{ width: "61.2rem" }}>
                                    <label className="block">
                                        Plan Description <span className="steric">*</span>
                                    </label>
                                    {formik.touched.description && formik.errors.description ? <p className="error">{formik.errors.description}</p> : null}
                                </div>

                                <InputTextarea placeholder="Plan Description" name="description" value={formik.values.description} onChange={formik.handleChange} className={formik.touched.description && formik.errors.description ? "textareaplanerror" : "textareaplan"} />
                                {/* <InputText placeholder="Plan Description" className="addplanfieldinput" name="description" value={formik.values.description} onChange={formik.handleChange} /> */}
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div className="addplanbtn">
                <Button
                    onClick={() => {
                        setAddPlanVisibility(false);
                    }}
                    loading={isLoading}
                    disabled={isLoading}
                    className=" backbtn"
                    label="Cancel"
                    type="button"
                    style={{ width: "16rem", height: "5rem" }}
                />
                <Button onClick={formik.handleSubmit} loading={isLoading} disabled={isLoading} className=" nextbtn" label="Add Plan" type="Submit" style={{ width: "16rem", height: "5rem" }} />
            </div>
            <Toast ref={toast} />
        </div>
    );
}
function capitalizeFirstLetter(string) {
    return string
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
}
