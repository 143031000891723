import React, { useEffect, useRef, useState } from "react";
import "./Main_Add_Service.css";
import { api } from "../../../../../utility/AxiosInceptor";
import InitiateEnrollment from "./components/Initiation/InitiationMain";
import PersonalInfo from "./components/PersonalInfo/PersonalInfo";
import Address from "./components/Address/Address";
import PaymentScreen from "./components/PaymentScreen/PaymentScreen";
import Axios from "axios";
import Preview from "./components/Preview/Preview";
import { Toast } from "primereact/toast";
export default function AddServiceMain({ isPrepaidIncomplete, setIsPrepaidIncomplete }) {
    let toast = useRef();
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const [initiateActive, setInitiateActive] = useState(isPrepaidIncomplete !== "" ? false : true);
    const [personalInfoActive, setPersonalInfoActive] = useState(isPrepaidIncomplete !== "" ? true : false);
    const [addressActive, setAddressActive] = useState(false);
    const [productActive, setProductActive] = useState(false);
    const [subscriptionActive, setSubscriptionActive] = useState(false);
    const [deliveryActive, setDeliveryActive] = useState(false);
    const [billingActive, setBillingActive] = useState(false);
    const [previewActive, setPreviewActive] = useState(false);
    let selectedId = JSON.parse(localStorage.getItem("selectedId"));
    const [confirmationActive, setConfirmationActive] = useState(false);
    const [inventoryTypeSelected, setInventoryTypeSelected] = useState(() => isPrepaidIncomplete?.inventoryId?.inventoryType || "");
    const [checked, setChecked] = useState(() => isPrepaidIncomplete?.shippingLabelDiscount || "");
    const [inventoryIdSelected, setInventoryIdSelected] = useState(() => isPrepaidIncomplete?.inventoryId?._id || "");
    const [inventoryData, setInventoryData] = useState();
    const [paymentScreenActive, setPaymentScreenActive] = useState(false);
    const [previewScreenActive, setPreviewScreenActive] = useState(false);
    const [billingModelSelected, setBillingModelSelected] = useState("");
    const [currentBillingModel, setCurrentBillingModel] = useState();
    const [payingAmount, setPayingAmount] = useState(() => isPrepaidIncomplete?.payingAmount || "");
    const [parentDetails, setParentDetails] = useState(() => isPrepaidIncomplete || "");
    const [additionalsDiscounts, setAdditionalDiscounts] = useState(() => ({
        additional: isPrepaidIncomplete?.selectedAdditional || [],
        setted: isPrepaidIncomplete?.selectedAdditional ? true : false,
        discounts: isPrepaidIncomplete?.selectedDiscount || [],
    }));
    // const [parentCurrentContact, setParentCurrentContact] = useState(isPrepaidIncomplete !== "" ? isPrepaidIncomplete?.contact : "");
    // const [initiationData, setInitiationData] = useState(isPrepaidIncomplete !== "" ? isPrepaidIncomplete : "");
    // const [paymentScreenDetails, setPaymentScreenDetails] = useState({});
    // const [currentAllPlans, setCurrentAllPlans] = useState();
    // const [currentAllBillingConfig, setCurrentAllBillingConfig] = useState("");
    // const [paymentDone, setPaymentDone] = useState(false);
    // const [dataSendForInvoice, setDataSendForInvoice] = useState("");
    // const [allpaymentinfo, setAllPaymentInfo] = useState("");
    // const [proceed, setProceed] = useState(false);
    // const [carrierVal, setCarrierVal] = useState(isPrepaidIncomplete !== "" ? isPrepaidIncomplete?.carrierVal : "");
    // const [servicesVal, setServicesVal] = useState(isPrepaidIncomplete !== "" ? isPrepaidIncomplete?.servicesVal : "");
    // const [deliveryVal, setDeliveryVal] = useState(isPrepaidIncomplete !== "" ? isPrepaidIncomplete?.deliveryVal : "");

    // const [checkLabelVal, setCheckLabelVal] = useState(isPrepaidIncomplete !== "" ? isPrepaidIncomplete?.labelSubmissionval : "");
    // const [generate, setGenerate] = useState(isPrepaidIncomplete !== "" ? isPrepaidIncomplete?.shippingLabelDiscount : "");
    // const [prospectCases, setProspectCases] = useState({
    //     prospectWithInvoice: isPrepaidIncomplete !== "" ? (isPrepaidIncomplete?.isProspect ? isPrepaidIncomplete?.isProspect : false) : false,
    //     prospectWithoutInvoice: isPrepaidIncomplete !== "" ? (isPrepaidIncomplete?.isWithoutProspect ? isPrepaidIncomplete?.isWithoutProspect : false) : false,
    // });
    // const [amount, setAmount] = useState(0);
    // const [currentScreen, setCurrentScreen] = useState(1);
    // const [zipVerified, setZipVerified] = useState(isPrepaidIncomplete !== "" ? isPrepaidIncomplete?.izZipVerified : false);
    // const [currentPlanSelect, setCurrentPlanSelect] = useState(isPrepaidIncomplete !== "" ? (isPrepaidIncomplete?.plan ? isPrepaidIncomplete?.plan?._id : "") : "");
    // const [current, setCurrentSelect] = useState(isPrepaidIncomplete !== "" ? (isPrepaidIncomplete?.inventoryId ? `${isPrepaidIncomplete?.inventoryId?.make + " - " + isPrepaidIncomplete?.inventoryId?.model}` : "") : "");

    // const [validateAddress, setValidateAddress] = useState(false);
    // const [storeValidAddress, setStoreValidAddress] = useState("");
    // const [serviceAddress1, setServiceAddress1] = useState("");
    // const [serviceAddress2, setServiceAddress2] = useState("");
    // const [mailingAddress1, setMailingAddress1] = useState("");
    // const [mailingAddress2, setMailingAddress2] = useState("");
    // const [poBoxAddress, setPoBoxAddress] = useState("");
    // const [slideIn, setSlideIn] = useState(false);

    // useEffect(() => {
    //     setSlideIn(false);

    //     const timer = setTimeout(() => {
    //         setSlideIn(true);
    //     }, 400);
    // }, [initiateActive, personalInfoActive, addressActive, paymentScreenActive, subscriptionActive, deliveryActive, billingActive]);

    // useEffect(() => {
    //     const loginRes = localStorage.getItem("userData");
    //     const parseLoginRes = JSON.parse(loginRes);
    //
    //api.get(`/api/billingModel/all?serviceProvider=${parseLoginRes?.company}`).then((res) => {
    //         let billingmodels = res?.data?.data;
    //         for (let i = 0; i < billingmodels.length; i++) {
    //             if (billingmodels[i].billingModel === "PREPAID") {
    //                 setCurrentBillingModel(billingmodels[i].billingModel);
    //                 setBillingModelSelected(billingmodels[i]._id);
    //             }

    //             if (isPrepaidIncomplete) {
    //                 setProceed(true);
    //                 setParentDetails(isPrepaidIncomplete);
    //                 setInitiationData(isPrepaidIncomplete);
    //                 setInitiateActive(false);
    //                 setPersonalInfoActive(true);
    //                 // setPersonalInfoActive(false);
    //             } else {
    //                 setProceed(true);
    //             }
    //         }
    //     });

    //     return () => {
    //         setIsPrepaidIncomplete("");
    //     };
    // }, []);
    const [parentCurrentContact, setParentCurrentContact] = useState(() => isPrepaidIncomplete?.contact || "");
    const [initiationData, setInitiationData] = useState(() => isPrepaidIncomplete || "");
    const [paymentScreenDetails, setPaymentScreenDetails] = useState({});
    const [currentAllPlans, setCurrentAllPlans] = useState();
    const [currentAllBillingConfig, setCurrentAllBillingConfig] = useState("");
    const [paymentDone, setPaymentDone] = useState(false);
    const [dataSendForInvoice, setDataSendForInvoice] = useState("");
    const [allpaymentinfo, setAllPaymentInfo] = useState("");
    const [proceed, setProceed] = useState(false);
    const [carrierVal, setCarrierVal] = useState(() => isPrepaidIncomplete?.carrierVal || "");
    const [servicesVal, setServicesVal] = useState(() => isPrepaidIncomplete?.servicesVal || "");
    const [deliveryVal, setDeliveryVal] = useState(() => isPrepaidIncomplete?.deliveryVal || "");
    const [checkLabelVal, setCheckLabelVal] = useState(() => isPrepaidIncomplete?.labelSubmissionval || "");
    const [generate, setGenerate] = useState(() => isPrepaidIncomplete?.shippingLabelDiscount || "");
    const [prospectCases, setProspectCases] = useState(() => ({
        prospectWithInvoice: isPrepaidIncomplete?.isProspect || false,
        prospectWithoutInvoice: isPrepaidIncomplete?.isWithoutProspect || false,
    }));
    const [amount, setAmount] = useState(0);
    const [currentScreen, setCurrentScreen] = useState(1);
    const [zipVerified, setZipVerified] = useState(() => isPrepaidIncomplete?.izZipVerified || false);
    const [currentPlanSelect, setCurrentPlanSelect] = useState(() => isPrepaidIncomplete?.plan?._id || "");
    const [current, setCurrentSelect] = useState(() => (isPrepaidIncomplete?.inventoryId ? `${isPrepaidIncomplete.inventoryId.make} - ${isPrepaidIncomplete.inventoryId.model}` : ""));
    const [validateAddress, setValidateAddress] = useState(false);
    const [storeValidAddress, setStoreValidAddress] = useState("");
    const [serviceAddress1, setServiceAddress1] = useState("");
    const [serviceAddress2, setServiceAddress2] = useState("");
    const [mailingAddress1, setMailingAddress1] = useState("");
    const [mailingAddress2, setMailingAddress2] = useState("");
    const [poBoxAddress, setPoBoxAddress] = useState("");
    const [slideIn, setSlideIn] = useState(false);

    useEffect(() => {
        setSlideIn(false);
        const timer = setTimeout(() => {
            setSlideIn(true);
        }, 400);
        return () => clearTimeout(timer); // cleanup to avoid memory leaks
    }, [initiateActive, personalInfoActive, addressActive, productActive, subscriptionActive, deliveryActive, billingActive]);

    useEffect(() => {
        const loginRes = localStorage.getItem("userData");
        const parseLoginRes = JSON.parse(loginRes);

        api.get(`/api/billingModel/all?serviceProvider=${parseLoginRes?.company}`).then((res) => {
            let billingmodels = res?.data?.data;
            for (let i = 0; i < billingmodels.length; i++) {
                if (billingmodels[i].billingModel === "PREPAID") {
                    setCurrentBillingModel(billingmodels[i].billingModel);
                    setBillingModelSelected(billingmodels[i]._id);
                }
            }

            if (isPrepaidIncomplete && isPrepaidIncomplete.bankOfamericacustomer) {
                setProceed(true);
                setParentDetails(isPrepaidIncomplete);
                setInitiationData(isPrepaidIncomplete);
                setInitiateActive(false);
                setPersonalInfoActive(false);
                setPreviewScreenActive(true);
            } else if (isPrepaidIncomplete) {
                setProceed(true);
                setParentDetails(isPrepaidIncomplete);
                setInitiationData(isPrepaidIncomplete);
                setInitiateActive(false);
                setPersonalInfoActive(true);
            } else {
                setProceed(true);
            }
        });

        return () => {
            setIsPrepaidIncomplete("");
        };
    }, [isPrepaidIncomplete]); // add the dependencies here
    return (
        <div className={initiateActive ? "prepaidinitiate" : "prepaidOrderMain"} style={{ overflowX: "hidden", overflowY: "auto", position: "relative", borderRadius: "1.6rem", width: "98.8rem" }}>
            <div className={`${previewScreenActive ? "prepaidmainnone" : `prepaidmain ${slideIn ? "slide-in" : ""}`}`} style={{ minHeight: initiateActive ? "42.2rem" : "auto" }}>
                <>
                    {/* {!initiateActive && !previewScreenActive ? (
                        <div className="flex flex-wrap w-full justify-content-center">
                            <h1 className=" heading" style={{ fontSize: "2.4rem" }}>
                                ENROLLMENT ID : {parentDetails?.enrollmentId}
                            </h1>
                        </div>
                    ) : (
                        ""
                    )} */}
                    {!initiateActive && !previewScreenActive ? (
                        <div className="flex flex-wrap w-full justify-content-center" style={{ marginTop: "3.2rem" }}>
                            <div className="flex flex-row justify-content-left steppermain" style={{ gap: "1.6rem" }}>
                                <div>
                                    <p className={personalInfoActive || addressActive || productActive || subscriptionActive || deliveryActive || billingActive ? "pactive  flex text-center " : "flex  text-center "}>
                                        <span className={personalInfoActive ? "personalinfoactive" : ""}>
                                            Personal Info <i className="pi pi-angle-right"></i>
                                        </span>
                                    </p>
                                </div>
                                <div>
                                    <p className={addressActive || productActive || subscriptionActive || deliveryActive || billingActive ? "pactive  flex  text-center  " : "flex  text-center   pinactive"}>
                                        <span className={addressActive ? "addressactive" : ""}>
                                            Address <i className="pi pi-angle-right"></i>
                                        </span>
                                    </p>
                                </div>
                                <div>
                                    <p className={productActive || subscriptionActive || deliveryActive || billingActive ? "pactive  flex  text-center  " : "flex  text-center   pinactive"}>
                                        <span className={productActive ? "productactive" : ""}>
                                            Product <i className="pi pi-angle-right"></i>
                                        </span>
                                    </p>
                                </div>
                                <div>
                                    <p className={subscriptionActive || deliveryActive || billingActive ? "pactive  flex  text-center " : "flex  text-center  pinactive"}>
                                        <span className={subscriptionActive ? "subscriptionactive" : ""}>
                                            Subscription <i className="pi pi-angle-right"></i>
                                        </span>
                                    </p>
                                </div>
                                <div>
                                    <p className={deliveryActive || billingActive ? "pactive  flex  text-center  " : "flex  text-center   pinactive"}>
                                        <span className={deliveryActive ? "deliveryactive" : ""}>
                                            Delivery <i className="pi pi-angle-right"></i>
                                        </span>
                                    </p>
                                </div>
                                <div>
                                    <p className={billingActive ? "pactive flex  text-center  " : "flex   text-center   pinactive billing"}>
                                        <span className={billingActive ? "billingactive" : ""}>
                                            Billing <i className="pi pi-angle-right"></i>
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    ) : null}
                </>
                {initiateActive ? (
                    <InitiateEnrollment
                        setParentDetails={setParentDetails}
                        setInitiationData={setInitiationData}
                        parentDetails={parentDetails}
                        setCurrentBillingModel={setCurrentBillingModel}
                        billingModelSelected={billingModelSelected}
                        setBillingModelSelected={setBillingModelSelected}
                        setPersonalInfoActive={setPersonalInfoActive}
                        setInitiateActive={setInitiateActive}
                        setZipVerified={setZipVerified}
                        zipVerified={zipVerified}
                    />
                ) : personalInfoActive ? (
                    <PersonalInfo
                        isPrepaidIncomplete={isPrepaidIncomplete}
                        parentCurrentContact={parentCurrentContact}
                        setParentDetails={setParentDetails}
                        initiationData={initiationData}
                        zipVerified={zipVerified}
                        currentBillingModel={currentBillingModel}
                        parentDetails={parentDetails}
                        setInitiateActive={setInitiateActive}
                        setPersonalInfoActive={setPersonalInfoActive}
                        setAddressActive={setAddressActive}
                    />
                ) : addressActive ? (
                    <Address
                        zipVerified={zipVerified}
                        isPrepaidIncomplete={isPrepaidIncomplete}
                        parentDetails={parentDetails}
                        initiationData={initiationData}
                        setParentDetails={setParentDetails}
                        setAddressActive={setAddressActive}
                        setPersonalInfoActive={setPersonalInfoActive}
                        setConfirmationActive={setConfirmationActive}
                        validateAddress={validateAddress}
                        setValidateAddress={setValidateAddress}
                        storeValidAddress={storeValidAddress}
                        setStoreValidAddress={setStoreValidAddress}
                        serviceAddress1={serviceAddress1}
                        setServiceAddress1={setServiceAddress1}
                        serviceAddress2={serviceAddress2}
                        setServiceAddress2={setServiceAddress2}
                        mailingAddress1={mailingAddress1}
                        setMailingAddress1={setMailingAddress1}
                        mailingAddress2={mailingAddress2}
                        setMailingAddress2={setMailingAddress2}
                        poBoxAddress={poBoxAddress}
                        setPoBoxAddress={setPoBoxAddress}
                        incomplete={isPrepaidIncomplete ? true : false}
                        setPaymentScreenActive={setPaymentScreenActive}
                        setProductActive={setProductActive}
                    />
                ) : //         :
                //         confirmationActive ? (
                // <Agree
                //     setParentDetails={setParentDetails}
                //     setPaymentScreenActive={setPaymentScreenActive}
                //     parentDetails={parentDetails}
                //     initiationData={initiationData}
                //     setAddressActive={setAddressActive}
                //     setPreviewScreenActive={setPreviewScreenActive}
                //     isPrepaidIncomplete={isPrepaidIncomplete}
                //     setConfirmationActive={setConfirmationActive}
                // />
                //         )
                paymentScreenActive ? (
                    <PaymentScreen
                        currentAllPlans={currentAllPlans}
                        prospectCases={prospectCases}
                        setProspectCases={setProspectCases}
                        setCurrentAllBillingConfig={setCurrentAllBillingConfig}
                        setCurrentAllPlans={setCurrentAllPlans}
                        setAllPaymentInfo={setAllPaymentInfo}
                        setDataSendForInvoice={setDataSendForInvoice}
                        paymentScreenDetails={paymentScreenDetails}
                        paymentDone={paymentDone}
                        setPaymentDone={setPaymentDone}
                        initiationData={initiationData}
                        parentDetails={parentDetails}
                        setPaymentScreenActive={setPaymentScreenActive}
                        currentBillingModel={currentBillingModel}
                        // setConfirmationActive={setConfirmationActive}
                        inventoryTypeSelected={inventoryTypeSelected}
                        setInventoryTypeSelected={setInventoryTypeSelected}
                        inventoryIdSelected={inventoryIdSelected}
                        setInventoryIdSelected={setInventoryIdSelected}
                        billingModelSelected={billingModelSelected}
                        setPreviewScreenActive={setPreviewScreenActive}
                        setAddressActive={setAddressActive}
                        setPaymentScreenDetails={setPaymentScreenDetails}
                        carrierVal={carrierVal}
                        setCarrierVal={setCarrierVal}
                        servicesVal={servicesVal}
                        setServicesVal={setServicesVal}
                        deliveryVal={deliveryVal}
                        setDeliveryVal={setDeliveryVal}
                        checkLabelVal={checkLabelVal}
                        setCheckLabelVal={setCheckLabelVal}
                        checked={checked}
                        setChecked={setChecked}
                        generate={generate}
                        setGenerate={setGenerate}
                        currentPlanSelect={currentPlanSelect}
                        setCurrentPlanSelect={setCurrentPlanSelect}
                        current={current}
                        additionalsDiscounts={additionalsDiscounts}
                        setAdditionalDiscounts={setAdditionalDiscounts}
                        setCurrentSelect={setCurrentSelect}
                        amount={amount}
                        setAmount={setAmount}
                        setSubscriptionActive={setSubscriptionActive}
                        setDeliveryActive={setDeliveryActive}
                        setBillingActive={setBillingActive}
                        payingAmount={payingAmount}
                        setPayingAmount={setPayingAmount}
                        currentScreen={currentScreen}
                        setCurrentScreen={setCurrentScreen}
                        productActive={productActive}
                        setProductActive={setProductActive}
                        setInventoryData={setInventoryData}
                        toast={toast}
                        // current={current}
                        // setCurrentSelect={setCurrentSelect}
                        // currentPlanSelect={currentPlanSelect}
                        // setCurrentPlanSelect={setCurrentPlanSelect}
                    />
                ) : previewScreenActive ? (
                    <Preview
                        paymentDone={paymentDone}
                        setCurrentScreen={setCurrentScreen}
                        prospectCases={prospectCases}
                        isPrepaidIncomplete={isPrepaidIncomplete}
                        setConfirmationActive={setConfirmationActive}
                        currentAllBillingConfig={currentAllBillingConfig}
                        currentAllPlans={currentAllPlans}
                        setPreviewScreenActive={setPreviewScreenActive}
                        setPaymentScreenActive={setPaymentScreenActive}
                        initiationData={initiationData}
                        parentDetails={parentDetails}
                        paymentScreenDetails={paymentScreenDetails}
                        setParentDetails={setParentDetails}
                        currentPlanSelect={currentPlanSelect}
                        inventoryData={inventoryData}
                        inventoryIdSelected={inventoryIdSelected}
                        payingAmount={payingAmount}
                        currentBillingModel={currentBillingModel}
                    />
                ) : (
                    ""
                )}
                <Toast ref={toast} />
            </div>
        </div>
    );
}
