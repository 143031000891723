import React, { useEffect, useRef, useState } from "react";
import "./Main_Add_Service.css";
import InitiateEnrollment from "./components/Initiation/InitiationMain";
import PersonalInfo from "./components/PersonalInfo/PersonalInfo";
import Address from "./components/Address/Address";
import Agree from "./components/Confirmation/Confirmation";
import PaymentScreen from "./components/PaymentScreen/PaymentScreen";
import Axios from "axios";
import Preview from "./components/Preview/Preview";
import { api } from "../../../../../utility/AxiosInceptor";
import { Toast } from "primereact/toast";
import { ProgressSpinner } from "primereact/progressspinner";
export default function AddServiceMain({ isLinkedAccountIncomplete, setIsLinkedAccountIncomplete }) {
    let toast = useRef();
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const [initiateActive, setInitiateActive] = useState(true);
    const [personalInfoActive, setPersonalInfoActive] = useState(false);

    const [currentScreen, setCurrentScreen] = useState(1);
    const [inventoryIdSelected, setInventoryIdSelected] = useState(isLinkedAccountIncomplete !== "" ? (isLinkedAccountIncomplete?.inventoryId ? isLinkedAccountIncomplete?.inventoryId?._id : "") : "");
    const [addressActive, setAddressActive] = useState(false);
    let selectedId = JSON.parse(localStorage.getItem("selectedId"));
    const [confirmationActive, setConfirmationActive] = useState(false);
    const [checked, setChecked] = useState(isLinkedAccountIncomplete !== "" ? isLinkedAccountIncomplete?.shippingLabelDiscount : "");
    const [isGenerateInvoiceWithParent, setIsGenerateInvoiceWithParent] = useState(isLinkedAccountIncomplete !== "" && isLinkedAccountIncomplete?.generateWithParent ? isLinkedAccountIncomplete?.generateWithParent : false);
    const [paymentScreenActive, setPaymentScreenActive] = useState(false);
    const [previewScreenActive, setPreviewScreenActive] = useState(false);
    const [billingModelSelected, setBillingModelSelected] = useState("");
    const [currentBillingModel, setCurrentBillingModel] = useState();
    const [parentDetails, setParentDetails] = useState();
    const [additionalsDiscounts, setAdditionalDiscounts] = useState({
        additional: isLinkedAccountIncomplete !== "" ? (isLinkedAccountIncomplete?.selectedAdditional ? isLinkedAccountIncomplete?.selectedAdditional : []) : [],
        setted: isLinkedAccountIncomplete !== "" ? (isLinkedAccountIncomplete?.selectedAdditional ? true : false) : false,
        discounts: isLinkedAccountIncomplete !== "" ? (isLinkedAccountIncomplete?.selectedDiscount ? isLinkedAccountIncomplete?.selectedDiscount : []) : [],
    });

    const [parentCurrentContact, setParentCurrentContact] = useState();
    const [initiationData, setInitiationData] = useState();
    const [paymentScreenDetails, setPaymentScreenDetails] = useState({});
    const [currentAllPlans, setCurrentAllPlans] = useState();
    const [currentAllBillingConfig, setCurrentAllBillingConfig] = useState("");
    const [paymentDone, setPaymentDone] = useState(false);
    const [dataSendForInvoice, setDataSendForInvoice] = useState("");
    const [allpaymentinfo, setAllPaymentInfo] = useState("");
    const [proceed, setProceed] = useState(false);
    const [fetched, setFetched] = useState(false);
    const [carrierVal, setCarrierVal] = useState(isLinkedAccountIncomplete !== "" ? isLinkedAccountIncomplete?.carrierVal : "");
    const [servicesVal, setServicesVal] = useState(isLinkedAccountIncomplete !== "" ? isLinkedAccountIncomplete?.servicesVal : "");
    const [deliveryVal, setDeliveryVal] = useState(isLinkedAccountIncomplete !== "" ? isLinkedAccountIncomplete?.deliveryVal : "");
    const [inventoryTypeSelected, setInventoryTypeSelected] = useState(isLinkedAccountIncomplete !== "" ? isLinkedAccountIncomplete?.inventoryId?.inventoryType : "");
    const [checkLabelVal, setCheckLabelVal] = useState(isLinkedAccountIncomplete !== "" ? isLinkedAccountIncomplete?.labelSubmissionval : "");
    const [generate, setGenerate] = useState(isLinkedAccountIncomplete !== "" ? isLinkedAccountIncomplete?.shippingLabelDiscount : "");
    const [prospectCases, setProspectCases] = useState({
        prospectWithInvoice: isLinkedAccountIncomplete !== "" ? (isLinkedAccountIncomplete?.isProspect ? isLinkedAccountIncomplete?.isProspect : false) : false,
        prospectWithoutInvoice: isLinkedAccountIncomplete !== "" ? (isLinkedAccountIncomplete?.isWithoutProspect ? isLinkedAccountIncomplete?.isWithoutProspect : false) : false,
    });
    const [amount, setAmount] = useState(0);
    const [subscriptionActive, setSubscriptionActive] = useState(false);
    const [deliveryActive, setDeliveryActive] = useState(false);
    const [billingActive, setBillingActive] = useState(false);
    const [productActive, setProductActive] = useState(false);
    const [inventoryData, setInventoryData] = useState();
    const [currentPlanSelect, setCurrentPlanSelect] = useState(isLinkedAccountIncomplete !== "" ? (isLinkedAccountIncomplete?.plan ? isLinkedAccountIncomplete?.plan?._id : "") : "");
    const [current, setCurrentSelect] = useState(isLinkedAccountIncomplete !== "" ? (isLinkedAccountIncomplete?.inventoryId ? `${isLinkedAccountIncomplete?.inventoryId?.make + " - " + isLinkedAccountIncomplete?.inventoryId?.model}` : "") : "");

    const [validateAddress, setValidateAddress] = useState(false);
    const [storeValidAddress, setStoreValidAddress] = useState("");
    const [serviceAddress1, setServiceAddress1] = useState("");
    const [serviceAddress2, setServiceAddress2] = useState("");
    const [mailingAddress1, setMailingAddress1] = useState("");
    const [mailingAddress2, setMailingAddress2] = useState("");
    const [poBoxAddress, setPoBoxAddress] = useState("");
    const [payingAmount, setPayingAmount] = useState(isLinkedAccountIncomplete !== "" && isLinkedAccountIncomplete?.payingAmount ? isLinkedAccountIncomplete?.payingAmount : "");
    const [slideIn, setSlideIn] = useState(false);

    useEffect(() => {
        setSlideIn(false);
        const timer = setTimeout(() => {
            setSlideIn(true);
        }, 400);
        return () => clearTimeout(timer); // cleanup to avoid memory leaks
    }, [initiateActive, personalInfoActive, addressActive, productActive, subscriptionActive, deliveryActive, billingActive]);
    useEffect(() => {
        if (isLinkedAccountIncomplete === "") {
            api.get(`/api/user/userDetails?userId=${selectedId}`)
                .then((res) => {
                    setParentDetails(res?.data?.data);
                    setFetched(true);
                    setParentCurrentContact(res?.data?.data?.contact);
                    if (res?.data?.data?.relationWithParent !== undefined) {
                        setProceed(false);

                        toast.current.show({ severity: "error", summary: "Info", detail: "You Can't Create Child Account Of A Child Account" });
                    } else {
                        setProceed(true);
                    }
                })
                .catch((err) => {});
        } else {
            const loginRes = localStorage.getItem("userData");
            const parseLoginRes = JSON.parse(loginRes);
            api.get(`/api/billingModel/all?serviceProvider=${parseLoginRes?.company}`)
                .then((res) => {
                    let billingmodels = res?.data?.data;
                    for (let i = 0; i < billingmodels.length; i++) {
                        if (billingmodels[i].billingModel === "PREPAID" && isLinkedAccountIncomplete?.accountType === "Prepaid") {
                            setCurrentBillingModel(billingmodels[i].billingModel);
                            setBillingModelSelected(billingmodels[i]._id);
                        } else if (billingmodels[i].billingModel === "POSTPAID" && isLinkedAccountIncomplete?.accountType === "Postpaid") {
                            setCurrentBillingModel(billingmodels[i].billingModel);
                            setBillingModelSelected(billingmodels[i]._id);
                        }
                    }

                    setProceed(true);
                    setParentDetails(isLinkedAccountIncomplete);
                    setInitiationData(isLinkedAccountIncomplete);
                    setFetched(true);
                    setInitiateActive(false);
                    setPersonalInfoActive(true);
                })
                .catch((err) => {});
        }
        return () => {
            setIsLinkedAccountIncomplete("");
        };
    }, []);
    return (
        <div className={initiateActive ? "prepaidinitiate" : "prepaidOrderMain"} style={{ overflowX: "hidden", overflowY: "auto", position: "relative", borderRadius: "8px", width: "988px" }}>
            {!fetched && !proceed ? (
                !fetched ? (
                    <div>
                        <div style={{ marginTop: "40px" }} className="mt-4 flex justify-content-center">
                            <ProgressSpinner />
                        </div>
                    </div>
                ) : (
                    <h1>Can't Create Child Account Of A Child Account</h1>
                )
            ) : fetched && proceed ? (
                <div className={`${previewScreenActive ? "prepaidmainnone" : `prepaidmain ${slideIn ? "slide-in" : ""}`}`}>
                    {!initiateActive && !previewScreenActive ? (
                        <div className="flex flex-wrap w-full justify-content-center" style={{ marginBottom: "2rem" }}>
                            <div className="flex flex-row justify-content-left steppermain mt-4" style={{ gap: "2%" }}>
                                <div>
                                    <p className={personalInfoActive || addressActive || productActive || subscriptionActive || deliveryActive || billingActive ? "pactive  flex text-center mt-4 " : "flex  text-center mt-4 "}>
                                        <span className={personalInfoActive ? "personalinfoactive" : ""}>
                                            Personal Info <i className="pi pi-angle-right ml-1 mt-2"></i>
                                        </span>
                                    </p>
                                </div>
                                <div>
                                    <p className={addressActive || productActive || subscriptionActive || deliveryActive || billingActive ? "pactive  flex  text-center mt-4  " : "flex  text-center mt-4   pinactive"}>
                                        <span className={addressActive ? "addressactive" : ""}>
                                            Address <i className="pi pi-angle-right ml-1 mt-2"></i>
                                        </span>
                                    </p>
                                </div>
                                <div>
                                    <p className={productActive || subscriptionActive || deliveryActive || billingActive ? "pactive  flex  text-center mt-4  " : "flex  text-center mt-4   pinactive"}>
                                        <span className={productActive ? "productactive" : ""}>
                                            Product <i className="pi pi-angle-right ml-1 mt-2"></i>
                                        </span>
                                    </p>
                                </div>
                                <div>
                                    <p className={subscriptionActive || deliveryActive || billingActive ? "pactive  flex  text-center mt-4 " : "flex  text-center mt-4  pinactive"}>
                                        <span className={subscriptionActive ? "subscriptionactive" : ""}>
                                            Subscription <i className="pi pi-angle-right ml-1 mt-2"></i>
                                        </span>
                                    </p>
                                </div>
                                <div>
                                    <p className={deliveryActive || billingActive ? "pactive  flex  text-center mt-4  " : "flex  text-center mt-4   pinactive"}>
                                        <span className={deliveryActive ? "deliveryactive" : ""}>
                                            Delivery <i className="pi pi-angle-right ml-1 mt-2"></i>
                                        </span>
                                    </p>
                                </div>
                                <div>
                                    <p className={billingActive ? "pactive flex  text-center mt-4  " : "flex   text-center mt-4   pinactive billing"}>
                                        <span className={billingActive ? "billingactive" : ""}>
                                            Billing <i className="pi pi-angle-right ml-1 mt-2"></i>
                                        </span>
                                    </p>
                                </div>
                                <div>
                                    <p className="text-center mt-4  preview  " style={{ fontSize: "12px", fontWeight: "500", color: "#72728F    " }}>
                                        Preview <i className="pi pi-angle-right ml-1 mt-2"></i>
                                    </p>
                                </div>
                            </div>
                        </div>
                    ) : null}
                    {initiateActive ? (
                        <InitiateEnrollment
                            setParentDetails={setParentDetails}
                            setInitiationData={setInitiationData}
                            parentDetails={parentDetails}
                            setCurrentBillingModel={setCurrentBillingModel}
                            billingModelSelected={billingModelSelected}
                            setBillingModelSelected={setBillingModelSelected}
                            setPersonalInfoActive={setPersonalInfoActive}
                            setInitiateActive={setInitiateActive}
                        />
                    ) : personalInfoActive ? (
                        <PersonalInfo
                            isLinkedAccountIncomplete={isLinkedAccountIncomplete}
                            parentCurrentContact={parentCurrentContact}
                            setParentDetails={setParentDetails}
                            initiationData={initiationData}
                            currentBillingModel={currentBillingModel}
                            parentDetails={parentDetails}
                            setInitiateActive={setInitiateActive}
                            setPersonalInfoActive={setPersonalInfoActive}
                            setAddressActive={setAddressActive}
                        />
                    ) : addressActive ? (
                        <Address
                            isLinkedAccountIncomplete={isLinkedAccountIncomplete}
                            parentDetails={parentDetails}
                            initiationData={initiationData}
                            setParentDetails={setParentDetails}
                            setAddressActive={setAddressActive}
                            setPersonalInfoActive={setPersonalInfoActive}
                            setConfirmationActive={setConfirmationActive}
                            validateAddress={validateAddress}
                            setValidateAddress={setValidateAddress}
                            storeValidAddress={storeValidAddress}
                            setStoreValidAddress={setStoreValidAddress}
                            serviceAddress1={serviceAddress1}
                            setServiceAddress1={setServiceAddress1}
                            serviceAddress2={serviceAddress2}
                            setServiceAddress2={setServiceAddress2}
                            mailingAddress1={mailingAddress1}
                            setMailingAddress1={setMailingAddress1}
                            mailingAddress2={mailingAddress2}
                            setMailingAddress2={setMailingAddress2}
                            poBoxAddress={poBoxAddress}
                            setPoBoxAddress={setPoBoxAddress}
                            incomplete={isLinkedAccountIncomplete ? true : false}
                            setPaymentScreenActive={setPaymentScreenActive}
                            setProductActive={setProductActive}
                        />
                    ) : // ) : confirmationActive ? (
                    //     <Agree
                    //         setParentDetails={setParentDetails}
                    //         setPaymentScreenActive={setPaymentScreenActive}
                    //         parentDetails={parentDetails}
                    //         initiationData={initiationData}
                    //         setAddressActive={setAddressActive}
                    //         setPreviewScreenActive={setPreviewScreenActive}
                    //         isLinkedAccountIncomplete={isLinkedAccountIncomplete}
                    //         setConfirmationActive={setConfirmationActive}
                    //     />
                    paymentScreenActive ? (
                        <PaymentScreen
                            currentScreen={currentScreen}
                            setCurrentScreen={setCurrentScreen}
                            payingAmount={payingAmount}
                            toast={toast}
                            setPayingAmount={setPayingAmount}
                            inventoryTypeSelected={inventoryTypeSelected}
                            setInventoryTypeSelected={setInventoryTypeSelected}
                            currentAllPlans={currentAllPlans}
                            prospectCases={prospectCases}
                            setProspectCases={setProspectCases}
                            setCurrentAllBillingConfig={setCurrentAllBillingConfig}
                            setCurrentAllPlans={setCurrentAllPlans}
                            setAllPaymentInfo={setAllPaymentInfo}
                            setDataSendForInvoice={setDataSendForInvoice}
                            paymentScreenDetails={paymentScreenDetails}
                            paymentDone={paymentDone}
                            isLinkedAccountIncomplete={isLinkedAccountIncomplete}
                            setPaymentDone={setPaymentDone}
                            initiationData={initiationData}
                            parentDetails={parentDetails}
                            setPaymentScreenActive={setPaymentScreenActive}
                            currentBillingModel={currentBillingModel}
                            setConfirmationActive={setConfirmationActive}
                            billingModelSelected={billingModelSelected}
                            setPreviewScreenActive={setPreviewScreenActive}
                            setPaymentScreenDetails={setPaymentScreenDetails}
                            carrierVal={carrierVal}
                            setCarrierVal={setCarrierVal}
                            servicesVal={servicesVal}
                            setServicesVal={setServicesVal}
                            deliveryVal={deliveryVal}
                            setDeliveryVal={setDeliveryVal}
                            checkLabelVal={checkLabelVal}
                            setCheckLabelVal={setCheckLabelVal}
                            checked={checked}
                            setChecked={setChecked}
                            generate={generate}
                            setGenerate={setGenerate}
                            currentPlanSelect={currentPlanSelect}
                            setCurrentPlanSelect={setCurrentPlanSelect}
                            current={current}
                            additionalsDiscounts={additionalsDiscounts}
                            setAdditionalDiscounts={setAdditionalDiscounts}
                            setCurrentSelect={setCurrentSelect}
                            amount={amount}
                            setAmount={setAmount}
                            inventoryIdSelected={inventoryIdSelected}
                            setInventoryIdSelected={setInventoryIdSelected}
                            isGenerateInvoiceWithParent={isGenerateInvoiceWithParent}
                            setIsGenerateInvoiceWithParent={setIsGenerateInvoiceWithParent}
                            setProductActive={setProductActive}
                            setInventoryData={setInventoryData}
                            // product={product}
                            // setProduct={setProduct}
                            // current={current}
                            // setCurrentSelect={setCurrentSelect}
                            // currentPlanSelect={currentPlanSelect}
                            // setCurrentPlanSelect={setCurrentPlanSelect}

                            setSubscriptionActive={setSubscriptionActive}
                            setDeliveryActive={setDeliveryActive}
                            setBillingActive={setBillingActive}
                        />
                    ) : previewScreenActive ? (
                        <Preview
                            paymentDone={paymentDone}
                            setCurrentScreen={setCurrentScreen}
                            prospectCases={prospectCases}
                            isLinkedAccountIncomplete={isLinkedAccountIncomplete}
                            setConfirmationActive={setConfirmationActive}
                            currentAllBillingConfig={currentAllBillingConfig}
                            currentAllPlans={currentAllPlans}
                            setPreviewScreenActive={setPreviewScreenActive}
                            setPaymentScreenActive={setPaymentScreenActive}
                            initiationData={initiationData}
                            parentDetails={parentDetails}
                            paymentScreenDetails={paymentScreenDetails}
                            setParentDetails={setParentDetails}
                            currentPlanSelect={currentPlanSelect}
                            inventoryData={inventoryData}
                            inventoryIdSelected={inventoryIdSelected}
                            payingAmount={payingAmount}
                            currentBillingModel={currentBillingModel}
                        />
                    ) : (
                        ""
                    )}
                </div>
            ) : (
                <p className="text-center p-error">Can't Create Child Of A Child Component</p>
            )}
            <Toast ref={toast} />
        </div>
    );
}
