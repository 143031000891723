import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Axios from "axios";
import { api } from "../../../../../utility/AxiosInceptor";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FilterMatchMode } from "primereact/api";
import { InputText } from "primereact/inputtext";
import { Backbtn, Continue, Export, ExportHover, Filter, Import, ImportHover, Nextbtn, Sortby } from "../../../../../utility";
import "./incomplete.css";
import CommonLoaderBlue from "../../billingModel/billingmodel_configurations/Loaders/components/Loader/LoaderBlue";
const BASE_URL = process.env.REACT_APP_BASE_URL;
const InComplete_Enrollments = ({ isPostpaidIncomplete, setIsPostpaidIncomplete, isLinkedAccountIncomplete, setIsLinkedAccountIncomplete }) => {
    // State For Select Row
    const [selectedEnrollments, setSelectedEnrollments] = useState([]);
    const [rowClick, setRowClick] = useState(true);

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        enrollment: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        createdAt: { value: null, matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO },
        createdTo: { value: null, matchMode: FilterMatchMode.LESS_THAN_OR_EQUAL_TO },
    });
    const [createDateToFilterValue, setCreatedDateToFilterValue] = useState("");
    const [enrollmentIdFilterValue, setEnrollmentIdFilterValue] = useState("");
    const [createDateFilterValue, setCreatedDateFilterValue] = useState("");
    const [globalFilterValue, setGlobalFilterValue] = useState("");
    const [fromIncomplete, setFromIncomplete] = useState(null);
    const [hover, setHover] = useState(false);
    const [hoverLeave, setHoverLeave] = useState(false);
    const [hoverAfter, setHoverAfter] = useState(false);
    const [hoverLeaveAfter, setHoverLeaveAfter] = useState(false);
    const [allInCompletedEnrollments, setAllInCompletedEnrollments] = useState([]);

    const [customerSearchName, setCustomerSearchName] = useState("");
    const [totalSearchResult, setTotalSearchResult] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [enrollmentFrom, setEnrollmentFrom] = useState(1);
    const [enrollmentTo, setEnrollmentTo] = useState(10);
    const [completeListEnrollment, setCompleteListEnrollment] = useState([]);
    const [selectedNumberToShow, setSelectedNumberToShow] = useState(10);
    const [totalIncaseSearch, setTotalIncaseSearch] = useState(0);
    const [totalPage, setTotalPage] = useState(0);

    const onGlobalFilterValueChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters["global"].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };
    const onNameDateEnrollmentIdValueFilter = (e, field) => {
        const value = e.target.value;
        let _filters = { ...filters };
        if (field === "enrollment") {
            _filters["enrollment"].value = value;
            setFilters(_filters);
            setEnrollmentIdFilterValue(value);
        } else if (field === "createdTo") {
            setCreatedDateToFilterValue(e.value);
            const updatedDate = new Date(e.value);
            updatedDate.setDate(updatedDate.getDate() + 1);
            _filters["createdTo"].value = new Date(updatedDate).toISOString();
            setFilters(_filters);
        } else {
            setCreatedDateFilterValue(e.value);
            _filters["createdAt"].value = new Date(e.value).toISOString();
            setFilters(_filters);
        }
    };

    const [isLoading, setIsLoading] = useState(false);
    const [isButtonLoading, setisButtonLoading] = useState(false);
    const [selectedEnrollmentId, setSelectedEnrollmentId] = useState();

    const [isCreate, setIsCreate] = useState(false);
    const [isManage, setIsManage] = useState(false);

    const location = useLocation();
    const currentPath = location?.pathname;
    const navigate = useNavigate();

    const loginRes = localStorage.getItem("userData");
    const parseLoginRes = JSON.parse(loginRes);

    const actionBasedChecks = () => {
        const loginPerms = localStorage.getItem("permissions");
        const parsedLoginPerms = JSON.parse(loginPerms);

        const isCreate = parsedLoginPerms.some((node) => node?.subModule.some((subNode) => subNode?.route === currentPath && subNode?.actions.some((action) => action?.name === "create")));
        setIsCreate(isCreate);

        const isManage = parsedLoginPerms.some((node) => node?.subModule.some((subNode) => subNode?.route === currentPath && subNode?.actions.some((action) => action?.name === "manage")));
        setIsManage(isManage);
    };

    useEffect(() => {
        actionBasedChecks();
        setFromIncomplete(false);
    }, []);

    const getAllInCompletedEnrollments = async () => {
        setIsLoading(true);
        try {
            const res = await api.get(`/api/user/inCompleteEnrollmentUser?userId=${parseLoginRes?._id}&accountType=Postpaid`);
            if (res?.status === 200 || res?.status === 201) {
                const data = res?.data?.data.map((item) => ({
                    ...item,
                    enrollment: item.isSelfEnrollment ? "Self Enrollments" : "Enrollment",
                    name: `${item?.firstName ? item?.firstName.toUpperCase() : ""} ${item?.lastName ? item?.lastName.toUpperCase() : ""}`,
                    createdDate: new Date(item.createdAt)
                        .toLocaleDateString("en-US", {
                            month: "2-digit",
                            day: "2-digit",
                            year: "numeric",
                        })
                        .replace(/\//g, "-"),
                    createdTo: item.createdAt,
                }));
                setAllInCompletedEnrollments(data.slice(0, 10)); // Initially display first 10 records
                setCompleteListEnrollment(data); // Full list for manual pagination
                setTotalPage(Math.ceil(data.length / 10)); // Total number of pages

                // Sorting by activatedAt (descending order)
                data.sort((a, b) => {
                    const dateComparison = new Date(b.activatedAt) - new Date(a.activatedAt);
                    if (dateComparison !== 0) {
                        return dateComparison;
                    }
                    return new Date(b.activatedAt).getTime() - new Date(a.activatedAt).getTime();
                });

                setIsLoading(false);
            }
        } catch (error) {
            toast.error(`Error Fetching Enrollment data: ${error?.response?.data?.msg}`);
            setIsLoading(false);
        }
    };
    useEffect(() => {
        getAllInCompletedEnrollments();
    }, []);

    const viewRow = async (rowData) => {
        setisButtonLoading(true);
        const _id = rowData._id;
        setSelectedEnrollmentId(_id);
        try {
            const response = await api.get(`/api/user/userDetails?userId=${_id}`);

            if (response?.status === 201 || response?.status === 200) {
                if (rowData?.linkedAccount) {
                    setIsLinkedAccountIncomplete(response?.data?.data);
                    navigate("/add-service");
                } else {
                    setIsPostpaidIncomplete(response?.data?.data);
                    navigate("/postpaid-newenrollment");
                }
                setisButtonLoading(false);
            }
        } catch (error) {
            toast.error(error?.response?.data?.msg);
            setisButtonLoading(false);
        }
        setisButtonLoading(false);
    };
    const handleEnrollmentIdClick = (rowData) => {
        navigate("/customer-profile", { state: { selectedId: rowData._id } });
        localStorage.setItem("selectedId", JSON.stringify(rowData._id));
    };

    const actionTemplate = (rowData) => {
        return (
            <div
                style={{ marginLeft: "-5rem" }}
                className="flex flex-wrap justify-content-center tablecontinuebtn"
                onClick={(e) => {
                    if (!isButtonLoading) {
                        e.stopPropagation();
                        viewRow(rowData);
                    }
                }}
                text
                raised
                disabled={isButtonLoading || !isCreate}
            >
                {selectedEnrollmentId === rowData?._id ? <CommonLoaderBlue customSize={10} /> : <Continue />}
            </div>
        );
    };

    return (
        <div className="postpaidincomplete">
            <div className="flex w-full flex-column justify-content-start h-4" style={{ marginBottom: "1.5rem" }}>
                <h3 className="heading">In-Complete Enrollments</h3>
                <div className="flex flex-wrap justify-content-between mt-2 w-full">
                    <div className="flex flex-wrap justify-content-between w-full ">
                        <div className="flex">
                            <InputText
                                className="searchbar"
                                value={customerSearchName}
                                onChange={(e) => {
                                    setCustomerSearchName(e.target.value);
                                    setCurrentPage(1);

                                    if (e.target.value.length > 0) {
                                        let searchResult = completeListEnrollment.filter((item) => item.name.toLowerCase().includes(e.target.value.toLowerCase()));

                                        setAllInCompletedEnrollments(searchResult);
                                        setTotalIncaseSearch(searchResult.length);
                                        setTotalSearchResult(searchResult);
                                        setEnrollmentFrom(1);
                                        setEnrollmentTo(searchResult.length > 10 ? 10 : searchResult.length);
                                    } else {
                                        setTotalSearchResult([]);
                                        setAllInCompletedEnrollments(completeListEnrollment.slice(0, 10));
                                        setEnrollmentFrom(1);
                                        setEnrollmentTo(completeListEnrollment.length > 10 ? 10 : completeListEnrollment.length);
                                    }
                                }}
                                placeholder="Search Enrollment"
                            />
                        </div>
                        <div className="flex">
                            <button
                                onMouseEnter={() => {
                                    setHover(true);
                                    setHoverLeave(false);
                                }}
                                onMouseLeave={() => {
                                    setHover(false);
                                    setHoverLeave(true);
                                }}
                                className="importbtn"
                                style={{ backgroundColor: "#FFFFFF33" }}
                            >
                                <div className={hover ? "importexternal" : hoverLeave ? "importexternalleave" : ""}></div>
                                <span className="flex w-full justify-content-center align-items-center">
                                    {hover ? (
                                        <span style={{ transition: "transform 300ms ease-in-out", transform: "rotateY(160deg)" }}>
                                            <ImportHover />
                                        </span>
                                    ) : (
                                        <span style={{ transition: "transform 300ms ease-in-out", transform: "rotateY(0deg)" }}>
                                            <Import />
                                        </span>
                                    )}
                                    &nbsp; Import
                                </span>
                            </button>
                            <button
                                onMouseEnter={() => {
                                    setHoverAfter(true);
                                    setHoverLeaveAfter(false);
                                }}
                                onMouseLeave={() => {
                                    setHoverAfter(false);
                                    setHoverLeaveAfter(true);
                                }}
                                className="exportbtn cursor-pointer"
                                style={{ backgroundColor: "#FFFFFF33" }}
                            >
                                <div className={hoverAfter ? "exportexternal" : hoverLeaveAfter ? "exportexternalleave" : ""}></div>
                                <span className="flex w-full justify-content-center align-items-center">
                                    {hoverAfter ? (
                                        <span style={{ transition: "transform 300ms ease-in-out", transform: "rotateY(160deg)" }}>
                                            <ExportHover />
                                        </span>
                                    ) : (
                                        <span style={{ transition: "transform 300ms ease-in-out", transform: "rotateY(0deg)" }}>
                                            <Export />
                                        </span>
                                    )}
                                    &nbsp; Export
                                </span>
                            </button>
                            <button className="sortbtn" style={{ backgroundColor: "#FFFFFF33" }}>
                                <span>
                                    <Sortby />
                                </span>
                                &nbsp; Sort by
                            </button>
                            <button className="filterbtn" style={{ backgroundColor: "#FFFFFF33" }}>
                                <span>
                                    <Filter />
                                </span>
                                &nbsp; Filter
                            </button>
                        </div>
                        <div className="flex">
                            <span style={{ fontSize: "1.4rem" }} className="flex align-items-center ">
                                Result per page
                                <button style={{ backgroundColor: "#FFFFFF33" }} className="resultbtn">
                                    {selectedNumberToShow}
                                </button>
                                &nbsp; {enrollmentFrom}-{enrollmentTo}&nbsp; of&nbsp; {customerSearchName.length > 0 ? totalIncaseSearch : completeListEnrollment.length}
                            </span>
                            &nbsp;
                            <button
                                onClick={() => {
                                    const itemsPerPage = 10;
                                    const calculateTotalPages = (data) => Math.ceil(data.length / itemsPerPage);

                                    const handlePageNavigation = (data, setDataFn) => {
                                        const totalPages = calculateTotalPages(data);
                                        if (currentPage > 1) {
                                            let startIdx, endIdx;

                                            if (totalPages - 1 === currentPage) {
                                                startIdx = (currentPage - 2) * itemsPerPage;
                                                endIdx = startIdx + itemsPerPage;
                                            } else if (totalPages === currentPage) {
                                                startIdx = data.length - (data.length % itemsPerPage) - itemsPerPage;
                                                endIdx = startIdx + itemsPerPage;
                                            } else {
                                                startIdx = (currentPage - 2) * itemsPerPage;
                                                endIdx = startIdx + itemsPerPage;
                                            }

                                            setDataFn(data.slice(startIdx, endIdx));
                                            setCurrentPage((prev) => prev - 1);
                                            setEnrollmentFrom(startIdx + 1);
                                            setEnrollmentTo(endIdx);
                                        }
                                    };

                                    if (customerSearchName.length > 0) {
                                        handlePageNavigation(totalSearchResult, setAllInCompletedEnrollments);
                                    } else {
                                        handlePageNavigation(completeListEnrollment, setAllInCompletedEnrollments);
                                    }
                                }}
                                className="cursor-pointer backbutton"
                                style={{ backgroundColor: "#FFFFFF33" }}
                            >
                                <Backbtn />
                            </button>
                            <button
                                style={{ backgroundColor: "#FFFFFF33" }}
                                onClick={() => {
                                    if (customerSearchName.length > 0) {
                                        let totalPages = Math.ceil(totalSearchResult.length / 10);
                                        if (totalPages > currentPage) {
                                            let enrollmentTillCurrentPage = currentPage * 10;
                                            if (totalPages - 1 === currentPage) {
                                                setAllInCompletedEnrollments(totalSearchResult.slice(enrollmentTillCurrentPage));

                                                setCurrentPage((prev) => prev + 1);
                                                setEnrollmentFrom(enrollmentTillCurrentPage + 1);
                                                setEnrollmentTo(totalSearchResult.length);
                                            } else {
                                                setAllInCompletedEnrollments(totalSearchResult.slice(enrollmentTillCurrentPage, enrollmentTillCurrentPage + 10));

                                                setCurrentPage((prev) => prev + 1);
                                                setEnrollmentFrom(enrollmentTillCurrentPage + 1);
                                                setEnrollmentTo(enrollmentTillCurrentPage + 10);
                                            }
                                        }
                                    } else {
                                        let totalPages = Math.ceil(completeListEnrollment.length / 10);
                                        if (totalPages > currentPage) {
                                            let enrollmentTillCurrentPage = currentPage * 10;
                                            if (totalPages - 1 === currentPage) {
                                                setAllInCompletedEnrollments(completeListEnrollment.slice(enrollmentTillCurrentPage));

                                                setCurrentPage((prev) => prev + 1);
                                                setEnrollmentFrom(enrollmentTillCurrentPage + 1);
                                                setEnrollmentTo(completeListEnrollment.length);
                                            } else {
                                                setAllInCompletedEnrollments(completeListEnrollment.slice(enrollmentTillCurrentPage, enrollmentTillCurrentPage + 10));

                                                setCurrentPage((prev) => prev + 1);
                                                setEnrollmentFrom(enrollmentTillCurrentPage + 1);
                                                setEnrollmentTo(enrollmentTillCurrentPage + 10);
                                            }
                                        }
                                    }
                                }}
                                className="cursor-pointer nextbutton"
                            >
                                <Nextbtn />
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <DataTable
                    selectionMode={rowClick ? null : "checkbox"}
                    selection={selectedEnrollments}
                    onSelectionChange={(e) => setSelectedEnrollments(e.value)}
                    size="small"
                    value={allInCompletedEnrollments}
                    stripedRows
                    resizableColumns
                    emptyMessage={
                        isLoading ? (
                            <div className="flex flex-wrap w-full justify-content-center  flex-row  mt-4 mb-4 ">
                                <CommonLoaderBlue customSize={16} />
                            </div>
                        ) : (
                            "Enrollment Not Found"
                        )
                    }
                    style={{ fontFamily: "Inter", fontSize: "14px" }}
                >
                    <Column selectionMode="multiple" headerStyle={{ width: "3rem" }}></Column>

                    <Column
                        header="Enrollment ID"
                        field="enrollmentId"
                        body={(rowData) => (
                            <button
                                style={{ fontSize: "1.272rem", fontWeight: "500", color: "#5E79FF", border: "none", backgroundColor: "transparent", cursor: "pointer", marginLeft: "-1rem" }}
                                onClick={() => {
                                    /*handleEnrollmentIdClick(rowData) */
                                }}
                            >
                                {rowData.enrollmentId}
                            </button>
                        )}
                    ></Column>
                    <Column header="Name" field="name"></Column>
                    <Column field="contact" header="Contact" />
                    <Column field="createdBy.name" header="Created By" />
                    <Column field="createdDate" header="Created At" />

                    <Column
                        header="Linked"
                        body={(rowData) => {
                            return <p className={rowData?.linkedAccount ? "yes" : "no"}>{rowData?.linkedAccount ? "Yes" : "No"}</p>;
                        }}
                    />
                    <Column header="Actions" body={actionTemplate}></Column>
                </DataTable>

                {/* {isLoading ? <ProgressSpinner className="flex flex-wrap justify-content-center flex-row mt-4" /> : null} */}
            </div>
        </div>
    );
};

export default InComplete_Enrollments;
