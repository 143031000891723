import React, { useEffect, useState } from "react";
import "./User.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { toast } from "react-toastify";
import { api } from "../../../../../../utility/AxiosInceptor";
import { Dialog } from "primereact/dialog";
import EditUser from "./Components/EditUser";
import { useNavigate } from "react-router-dom";
export default function Users() {
    let navigate = useNavigate();
    const [showEditUser, setShowEditUser] = useState(false);
    const [rowData, setRowData] = useState(null);
    const [userTable, setUserTable] = useState([
        {
            Id: "0001",
            name: "Zaka Ullah Khan",
            email: "mrzakaullah01@gmail.com",
            Role: "Owner",
            Enrollments: "0",
        },
        {
            Id: "0002",
            name: "Adeel Abid",
            email: "Adeelabid@gmail.com",
            Role: "Team Lead",
            Enrollments: "4000",
        },
        {
            Id: "0003",
            name: "Zaka Ullah Khan",
            email: "mrzakaullah01@gmail.com",
            Role: "Owner",
            Enrollments: "0",
        },
        {
            Id: "0004",
            name: "Zaka Ullah Khan",
            email: "mrzakaullah01@gmail.com",
            Role: "Owner",
            Enrollments: "0",
        },
        {
            Id: "0005",
            name: "Zaka Ullah Khan",
            email: "mrzakaullah01@gmail.com",
            Role: "Owner",
            Enrollments: "0",
        },
        {
            Id: "0006",
            name: "Zaka Ullah Khan",
            email: "mrzakaullah01@gmail.com",
            Role: "Owner",
            Enrollments: "0",
        },
    ]);
    const getAllUsers = async () => {
        try {
            const res = await api.get(`/api/web/user/all?company=${JSON.parse(localStorage.getItem("userData")).company}`);
            setUserTable(res?.data?.data.slice(0, 6) || []);

            // Pagination logic
            //setAllRolesData(res?.data?.data.slice(0, 15)); // Initially display first 15 records
            //setCompleteListEnrollment(res?.data?.data); // Full list for manual pagination
            //setTotalPage(Math.ceil(res?.data?.data.length / 15)); // Total number of pages
        } catch (error) {
            toast.error(`Error fetching module data: ${error?.response?.data?.msg}`);
        }
    };
    useEffect(() => {
        getAllUsers();
    }, []);
    return (
        <div className="main-user-conatainer">
            <div className="dashboard-users-header flex flex-wrap flex-row justify-content-between align-items-center">
                <h1>Users</h1>
                <div className="flex flex-wrap flex-row justify-content-start align-items-center">
                    <button
                        className="link-all-crm cursor-pointer"
                        onClick={() => {
                            navigate("/manage-user");
                        }}
                    >
                        VIEW ALL
                    </button>
                    <button
                        className="smallbutton cursor-pointer"
                        onClick={() => {
                            setShowEditUser(true);
                        }}
                    >
                        ADD NEW
                    </button>
                </div>
            </div>
            <DataTable headerClassName="header-user-table" className="user-table-main" value={userTable} tableStyle={{ minWidth: "50rem" }}>
                <Column
                    headerClassName="header-user-table "
                    className="column header-user-id"
                    field="_id"
                    body={(item) => {
                        return item?._id?.substring(item?._id?.length - 4, item?._id?.length)?.toUpperCase();
                    }}
                    header="Id"
                ></Column>
                <Column headerClassName="header-user-table" className="column" field="name" header="Name"></Column>
                <Column headerClassName="header-user-table" className="column" field="email" header="Email"></Column>
                <Column headerClassName="header-user-table" className="column" field="role.role" header="Role"></Column>
            </DataTable>
            <Dialog
                header="null"
                headerClassName="edituserdialogheader"
                visible={showEditUser}
                style={{ width: "80rem", height: "50rem" }}
                onHide={() => {
                    setShowEditUser((prev) => !prev);
                }}
            >
                <EditUser data={rowData} setShowEditUser={setShowEditUser} />
            </Dialog>
        </div>
    );
}
