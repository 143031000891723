import { api } from "../../../../../../../utility/AxiosInceptor";
import React, { useEffect, useState, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Axios from "axios";
import { useLocation, useNavigate } from "react-router-dom"; // Import ToastContainer and toast
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FilterMatchMode } from "primereact/api";
import { InputText } from "primereact/inputtext";
import { OverlayPanel } from "primereact/overlaypanel";
import { Button } from "primereact/button";
import DateRangeCalendar from "../all_enrollments.js/Calendar/Calendar-DropDown";
import { Status, Calendar, Continue, DownloadIcon, NextIcon, PrevIcon, EmptyEnrollment } from "../../../../../../../utility";
import "./incomplete.css";
import CommonLoaderBlue from "../../../../billingModel/billingmodel_configurations/Loaders/components/Loader/LoaderBlue";
const BASE_URL = process.env.REACT_APP_BASE_URL;
const PrepaidInCompleteEnrollments = ({ isPrepaidIncomplete, setIsPrepaidIncomplete, isLinkedAccountIncomplete, setIsLinkedAccountIncomplete }) => {
    // State For Select Row
    const [selectedEnrollments, setSelectedEnrollments] = useState([]);
    const [rowClick, setRowClick] = useState(true);

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        enrollment: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        createdAt: { value: null, matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO },
        createdTo: { value: null, matchMode: FilterMatchMode.LESS_THAN_OR_EQUAL_TO },
    });

    const [fromIncomplete, setFromIncomplete] = useState(null);
    const [hover, setHover] = useState(false);
    const [hoverLeave, setHoverLeave] = useState(false);
    const [hoverAfter, setHoverAfter] = useState(false);
    const [hoverLeaveAfter, setHoverLeaveAfter] = useState(false);
    const [allInCompletedEnrollments, setAllInCompletedEnrollments] = useState([]);

    const [customerSearchName, setCustomerSearchName] = useState("");
    const [totalSearchResult, setTotalSearchResult] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [enrollmentFrom, setEnrollmentFrom] = useState(1);
    const [enrollmentTo, setEnrollmentTo] = useState(15);
    const [completeListEnrollment, setCompleteListEnrollment] = useState([]);
    const [selectedNumberToShow, setSelectedNumberToShow] = useState(15);
    const [totalIncaseSearch, setTotalIncaseSearch] = useState(0);
    const [totalPage, setTotalPage] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [isButtonLoading, setisButtonLoading] = useState(false);
    const [selectedEnrollmentId, setSelectedEnrollmentId] = useState();

    const [isCreate, setIsCreate] = useState(false);
    const [isManage, setIsManage] = useState(false);
    const [prospect, setProspect] = useState(false);
    const [cancelled, setCancelled] = useState(false);
    const [all, setAll] = useState(false);
    const [selectedRange, setSelectedRange] = useState([null, null]);
    const [shortSelection, setShortSelection] = useState();

    const userData = JSON.parse(localStorage.getItem("userData"));
    const [role, setRole] = useState(userData?.role?.role);
    const op = useRef(null);
    const date = useRef(null);

    const location = useLocation();
    const currentPath = location?.pathname;
    const navigate = useNavigate();

    const loginRes = localStorage.getItem("userData");
    const parseLoginRes = JSON.parse(loginRes);

    const actionBasedChecks = () => {
        const loginPerms = localStorage.getItem("permissions");
        const parsedLoginPerms = JSON.parse(loginPerms);

        const isCreate = parsedLoginPerms.some((node) => node?.subModule.some((subNode) => subNode?.route === currentPath && subNode?.actions.some((action) => action?.name === "create")));
        setIsCreate(isCreate);

        const isManage = parsedLoginPerms.some((node) => node?.subModule.some((subNode) => subNode?.route === currentPath && subNode?.actions.some((action) => action?.name === "manage")));
        setIsManage(isManage);
    };

    useEffect(() => {
        actionBasedChecks();
        setFromIncomplete(false);
    }, []);

    const getAllInCompletedEnrollments = async () => {
        setIsLoading(true);
        try {
            const res = await api.get(`/api/user/inCompleteEnrollmentUser?userId=${parseLoginRes?._id}&accountType=Prepaid`);
            if (res?.status === 200 || res?.status === 201) {
                const data = res?.data?.data.map((item) => ({
                    ...item,
                    enrollment: item.isSelfEnrollment ? "Self Enrollments" : "Enrollment",
                    name: `${item?.firstName ? item?.firstName.toUpperCase() : ""} ${item?.lastName ? item?.lastName.toUpperCase() : ""}`,
                    createdDate: new Date(item.createdAt)
                        .toLocaleDateString("en-US", {
                            month: "2-digit",
                            day: "2-digit",
                            year: "numeric",
                        })
                        .replace(/\//g, "-"),
                    createdTo: item.createdAt,
                }));
                setAllInCompletedEnrollments(data.slice(0, 15)); // Initially display first 15 records
                setCompleteListEnrollment(data); // Full list for manual pagination
                setTotalPage(Math.ceil(data.length / 15)); // Total number of pages

                // Sorting by activatedAt (descending order)
                data.sort((a, b) => {
                    const dateComparison = new Date(b.activatedAt) - new Date(a.activatedAt);
                    if (dateComparison !== 0) {
                        return dateComparison;
                    }
                    return new Date(b.activatedAt).getTime() - new Date(a.activatedAt).getTime();
                });

                setIsLoading(false);
            }
        } catch (error) {
            toast.error(`Error Fetching Enrollment data: ${error?.response?.data?.msg}`);
            setIsLoading(false);
        }
    };
    useEffect(() => {
        getAllInCompletedEnrollments();
    }, []);

    const viewRow = async (rowData) => {
        setisButtonLoading(true);
        const _id = rowData._id;
        setSelectedEnrollmentId(_id);
        try {
            const response = await api.get(`/api/user/userDetails?userId=${_id}`);

            if (response?.status === 201 || response?.status === 200) {
                if (rowData?.linkedAccount) {
                    setIsLinkedAccountIncomplete(response?.data?.data);
                    navigate("/add-service");
                } else {
                    setIsPrepaidIncomplete(response?.data?.data);
                    navigate("/prepaid-newenrollment");
                }
            }
        } catch (error) {
            toast.error(error?.response?.data?.msg);
            setisButtonLoading(false);
        }
    };
    const handleEnrollmentIdClick = (rowData) => {
        navigate("/customer-profile", { state: { selectedId: rowData._id } });
        localStorage.setItem("selectedId", JSON.stringify(rowData._id));
    };

    const actionTemplate = (rowData) => {
        return (
            <div
                className="flex flex-wrap justify-content-end tablecontinuebtn"
                onClick={(e) => {
                    if (!isButtonLoading) {
                        e.stopPropagation();
                        viewRow(rowData);
                    }
                }}
                text
                raised
                disabled={isButtonLoading || !isCreate}
            >
                {selectedEnrollmentId === rowData?._id ? <CommonLoaderBlue customSize={15} /> : <Continue />}
            </div>
        );
    };
    return (
        <div className="prepaidincomplete">
            <div className="flex w-full flex-column justify-content-start h-4" style={{ marginBottom: "1.6rem" }}>
                <h3 className="heading">InComplete Enrollments</h3>
                <p className="para mt-2">Track and manage enrollments that are incomplete or in progress to ensure seamless completion and follow-ups.</p>
                <div className="flex flex-wrap justify-content-start align-items-center mt-2 w-full">
                    <div className="flex flex-wrap w-full justify-content-between align-items-center">
                        <div className="flex prosp">
                            <h2 className="prosp-heading">Inprogress</h2>
                        </div>
                        <div className="flex align-items-center" style={{ width: "50.5rem", gap: "1.6rem" }}>
                            <InputText
                                className="searchbar"
                                value={customerSearchName}
                                onChange={(e) => {
                                    setCustomerSearchName(e.target.value);
                                    setCurrentPage(1);

                                    if (e.target.value.length > 0) {
                                        let searchResult = completeListEnrollment.filter((item) => item.name.toLowerCase().includes(e.target.value.toLowerCase()));

                                        setAllInCompletedEnrollments(searchResult);
                                        setTotalIncaseSearch(searchResult.length);
                                        setTotalSearchResult(searchResult);
                                        setEnrollmentFrom(1);
                                        setEnrollmentTo(searchResult.length > 15 ? 15 : searchResult.length);
                                    } else {
                                        setTotalSearchResult([]);
                                        setAllInCompletedEnrollments(completeListEnrollment.slice(0, 15));
                                        setEnrollmentFrom(1);
                                        setEnrollmentTo(completeListEnrollment.length > 15 ? 15 : completeListEnrollment.length);
                                    }
                                }}
                                placeholder="Search Enrollment"
                            />
                            <div>
                                <button className="selector" onClick={(e) => date.current.toggle(e)}>
                                    <Calendar />
                                </button>
                                <OverlayPanel ref={date}>
                                    <DateRangeCalendar setSelectedRange={setSelectedRange} setShortSelection={setShortSelection} shortSelection={shortSelection} selectedRange={selectedRange} />
                                </OverlayPanel>
                            </div>
                            <div>
                                <button className="selector" onClick={(e) => op.current.toggle(e)}>
                                    <Status />
                                </button>
                                <OverlayPanel ref={op}>
                                    <div className="flex flex-column w-full overlay">
                                        <div className="flex justify-content-start align-items-center" style={{ gap: "10px", height: "36px" }}>
                                            <input id="prepaid" name="prepaid" className="check" type="checkbox" onChange={(e) => setProspect(e.value)} checked={prospect} />
                                            <label>Prospect</label>
                                        </div>
                                        <div className="flex justify-content-start align-items-center" style={{ gap: "10px", height: "36px" }}>
                                            <input id="postpaid" name="postpaid" className="check" type="checkbox" onChange={(e) => setCancelled(e.value)} checked={cancelled} />
                                            <label>Cancelled</label>
                                        </div>
                                        <div className="flex justify-content-start align-items-center" style={{ gap: "10px", height: "36px" }}>
                                            <input id="acp" name="acp" className="check" type="checkbox" onChange={(e) => setAll(e.value)} checked={all} />
                                            <label>All</label>
                                        </div>
                                    </div>
                                </OverlayPanel>
                            </div>
                            <button className="export flex justify-content-evenly align-items-center" style={{ opacity: role !== "Admin" ? "0.5" : "1" }}>
                                <p className="flex justify-content-between">
                                    Export
                                    <span className="ml-2">
                                        <DownloadIcon />
                                    </span>
                                </p>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {allInCompletedEnrollments.length === 0 && !isLoading ? (
                <div className="flex w-full justify-content-center " style={{ marginTop: "5rem" }}>
                    <div className="emptystatediv">
                        <div className="emptyicon">
                            <span>
                                <EmptyEnrollment />
                            </span>
                        </div>
                        <div className="emptystatedata">
                            <h1>No Enrollments in the Queue!</h1>
                            <p>There are currently no active, suspended, or disconnected enrollments to display. Start new enrollments to fill your queue!</p>
                            <div className="flex w-full justify-content-center">
                                <Button className="emptystatebtn" label="Start Enrollment" onClick={() => navigate("/prepaid-newenrollment")} />
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div>
                    <DataTable
                        selectionMode={rowClick ? null : "checkbox"}
                        selection={selectedEnrollments}
                        onSelectionChange={role !== "Admin" ? null : (e) => setSelectedEnrollments(e.value)}
                        size="small"
                        value={allInCompletedEnrollments}
                        onRowClick={(e) => handleEnrollmentIdClick(e.data)}
                        stripedRows
                        resizableColumns
                        emptyMessage={
                            isLoading ? (
                                <div className="flex flex-wrap w-full justify-content-center  flex-row  mt-4 mb-4 ">
                                    <CommonLoaderBlue customSize={16} />
                                </div>
                            ) : (
                                "Enrollment Not Found"
                            )
                        }
                        style={{ fontFamily: "Inter", fontSize: "14px" }}
                    >
                        <Column selectionMode="multiple" style={{ width: "1rem", pointerEvents: role !== "Admin" ? "none" : "auto", opacity: role !== "Admin" ? 0.5 : 1 }} />
                        <Column
                            header="Id"
                            field="enrollmentId"
                            // body={(rowData) => (
                            //     <button
                            //         style={{ fontSize: "1.272rem", fontWeight: "500", color: "#5E79FF", border: "none", backgroundColor: "transparent", cursor: "pointer", marginLeft: "-1rem" }}
                            //         onClick={() => {
                            //             // /*handleEnrollmentIdClick(rowData)*/
                            //         }}
                            //     >
                            //         {rowData.enrollmentId}
                            //     </button>
                            // )}
                        ></Column>
                        <Column header="Name" field="name"></Column>
                        <Column field="contact" header="Contact" />
                        {role === "Admin" && <Column field="createdBy.name" header="Created By" />}
                        <Column field="createdDate" header="Date" />
                        <Column style={{ width: "1rem" }} header="Action" body={actionTemplate}></Column>
                    </DataTable>
                    <div className="flex w-full justify-content-between align-items-center mt-5">
                        <div className="totalcount">
                            <h4>
                                {enrollmentTo} / {customerSearchName.length > 0 ? totalIncaseSearch : completeListEnrollment.length}
                            </h4>
                        </div>

                        <div className="flex">
                            <button
                                onClick={() => {
                                    const itemsPerPage = 15;

                                    const calculateTotalPages = (data) => Math.ceil(data.length / itemsPerPage);

                                    const handlePageNavigation = (data, setDataFn) => {
                                        const totalPages = calculateTotalPages(data);

                                        if (currentPage > 1) {
                                            // Calculate start and end indices
                                            const startIdx = (currentPage - 2) * itemsPerPage;
                                            const endIdx = Math.min(startIdx + itemsPerPage, data.length);

                                            // Update state with sliced data
                                            setDataFn(data.slice(startIdx, endIdx));
                                            setCurrentPage((prev) => prev - 1);
                                            setEnrollmentFrom(startIdx + 1);
                                            setEnrollmentTo(endIdx);
                                        }
                                    };

                                    if (customerSearchName.length > 0) {
                                        handlePageNavigation(totalSearchResult, setAllInCompletedEnrollments);
                                    } else {
                                        handlePageNavigation(completeListEnrollment, setAllInCompletedEnrollments);
                                    }
                                }}
                                className="cursor-pointer backbutton"
                                style={{ backgroundColor: "#FFFFFF33" }}
                            >
                                <span>
                                    <PrevIcon /> &nbsp; Previous
                                </span>
                            </button>
                            <div className="flex align-items-center justify-content-center" style={{ width: "12rem", fontWeight: "500", fontSize: "1.4rem", color: "#72728F" }}>
                                &nbsp; {enrollmentFrom}-{enrollmentTo}&nbsp; of&nbsp; {customerSearchName.length > 0 ? totalIncaseSearch : completeListEnrollment.length}
                            </div>

                            <button
                                onClick={() => {
                                    if (customerSearchName.length > 0) {
                                        let totalPages = Math.ceil(totalSearchResult.length / 15);
                                        if (totalPages > currentPage) {
                                            let enrollmentTillCurrentPage = currentPage * 15;
                                            if (totalPages - 1 === currentPage) {
                                                setAllInCompletedEnrollments(totalSearchResult.slice(enrollmentTillCurrentPage));

                                                setCurrentPage((prev) => prev + 1);
                                                setEnrollmentFrom(enrollmentTillCurrentPage + 1);
                                                setEnrollmentTo(totalSearchResult.length);
                                            } else {
                                                setAllInCompletedEnrollments(totalSearchResult.slice(enrollmentTillCurrentPage, enrollmentTillCurrentPage + 15));
                                                setCurrentPage((prev) => prev + 1);
                                                setEnrollmentFrom(enrollmentTillCurrentPage + 1);
                                                setEnrollmentTo(enrollmentTillCurrentPage + 15);
                                            }
                                        }
                                    } else {
                                        let totalPages = Math.ceil(completeListEnrollment.length / 15);
                                        if (totalPages > currentPage) {
                                            let enrollmentTillCurrentPage = currentPage * 15;
                                            if (totalPages - 1 === currentPage) {
                                                setAllInCompletedEnrollments(completeListEnrollment.slice(enrollmentTillCurrentPage));

                                                setCurrentPage((prev) => prev + 1);
                                                setEnrollmentFrom(enrollmentTillCurrentPage + 1);
                                                setEnrollmentTo(completeListEnrollment.length);
                                            } else {
                                                setAllInCompletedEnrollments(completeListEnrollment.slice(enrollmentTillCurrentPage, enrollmentTillCurrentPage + 15));

                                                setCurrentPage((prev) => prev + 1);
                                                setEnrollmentFrom(enrollmentTillCurrentPage + 1);
                                                setEnrollmentTo(enrollmentTillCurrentPage + 15);
                                            }
                                        }
                                    }
                                }}
                                className="cursor-pointer nextbutton"
                                style={{ backgroundColor: "#FFFFFF33" }}
                            >
                                <span className="flex align-items-center">
                                    Next &nbsp;
                                    <NextIcon />
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default PrepaidInCompleteEnrollments;
