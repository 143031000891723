import React, { useRef, useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import "./Subscription-Chart.css";
import Axios from "axios";
import { api } from "../../../../../utility/AxiosInceptor";
import moment from "moment";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler } from "chart.js";
import CommonLoaderBlue from "../../../../components/Loader/LoaderBlue";
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, ChartDataLabels, Legend, Filler);

const SubscriptonPlanChart = ({ shortSelection, selectedRange }) => {
    const [carrierData, setCarrierData] = useState([]);
    const [selectedCarrierId, setSelectedCarrierId] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [currentSelect, setCurrentSelect] = useState("at&t");
    const [chartLabels, setChartLabels] = useState([]);
    const [dataChart, setDataChart] = useState([]);
    const [chartData, setChartData] = useState({
        labels: [],
        datasets: [],
    });

    const chartRef = useRef(null);

    const initializeChart = () => {
        const chart = chartRef.current;
        if (chart) {
            const ctx = chart.ctx;
            const gradient1 = ctx.createLinearGradient(0, 0, 0, 400);
            gradient1.addColorStop(0, "rgba(0, 41, 255, 0.2)");
            gradient1.addColorStop(0.5, "rgba(255, 255, 255, 0.05)");

            const gradient2 = ctx.createLinearGradient(0, 0, 0, 400);
            gradient2.addColorStop(0, "rgba(111, 209, 149,0.4)");
            gradient2.addColorStop(0.3, "rgba(111, 209, 149,0.05)");

            const img = new Image();
            const viewportHeight = window.innerHeight;

            img.src = currentSelect === "at&t" ? (viewportHeight < 500 ? "/basicNode8.svg" : viewportHeight < 768 ? "/basicNode13.svg" : "/basicNode.svg") : viewportHeight < 500 ? "/basicNode10Tmb.svg" : viewportHeight < 768 ? "/basicNode13Tmb.svg" : "/basicNodeTmb.svg";

            img.onload = () => {
                setChartData({
                    labels: chartLabels,
                    datasets: [
                        {
                            label: "Subscribed Plans",
                            data: dataChart,
                            borderColor: currentSelect === "at&t" ? gradient1 : gradient2,
                            backgroundColor: currentSelect === "at&t" ? gradient1 : gradient2,
                            fill: true,
                            pointBorderWidth: 5,
                            borderWidth: 1,
                            pointRadius: 10,
                            borderColor: "transparent",
                            tension: 0.4,
                            pointStyle: img,
                        },
                    ],
                });
                chart.update();
            };
        }
    };

    const fetchDataAndInitializeChart = () => {
        api.get(`/api/web/dashboard/getCustomerDetailsByCarrierAndPlan?accountType=Prepaid&carrier=${selectedCarrierId}`, {
            params: {
                filter: shortSelection,
                startDate: moment(selectedRange[0])?.isValid() ? moment(selectedRange[0])?.format("MM-DD-YYYY") : null,
                endData: moment(selectedRange[1])?.isValid() ? moment(selectedRange[1])?.format("MM-DD-YYYY") : null,
            },
        })
            .then((res) => {
                const labels = res.data.planDetails.map((item) => item.planName);
                const data = res.data.planDetails.map((item) => item.totalCustomers);
                setChartLabels(labels);
                setDataChart(data);
                setIsLoading(false);
                // initializeChart();
            })
            .catch((err) => {
                if (err?.response?.data?.msg === "No customers found.") {
                    setChartLabels([]);
                    setDataChart([]);
                    setIsLoading(false);
                }
            });
    };
    useEffect(() => {
        if (chartLabels.length > 0 && dataChart.length > 0) {
            initializeChart();
        } else {
            if (chartLabels.length === 0 && dataChart?.length === 0) {
                initializeChart();
            }
        }
    }, [chartLabels, dataChart]);
    useEffect(() => {
        api.get(`/api/web/carrier/all`)
            .then((res) => {
                setCarrierData(res?.data?.data || []);
                const defaultCarrier = res?.data?.data.find((carrier) => carrier?.name === "AT&T" || carrier?.name === "TMB") || res?.data?.data[0];
                if (defaultCarrier) {
                    setCurrentSelect(defaultCarrier.name.toLowerCase());
                    setSelectedCarrierId(defaultCarrier._id);
                }
            })
            .catch((err) => {});
    }, []);

    useEffect(() => {
        if (selectedCarrierId) {
            fetchDataAndInitializeChart();
        }
    }, [selectedCarrierId, shortSelection, selectedRange]);

    useEffect(() => {
        const handleResize = () => {
            fetchDataAndInitializeChart();
        };
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [currentSelect]);

    return (
        <div className="subscription-main">
            <div className="subscription-chart-main-header flex flex-wrap flex-row justify-content-between align-items-center">
                <p className="total-enrollment-header">Subscription Plan</p>
                <div className="subscription-chart-carrier-select flex flex-wrap flex-row justify-content-center align-items-center">
                    {carrierData.map((item) => (
                        <div
                            key={item._id}
                            onClick={() => {
                                if (!isLoading && currentSelect !== item.name.toLowerCase()) {
                                    setIsLoading(true);
                                    setCurrentSelect(item.name.toLowerCase());
                                    setSelectedCarrierId(item._id);
                                }
                            }}
                            style={{
                                background: `${currentSelect === item.name.toLowerCase() ? (item.name === "AT&T" ? "#4E69FB" : "#6FD195") : ""}`,
                                color: `${currentSelect === item.name.toLowerCase() ? "#FFFFFF" : item.name === "AT&T" ? "#4E69FB" : "#6FD195"}`,
                            }}
                            className="attselect"
                        >
                            <p>{item.name}</p>
                        </div>
                    ))}
                </div>
            </div>
            <div className="subscription-chart-main-container">
                {isLoading ? (
                    <div className="flex flex-wrap flex-row justify-content-center align-items-center w-full h-full">
                        <CommonLoaderBlue sizehere={20} color={currentSelect === "tmb" ? "#6FD195" : "rgb(78, 105, 251)"} />
                    </div>
                ) : (
                    <Line
                        ref={chartRef}
                        data={chartData}
                        options={{
                            responsive: true,
                            maintainAspectRatio: false,
                            layout: { padding: { top: 20 } },
                            plugins: {
                                tooltip: {
                                    enabled: true,

                                    font: {
                                        size: 14, // Custom font size
                                        family: "inter", // Optional: specify a custom font family
                                        weight: "bold", // Optional: specify font weight
                                    },
                                    titleFont: {
                                        family: "Inter", // Set font family for tooltip title
                                        size: 13, // Font size for title
                                        weight: "400", // Font weight for title
                                    },
                                    bodyFont: {
                                        family: "Inter", // Set font family for tooltip body
                                        size: 12, // Font size for body
                                    },
                                    footerFont: {
                                        family: "Inter", // Set font family for tooltip footer
                                        size: 12, // Font size for footer
                                    },
                                },
                                datalabels: {
                                    display: true,
                                    color: "#666666",
                                    align: "top",
                                    font: (context) => {
                                        const windowHeight = window.innerHeight;
                                        return {
                                            size: windowHeight < 500 ? 8 : windowHeight < 768 ? 10 : 12,
                                            weight: "400",
                                            family: "Inter",
                                        };
                                    },
                                    formatter: (value) => value,
                                },
                                legend: { display: false },
                            },
                            scales: {
                                x: {
                                    beginAtZero: true,
                                    ticks: {
                                        font: () => {
                                            const windowHeight = window.innerHeight;
                                            return { size: windowHeight < 500 ? 8 : windowHeight < 768 ? 10 : 12, family: "Inter" };
                                        },
                                        color: "#666666",
                                        align: "center",
                                        textAlign: "center",
                                        maxRotation: 0, // Disable rotation
                                        minRotation: 0, // Disable rotation
                                        autoSkip: true, // Enable auto-skipping of labels
                                    },
                                    grid: { display: false, drawBorder: false },
                                },
                                y: {
                                    beginAtZero: true,
                                    ticks: {
                                        font: () => {
                                            const windowHeight = window.innerHeight;
                                            return { size: windowHeight < 500 ? 8 : windowHeight < 768 ? 10 : 12, family: "Inter" };
                                        },
                                        color: "#666666",
                                        maxTicksLimit: 6,
                                    },
                                    grid: { drawBorder: false, display: false },
                                },
                            },
                        }}
                    />
                )}
            </div>
        </div>
    );
};

export default SubscriptonPlanChart;
