import "./Carrier-Earning.css";
import React, { useRef, useState } from "react";
import CarrierChart from "./CarrierEarningComponents/Carrier-Chart";
import EarningChart from "./CarrierEarningComponents/Earning-Chart";
import { Calendar, ModuleSelector } from "./assets";
import { OverlayPanel } from "primereact/overlaypanel";
import Calendardropdown from "../new-dashboard-components/Carrier-Earning/Calendar-Dropdown/Calendar-DropDown";
export default function CarrierEarningChart({ setSelectedRange, setShortSelection, shortSelection, selectedRange }) {
    let calendar = useRef(null);
    const [currentModuleSelect, setCurrentModuleSelect] = useState("");
    let moduleSelector = useRef(null);
    console.log("short selection 1", selectedRange ? selectedRange[0] : "");
    const [allChartData, setAllChartData] = useState();
    return (
        <div className="carrier-earning-chart">
            <div className="flex flex-wrap w-full flex-row justify-content-between align-items-center carrier-earning-top-content">
                <div className="carrier-earning-main-tag">
                    <h1>Module Performance Overview</h1>
                    <p>Current stats across all modules. Use filters to explore detailed insights.</p>
                </div>
                <div className="range-calendar-selector-main">
                    <p>
                        {selectedRange ? (selectedRange[0] ? new Date(selectedRange[0]).toLocaleDateString("en-US", { month: "short", day: "numeric", year: "numeric" }) : "Start Date") : "Start Date"} -&nbsp;
                        {selectedRange ? (selectedRange[1] ? new Date(selectedRange[1]).toLocaleDateString("en-US", { month: "short", day: "numeric", year: "numeric" }) : "End Date") : "End Date"}
                    </p>
                    <div className="range-calendar-main" onClick={(e) => calendar.current.toggle(e)}>
                        <Calendar />
                    </div>

                    <OverlayPanel position="left" ref={calendar} className="calendaroverlay">
                        <Calendardropdown calendar={calendar} setShortSelection={setShortSelection} shortSelection={shortSelection} selectedRange={selectedRange} setSelectedRange={setSelectedRange} />
                    </OverlayPanel>
                    <div
                        className="range-calendar-module-selector"
                        onClick={(e) => {
                            moduleSelector.current.toggle(e);
                        }}
                    >
                        <ModuleSelector />
                    </div>
                    <div className="main-overlay">
                        <OverlayPanel ref={moduleSelector}>
                            <div className="main-module-container">
                                <p
                                    onClick={() => {
                                        setCurrentModuleSelect("prepaid");
                                        setTimeout(() => {
                                            moduleSelector.current.hide();
                                        }, 250);
                                    }}
                                    className={`${currentModuleSelect === "prepaid" ? "selected-module" : ""}`}
                                >
                                    Prepaid
                                </p>
                                <p
                                    className={`${currentModuleSelect === "postpaid" ? "selected-module" : ""}`}
                                    onClick={() => {
                                        setCurrentModuleSelect("postpaid");
                                        setTimeout(() => {
                                            moduleSelector.current.hide();
                                        }, 250);
                                    }}
                                >
                                    Postpaid
                                </p>
                                <p
                                    className={`${currentModuleSelect === "acp" ? "selected-module" : ""}`}
                                    onClick={() => {
                                        setCurrentModuleSelect("acp");
                                        setTimeout(() => {
                                            moduleSelector.current.hide();
                                        }, 250);
                                    }}
                                >
                                    Acp
                                </p>
                                <p
                                    className={`${currentModuleSelect === "all" ? "selected-module" : ""}`}
                                    onClick={() => {
                                        setCurrentModuleSelect("all");
                                        setTimeout(() => {
                                            moduleSelector.current.hide();
                                        }, 250);
                                    }}
                                >
                                    All
                                </p>
                            </div>
                            {/*  <div className="flex flex-column w-full overlay">
                                <div className="flex justify-content-start align-items-center" style={{ gap: "10px", height: "36px" }}>
                                    <input id="prepaid" name="prepaid" className="check" type="checkbox" onChange={(e) => formik.setFieldValue("prepaid", e.target.checked)} checked={formik.values.prepaid} />
                                    <label>Prepaid</label>
                                </div>
                                <div className="flex justify-content-start align-items-center" style={{ gap: "10px", height: "36px" }}>
                                    <input id="postpaid" name="postpaid" className="check" type="checkbox" onChange={(e) => formik.setFieldValue("postpaid", e.target.checked)} checked={formik.values.postpaid} />
                                    <label>Postpaid</label>
                                </div>
                                <div className="flex justify-content-start align-items-center" style={{ gap: "10px", height: "36px" }}>
                                    <input id="acp" name="acp" className="check" type="checkbox" onChange={(e) => formik.setFieldValue("acp", e.target.checked)} checked={formik.values.acp} />
                                    <label>ACP</label>
                                </div>
                            </div>    */}
                        </OverlayPanel>
                    </div>
                </div>
            </div>
            <CarrierChart setAllChartData={setAllChartData} shortSelection={shortSelection} selectedRange={selectedRange} />
            <EarningChart allChartData={allChartData} shortSelection={shortSelection} selectedRange={selectedRange} />
        </div>
    );
}
