import React, { useEffect, useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import EditPlan from "./edit_plan";
import Axios from "axios";
import { Card } from "primereact/card";

import { api } from "../../../../utility/AxiosInceptor";
import PlansConfigurations from "./plan_configuration";
import { ToastContainer } from "react-toastify";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { toast } from "react-toastify";
import CommonLoaderBlue from "../billingModel/billingmodel_configurations/Loaders/components/Loader/LoaderBlue";
import { PlanEditIcon } from "../../../../utility";
import { OverlayPanel } from "primereact/overlaypanel";
export default function ListAllPlans() {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const [loader, setLoader] = useState(true);
    const loginRes = localStorage.getItem("userData");
    const parseLoginRes = JSON.parse(loginRes);
    const [refresh, setRefresh] = useState(false);
    const [editPlanVisibility, setEditPlanVisibility] = useState(false);
    const [visible, setVisible] = useState(false);
    const [delteBillingLoader, setDeleteBillingLoader] = useState(false);

    const op = useRef(null);
    useEffect(() => {
        if (editPlanVisibility === false) {
            api.get(`/api/web/plan/all?serviceProvider=${parseLoginRes?.company}`)
                .then((res) => {
                    setPlanList(res?.data?.data);
                })
                .catch((err) => {});
        }
        if (addPlanVisibility === false) {
            api.get(`/api/web/plan/all?serviceProvider=${parseLoginRes?.company}`)
                .then((res) => {
                    setPlanList(res?.data?.data);
                    setLoader(false);
                })
                .catch((err) => {
                    setLoader(false);
                });
        }
    }, [refresh]);
    const [planList, setPlanList] = useState([]);
    const [rowData, setRowData] = useState(null);
    const [addPlanVisibility, setAddPlanVisibility] = useState(false);
    return (
        <>
            {addPlanVisibility ? (
                <PlansConfigurations data={rowData} setRefresh={setRefresh} setAddPlanVisibility={setAddPlanVisibility} />
            ) : editPlanVisibility ? (
                <div>
                    <EditPlan data={rowData} setRefresh={setRefresh} setEditPlanVisibility={setEditPlanVisibility} />
                </div>
            ) : (
                <div className="overflow-hidden flex flex-column planinfomain" style={{ margin: "2.4rem" }}>
                    <ToastContainer />
                    <div className="flex  flex-wrap flex-row justify-content-between planheader">
                        <div className="flex h-full flex-column justify-content-between">
                            <h1>Planning</h1>
                            <p>View and manage all enrollments with Active, Suspended, or Disconnected statuses in one organized table.</p>
                        </div>
                        <div className="flex h-full align-items-end">
                            <Button
                                onClick={() => {
                                    setAddPlanVisibility(true);
                                }}
                                className="text-center mr-0 planbtn"
                                label="Add Plan"
                            />
                        </div>
                    </div>
                    <Dialog
                        headerClassName="dialogheader"
                        closable={false}
                        visible={visible}
                        onHide={() => {
                            if (!visible) return;
                            setVisible(false);
                        }}
                        className="delete-dialog"
                    >
                        <div className="datadelete">
                            <h1>Confirm Deletion</h1>
                            <p> Are you sure you want to delete this plan? This action cannot be undone. Proceed with caution.</p>{" "}
                        </div>
                        <div></div>
                        <div className="deletedialogbtn">
                            <Button className="backbtn" label="Cancel" onClick={() => setVisible(false)} style={{ width: "16rem", height: "5rem" }} />
                            <Button
                                label="Delete"
                                className="nextbtn"
                                loading={delteBillingLoader}
                                disabled={delteBillingLoader}
                                onClick={() => {
                                    setDeleteBillingLoader(true);
                                    api.patch(`/api/web/plan/updateStatus`, {
                                        updatedBy: parseLoginRes._id,
                                        serviceProvider: parseLoginRes.company,
                                        id: rowData._id,
                                        status: false,
                                    })
                                        .then((res) => {
                                            toast.success("Plan Removed Successfully");
                                            setRefresh((prev) => !prev);
                                            setTimeout(() => {
                                                setVisible(false);
                                            }, 500);
                                        })
                                        .catch((err) => {
                                            setDeleteBillingLoader(false);
                                            toast.error("Plan Removal Failed");
                                        });
                                    setVisible(false);
                                }}
                                style={{ width: "16rem", height: "5rem" }}
                            />
                        </div>
                    </Dialog>

                    <div className="plantable">
                        <DataTable
                            value={planList}
                            size="small"
                            stripedRows
                            resizableColumns
                            emptyMessage={
                                loader ? (
                                    <div className="flex flex-wrap flex-row mb-4 " style={{ marginLeft: "calc(50% - 70px)" }}>
                                        <CommonLoaderBlue />
                                    </div>
                                ) : (
                                    "No Plan Found"
                                )
                            }
                            style={{ marginTop: "24px", width: "108.4rem" }}
                        >
                            <Column header="Plan ID" field="planId" />
                            <Column
                                header="Name"
                                body={(rowData) => {
                                    const maxLength = 20; // Maximum characters to show
                                    const name = rowData.name;
                                    return name.length > maxLength ? name.substring(0, maxLength) + "..." : name;
                                }}
                            />
                            <Column header="Inventory Type" field="inventoryType" style={{ width: "10rem" }} />
                            <Column header="Billing Model" field="type" />
                            <Column header="Text Allowance" field="textAllowance" />
                            <Column header="Data Allowance" field="dataAllowance" />
                            <Column header="Voice Allowance" field="voiceAllowance" />
                            <Column header="Voice Retail Price" field="price" />
                            <Column
                                header="Description"
                                body={(rowData) => {
                                    const maxLength = 15; // Maximum characters to show
                                    const description = rowData.description;
                                    return description.length > maxLength ? description.substring(0, maxLength) + "..." : description;
                                }}
                            />
                            <Column
                                header="Actions"
                                body={(rowData) => {
                                    return (
                                        <>
                                            <div className="actionbtnmain" onClick={(e) => op.current.toggle(e)} style={{ marginLeft: "2.3rem" }}>
                                                <PlanEditIcon />
                                            </div>
                                            <OverlayPanel ref={op} style={{ width: "10.9rem", height: "8rem" }} className="flex align-items-center">
                                                <div className="flex flex-column w-full overlay">
                                                    <div
                                                        className="flex justify-content-start align-items-center prospectoverlay"
                                                        style={{ height: "32px" }}
                                                        onClick={() => {
                                                            setRowData(rowData);
                                                            setEditPlanVisibility(true);
                                                        }}
                                                    >
                                                        <label style={{ fontSize: "1.2rem", fontWeight: "500" }}>Update</label>
                                                    </div>
                                                    <div
                                                        className="flex justify-content-start align-items-center prospectoverlay"
                                                        style={{ height: "32px" }}
                                                        onClick={() => {
                                                            setVisible(true);
                                                            setRowData(rowData);
                                                        }}
                                                    >
                                                        <label style={{ fontSize: "1.2rem", fontWeight: "500" }}>Delete</label>
                                                    </div>
                                                </div>
                                            </OverlayPanel>
                                        </>
                                    );
                                }}
                                field="Edit"
                            />
                        </DataTable>
                    </div>
                </div>
            )}
        </>
    );
}
